import { swallCollors } from '../../environments/version';

export const getSwal = (
  type: string,
  title: string,
  text: string,
  confirm: string,
  cancel: string | null,
) => {
  const result: any = Object.assign({}, swallCollors);

  if (type && type !== null) {
    result.icon = type;
  }

  if (title && title !== null) {
    result.title = title;
  }

  if (text && text !== null) {
    result.text = text;
  }

  if (confirm && confirm !== null) {
    result.confirmButtonText = confirm;
  }

  if (cancel && cancel !== null) {
    result.showCancelButton = true;
    result.cancelButtonText = cancel;
  } else {
    result.showCancelButton = false;
  }
  return result;
};
