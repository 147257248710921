import { TranslateService } from '@ngx-translate/core';

/**
 * Inicialização do processo de internacionalização, configurações e carregamento dos arquivos de linguas
 */
export function I18nInitializer(
  translate: TranslateService,
  configs: any
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        //adiciona as linguas existentes na aplicação
        translate.addLangs(configs.languages);
        //determina lingua padrão
        translate.setDefaultLang(configs.defaultLanguage);

        //detecta e define a lingua a ser utilizada
        let browserLang = translate.getBrowserLang();

        browserLang = browserLang.match(configs.optionsRegex)
          ? browserLang
          : configs.useLanguage;

        translate.use(browserLang).subscribe(value => {
          resolve(value);
        });
      } catch (e) {
        reject();
      }
    });
  };
}
