import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';

import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

// import { TokenService } from './../../service/token.service';

const routes: Routes = [
	//{ path: '', component: HomeComponent, canActivate: [AuthGuardService], data: {modulo: '0'}, pathMatch: 'full' },
	{
		path: '',
		pathMatch: 'full',
		children: [
			{
				path: '',
				component: HomeComponent,
				canActivate: [AuthGuardService],
				data: {modulo: '0'} 
			}
	]
	},
	
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class HomeRoutingModule {

}
