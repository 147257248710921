import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IndexRoutingModule } from './index.routing.module';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared.module';


@NgModule({
    imports: [
        IndexRoutingModule,
        SharedModule
    ],
    exports: [
        IndexComponent
    ],
    declarations:[
        IndexComponent,
    ],
    providers: [],
})

export class IndexModule {

}