import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConsultaUsuarioService } from '../../../../../service/consulta-usuario/consultar.user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pesquisar-destinatarios',
  templateUrl: 'pesquisar-destinatario.component.html',
  styleUrls: ['./pesquisar-destinatario.component.scss'],
})
export class PesquisarDestinatariosComponent implements OnInit {
  @Output() onSelectUser: EventEmitter<any> = new EventEmitter<any>();

  destinatarioForm!: FormGroup;
  mascara: string = '000.000.000-00';
  tipo: string = 'cpf';
  loading: boolean = false;
  usuarios: Array<any> = [];
  noUsersOnSearch = false;

  labels: any;

  constructor(
    private fb: FormBuilder,
    private consultarUsuario: ConsultaUsuarioService,
    private translate: TranslateService,
  ) {
    this.setLabels();
  }

  ngOnInit() {
    this.criarFormulario();
  }

  criarFormulario() {
    this.destinatarioForm = this.fb.group({
      tipo: '',
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)]),
      ],
    });
  }

  alterarIdentificador(tipo: string) {
    this.destinatarioForm.controls.numero.setValue('');

    switch (tipo) {
      case 'cpf':
        this.mascara = '000.000.000-00';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(11),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
      case 'cnpj':
        this.mascara = '99.999.999/9999-99';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(8),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
      case 'inscricao':
        this.mascara = 'A*';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(1),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
      case 'razaoSocial':
        this.mascara = 'A*';
        this.destinatarioForm.controls.numero.setValidators(
          Validators.minLength(1),
        );
        this.destinatarioForm.controls.numero.updateValueAndValidity();
        break;
    }
  }

  pesquisarDestinatario() {
    this.loading = true;
    let obj: any = {};
    this.noUsersOnSearch = false;
    const value = this.destinatarioForm.value;
    if (value.tipo === 'inscricao') {
      obj['inscricao'] = value.numero;
    } else if (value.tipo === 'razaoSocial') {
      obj['razaoSocial'] = value.numero;
    } else {
      obj['cpfCnpj'] = value.numero;
    }
    // this.destinatarioForm.controls.numero.reset();
    this.consultarUsuario.consultarUsuariosAtivos(obj).subscribe(
      res => {
        console.log('pesquisarDestinatario result: ', res);
        this.loading = false;
        this.usuarios = res.resultado;
        if (this.usuarios.length === 0) {
          this.noUsersOnSearch = true;
        }
      },
      error => {
        this.noUsersOnSearch = true;
        this.loading = false;
      },
    );
  }

  selectUser(user: any) {
    this.onSelectUser.emit(user);
  }

  setLabels() {
    this.labels = {
      cgf: this.translate.instant('GLOBAIS.INSCRICAO'),
    };
  }
}
