import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginSenhaComponent } from "./login-senha/login-senha.component";
import { PerfilComponent } from "./perfil/perfil.component";
import { CertificadoComponent } from "./certificado/certificado.component";
import { RecuperarSenhaComponent } from "./recuperar-senha/recuperar-senha.component";
import { ProcuracaoComponent } from "./procuracao/procuracao.component";
import { ECnpjComponent } from "./e-cnpj/e-cnpj.component";
import { filiaisComponent } from "./filiais/filiais.component";
import { ResetarSenhaComponent } from "./resetar-senha/resetar-senha.component";
import { ConfirmacaoComponent } from "./confirmacao/confirmacao.component";


const routes: Routes = [
  { path: 'login-senha', component: LoginSenhaComponent },
  { path: 'perfis', data: {modulo: 0}, component: PerfilComponent},
  { path: 'certificado', data: {modulo: '0'}, component: CertificadoComponent },
  { path: 'recuperar-senha', data: {modulo: '0'}, component: RecuperarSenhaComponent },
  { path: 'procuracoes', data: {modulo: '0'}, component: ProcuracaoComponent},
  { path: 'certificado-digital/:tipo', data: {modulo: '0'}, component: CertificadoComponent },
  { path: 'alterar-senha/:token', data: {modulo: '0'}, component: ResetarSenhaComponent },
  { path: 'empresas', component: ECnpjComponent},
	{ path: 'filiais', component: filiaisComponent},
  { path: 'confirmar', data: {modulo: '0'}, component: ConfirmacaoComponent }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class LoginRoutingModule {

}