import { version } from './version';

export const environment = {
  BASE_URL: 'https://dte.qa.giss.com.br/PlatformaDTe',
  BASE_URL_microservico: 'https://dte.qa.giss.com.br',
  production: true,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  firebaseConfig: {
    apiKey: "AIzaSyCfkugEPINGr9sHKTa0anJq1HUkiNCRvbg",
    authDomain: "dte-id.firebaseapp.com",
    projectId: "dte-id",
    storageBucket: "dte-id.appspot.com",
    messagingSenderId: "619843953278",
    appId: "1:619843953278:web:7251e3a12840932a8b2e2f",
    measurementId: "G-ST0XXRQ7HP"
  },
  notificationApi: "https://qa-api.eiconbrasil.com.br/api-alerta-dte/v1/pushSubscription/registrar"
};
