import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//import { GlobalVariable } from '../../../model/global-variable';

@Component({
  selector: 'app-procuracao',
  templateUrl: './procuracao.component.html',
	styleUrls: ['./procuracao.component.scss'],
})
export class ProcuracaoComponent implements OnInit {

	private isSidebarClosed: boolean = localStorage.getItem('sidebar_is_closed') == 'true';

	constructor(private router: Router) {

	}

	ngOnInit() {
		
	}

	onSidebarToggle(isClosed: boolean) {
 		this.isSidebarClosed = isClosed;
 	}

 	onBuscarAVencer(value: any) {

 	}

 	onAtualizarAVencer() {

 	}

 	onCancelarAVencer() {

 	}

 	onBuscarVencidas(value: any) {

 	}

 	onAtualizarVencidas() {

 	}

 	onCancelarVencidas() {

 	}
}
