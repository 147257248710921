import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class ProcuracoesService {
  private procuracoes = new Subject<any>();
  procuracoes$ = this.procuracoes.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  getProcuracoes(searchParams: any): Observable<any> {
    let params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/procuracoes`, { params: params })
      .pipe(map(data => {
        let body = data;
        this.procuracoes.next(body);
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) return params;

    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }

    for (let key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  gerarRevogacao(codigoProcuracao: string, certificado: string) {
    return this.http
      .post(
        `${this.url}/procuracoes/${codigoProcuracao}/gerarDocumentoRevogacao`,
        certificado
      );
  }

  cancelarProcuracao(codigoProcuracao: any, assinatura: any, cancelaFiliais: any): Observable<any> {
    console.log('cancelaFiliais params::', cancelaFiliais);
    return this.http
      .post(`${this.url}/procuracoes/${codigoProcuracao}/cancelar`, assinatura, {
        params: {filiais: cancelaFiliais}
      });
  }

  aceitarProcuracao(codigoProcuracao: any): Observable<any> {
    return this.http
      .get(`${this.url}/procuracoes/${codigoProcuracao}/aceitar`);
  }

  getOutorgadasAdvogados(): Observable<any> {
    return this.http
      .get(`${this.url}/procuracoes/outorgadasParaAdvogados`);
  }

  getProcuracao(codigoProcuracao: string): Observable<any> {
    return this.http
      .get(`${this.url}/procuracoes/${codigoProcuracao}`);
  }

  getRepresentanteLegal(cnpj: string) {
    return this.http.get(`${this.url}/procuracoes/` + cnpj + '/representanteLegal');
  }

  baixarTermoProcuracao(codigoProcuracao: any){
    return this.http.get(`${this.url}/procuracoes/exportarTermoProcuracao/`+codigoProcuracao,{
        responseType: "arraybuffer"
      });
  }

  salvarTermoProcuracao(termoProcuracao: any, codigoProcuracao: string){
    const fd = new FormData();
    fd.append('anexo', termoProcuracao)
    fd.append('codigoProcuracao', codigoProcuracao)
    return this.http.post(`${this.url}/procuracoes/salvarTermoProcuracao`, fd)
      .pipe(map(data => {
        return data;
    }));
  }
}
