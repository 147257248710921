import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { CaixaPostalService } from '../../../../../../../service/caixa-postal/caixa-postal.service';
import { SessionService } from '../../../../../../../service/session/session.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { getSwal } from '../../../../../../../utils/swalObject';
import 'summernote/dist/summernote';
import 'summernote/dist/summernote-bs4';
import { FormsModule } from '@angular/forms';
declare var $;

@Component({
  selector: 'app-textbox-message',
  templateUrl: 'textbox.component.html',
  styleUrls: ['./textbox.component.scss']
})
export class TextBoxComponent implements OnInit {
  @Input('mensagem') mensagem: any;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  respostaForm: FormGroup;
  filesToUpload: Array<File>;
  loading = false;
  config: any;
  resposta: string;
  caracteresTextoPadrao: number = 0;
  mensagemString: string = '';
  files: Array<any> = [];
  respostaEnviada: boolean = false;

  constructor(
    private sessionService: SessionService,
    private caixaPostalService: CaixaPostalService,
    private router: Router,
    private fb: FormBuilder,
    private elementRef: ElementRef
  ) {
    this.filesToUpload = [];
  }


  ngOnInit() {
    this.configToolBar();
    this.criarFormulario();
    this.elementRef.nativeElement.ownerDocument.body.style.setProperty('charset', 'UTF-8');
  }

  criarFormulario() {
    this.respostaForm = this.fb.group({
      resposta: ['', Validators.required]
    });
  }

  fileChangeEvent(fileInput: any) {
    const files = fileInput.target.files;
    for (let i = 0; i <= files.length - 1; i++) {
      this.filesToUpload.push(files[i]);
    }
  }

  removeFile(index: number) {
    this.filesToUpload.splice(index, 1);
    this.fileInput.nativeElement.value = '';
  }

  validaUsuarioResponde(mensagem) {
    // funcao que verifica se o remetente é diferente do usuario a responder ou se o remetente é a aplicacao
    // caso algumas das condições seja verdadeira, o usuario poderá responder, caso contrário, não
    const session: Object = this.sessionService.getSession();
    const remetente = mensagem.remetente;
    if (mensagem.situacaoMensagem.situacao === 2) {
      return false;
    } else if (
      remetente.usuario &&
      remetente.usuario.cpfCnpj !== session['usuario'].cpfCnpj
    ) {
      return true;
    } else if (remetente.aplicacao !== null) {
      return true;
    } else {
      return false;
    }
  }

  responder() {
    console.log("mensagem: ", this.mensagemString)
    this.loading = true;
    const resposta = this.respostaForm.controls.resposta.value;
    const protocolo = this.mensagem.protocolo;
    this.caixaPostalService
      .responderMensagem(protocolo, this.mensagemString, this.filesToUpload)
      .subscribe(
        res => {
          this.respostaForm.reset();
          this.filesToUpload = [];
          this.loading = false;

          swal.fire(
            getSwal(
              'success',
              'Pronto!',
              'Sua resposta foi enviada com êxito.',
              'ok',
              null
            )
          ).then(res => {
            this.loading = false;
            this.router.navigate(['/caixa-postal']);
          });
        },
        error =>
          swal.fire(
            'Ops!',
            'Ocorreu um erro e sua resposta não pôde ser enviada.',
            'error'
          ).then(res => {
            this.loading = false;
            this.router.navigate(['/caixa-postal']);
          })
      );
    this.respostaEnviada = true;
  }

  configToolBar() {
    const those = this;
    this.config = {
      disabledDragAndDrop: true,
      disableResizeEditor: true,
      placeholder: 'Escreva aqui...',
      height: '95px',
      width: '100%',
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
      ],
    }
  }

}
