import { Component, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  animate,
  style,
  keyframes,
} from '@angular/animations';
import {
  FormGroup,
  FormBuilder,
  Validators,
  MinLengthValidator,
  FormControl,
} from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { RecuperarSenhaService } from '../../../service/recuperar-senha/recuperar-senha.service';

import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: 'recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.scss'],
  animations: [
    trigger('shake', [
      transition(
        ':enter',
        animate(
          400,
          keyframes([
            style({ transform: 'translate(30px)', offset: 0.0 }),
            style({ transform: 'translate(-30px)', offset: 0.2 }),
            style({ transform: 'translate(15px)', offset: 0.4 }),
            style({ transform: 'translate(-15px)', offset: 0.6 }),
            style({ transform: 'translate(8px)', offset: 0.8 }),
            style({ transform: 'translate(0px)', offset: 1 }),
          ]),
        ),
      ),
    ]),
  ],
})
export class RecuperarSenhaComponent implements OnInit {
  forgotPasswordForm!: FormGroup;
  opcao = '0';
  usuarioValido: boolean = false;
  celular: any;
  cpf = '';
  errorMessage: string = '';
  hasError: boolean = false;
  emailEnviado: boolean = false;
  userData: any = {};
  labels: any = {};
  //Mask:
  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';
  pureResult: any;
  maskedId: any;
  val: any;
  
  situacao!: number;
  recuperarSenha: boolean = true;
  recuperar: boolean = true;
  configuracao!: any;
  servicoSms!: number;
  codigoAtivacao: boolean = false;
  codigoVerificacao: String = '';
  codigoUsuario!: number;
  codigoCliente!: number;
  loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private recuperarSenhaService: RecuperarSenhaService,
    private translate: TranslateService,
    private cadastroService: CadastroService
  ) {
    this.setLabels();
  }
  
  ngOnInit() {
    this.criarFormulario();
    this.configuracoesSistema();
    setTimeout(() => this.alterarCamposObrigatorios(), 1500);
    
  }

  alterarCamposObrigatorios() {
    if(this.servicoSms == 0) {
      console.log('passou');
      
      this.forgotPasswordForm.controls["celular"].clearValidators();
      this.forgotPasswordForm.controls["celular"].updateValueAndValidity();
    }
  }

  criarFormulario() {
    this.forgotPasswordForm = this.fb.group({
      cpf: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(18),
        ]),
      ],
      email: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)]),
      ],
      celular: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(11)]),
      ],
      codigoVerificacao: '',
    });
  }

  unFormat(val: string) {
    if (!val) {
      return '';
    }
    val = val.replace(/\D/g, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  }

  cpf_mask(v: string) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return v;
  }

  cnpj(v: string) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
    return v;
  }

  format(valString: { toString: () => any; }) {
    if (!valString) {
      return '';
    }
    const val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    if (parts[0].length <= 11) {
      this.maskedId = this.cpf_mask(parts[0]);
      return this.maskedId;
    } else {
      this.maskedId = this.cnpj(parts[0]);
      return this.maskedId;
    }
  }

  verificarUsuario() {
    this.hasError = false;
    // this.usuarioValido = true
    let credentials = Object.assign({}, this.forgotPasswordForm.value);
    credentials['cpf'] = this.unFormat(credentials['cpf']);
    credentials['ddd'] = credentials['celular'].substring(0, 2);
    credentials['celular'] = credentials['celular'].substr(2, 9);
    console.table(credentials);
    this.recuperarSenhaService
      .verificarUsuario(credentials)
      .subscribe(res => this.handleRes(res), error => this.handleError(error));
  }

  handleRes(res: any) {
    // console.log('sitaucao: ' + this.situacao);
    this.userData = res.resultado;
    this.situacao = res.resultado.situacao;
    this.codigoUsuario = res.resultado.codigo;
    if(this.situacao > 0) {
      this.usuarioValido = true;
      this.recuperarSenha = false;
      console.log('Usuário válido');
    } else if(this.servicoSms == 0) {      
      Swal.fire(
        getSwal(
          'info',
          'Atenção',
          'Seu cadastro ainda não foi ativado. Será enviado um e-mail com o código para ativação.' +
           ' Digite no campo abaixo o código recebido.',
          'OK',
          null
        )
      ).then(() => {
        this.usuarioValido = false;
        this.recuperarSenha = false;
        this.recuperar = false;
        this.codigoAtivacao = true;
        let codigo = this.userData['codigo'];
        this.recuperarSenhaService
        .recuperarSenha(codigo, '/solicitarValidacaoPorEmail')
        .subscribe(
          res => {
          },
          error => this.handleErr(),
        );
      })
    } else if(this.servicoSms > 0) {
      Swal.fire(
        getSwal(
          'info',
          'Atenção',
          'Seu cadastro ainda não foi ativado. Será enviado um SMS com o código para ativação.' +
           ' Digite no campo abaixo o código recebido.',
          'OK',
          null
        )
      ).then(() => {
        this.usuarioValido = false;
        this.recuperarSenha = false;
        this.recuperar = false
        this.codigoAtivacao = true;
        let codigo = this.userData['codigo'];
        this.recuperarSenhaService
        .recuperarSenha(codigo, '/solicitarValidacaoPorSms')
        .subscribe(
          res => {
          },
          error => this.handleErr(),
        );
      })
    }
  }

  handleError(err: { error: { excecao: { codigo: string; mensagem: string; }; }; }) {
    this.hasError = true;
    if (err.error.excecao.codigo === 'E002') {
      this.errorMessage =
        'Usuário não encontrado. Verifique seus dados e tente novamente.';
    } else {
      this.errorMessage = err.error.excecao.mensagem;
    }
  }

  next() {
    let opcao = parseInt(this.opcao);
    let codigo = this.userData['codigo'];

    if (opcao === 0) {
      this.recuperarSenhaService
        .recuperarSenha(codigo, '/enviarInstrucoesPorEmail')
        .subscribe(
          res => {
            this.router.navigate(['confirmar'], {
              queryParams: { tipo: 'email', codigoUsuario: codigo },
            });
          },
          error => this.handleErr(),
        );
    } else {
      this.recuperarSenhaService
        .recuperarSenha(codigo, '/solicitarValidacaoPorSms')
        .subscribe(
          res => {
            this.router.navigate(['confirmar'], {
              queryParams: { tipo: 'celular', codigoUsuario: codigo },
            });
          },
          error => this.handleErr(),
        );
    }
  }

  handleErr() {
    swal.fire(
      getSwal(
        'error',
        'Ops',
        'Ocorreu um erro e a senha não pôde ser recuperada',
        'ok',
        null,
      ),
    ).then(res => {
      this.router.navigate(['index']);
    });
  }

  configuracoesSistema() {
    this.cadastroService.clientes()
      .subscribe((res: { resultado: Object[]; }) => {
        this.configuracao = res.resultado[0];
        console.log(this.configuracao);
        this.servicoSms = this.configuracao['configuracao']['servicoSms'];
        console.log(this.servicoSms);
      })
  }

  validarCodigo() {
    if(this.servicoSms > 0) {
      let codigoVerificacao = this.forgotPasswordForm.controls["codigoVerificacao"].value;
      this.recuperarSenhaService.validarCodigoSMS(this.codigoUsuario, codigoVerificacao)
        .subscribe(
          res => {
            let token = res['resultado'].token;
            this.router.navigate(['alterar-senha', token]);
          },
          error => {
            console.log('passou');
            
            swal.fire(
              getSwal(
                'error',
                'Código Inválido',
                'Verifique o código recebido no celular cadastrado e digite corretamente.',
                'OK',
                null
              )
            ).then(res => {
              this.router.navigate(['index'])
            })
          }
        );
    } else {
      let codigoVerificacao = this.forgotPasswordForm.controls["codigoVerificacao"].value;
      this.recuperarSenhaService.validarCodigoEmail(this.codigoUsuario, codigoVerificacao)
       .subscribe(
         res => {
           let token = res['resultado'].token;
           this.router.navigate(['/login/alterar-senha', token]);
         },
         error => {
           swal.fire(
             getSwal(
               'error',
               'Código Inválido',
               'Verifique o código recebido no e-mail cadastrado e digite corretamente.',
               'Ok',
               null
             )
           ).then(res => {
            this.router.navigate(['index']);
          })
         }
       );
    }
  }

  setLabels() {
    this.labels = {
      titulo: this.translate.instant('RECUPERARSENHA.TITULO'),
      tituloSecundario: this.translate.instant('RECUPERARSENHA.TITULOSECUNDARIO'),
      subtitulo: this.translate.instant('RECUPERARSENHA.SUBTITULO'),
      escolharecuperar: this.translate.instant('RECUPERARSENHA.ESCOLHARECUPERAR'),
      recuperar: this.translate.instant('RECUPERARSENHA.RECUPERAR'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      celular: this.translate.instant('GLOBAIS.CELULAR'),
      cpf: this.translate.instant('GLOBAIS.CPF'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      enviar: this.translate.instant('GLOBAIS.ENVIAR'),
    };
  }
}
