import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import { LoginService } from '../../../service/login/login.service';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-perfil',
  templateUrl: 'perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {
  perfis: any = [];
  dataPerfis: object = {};
  perfil: string = '';
  searchForm!: FormGroup;
  searchText = '';
  allow: boolean = false;
  userInfo: any = {};
  currentPerfil: any = {};

  showPage: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private sessionService: SessionService,
    private data: PerfilDataService
  ) {}

  ngOnInit() {
    this.criarFormulario();
    this.userInfo = this.sessionService.getSession();
    this.loginService
      .clientes()
      .subscribe(
        res => this.handleResponse(res),
        error => this.handleError(error)
      );
  }

  getMockFAke() {
    this.perfis = [
      {
        codigo: 287216,
        inicio: '2018-05-30T00:00:00',
        fim: null,
        ativo: true,
        tipoAprovacaoUsuario: {
          codigo: 1,
          descricao: 'APROVAÇÃO AUTOMÁTICA'
        },
        perfilAcesso: { codigo: 3, descricao: 'PROCURADOR', ativo: true },
        cliente: {
          codigo: 1,
          razaoSocial: 'Secretaria da Fazenda do Estado do Ceará',
          dataCriacao: '2018-08-06T14:54:04',
          inicioContrato: '2018-08-06T14:54:04',
          fimContrato: null,
          ativo: true,
          configuracao: null
        },
        usuario: {
          codigo: 620028,
          dataCriacao: '2018-05-24T09:13:33',
          cpfCnpj: '62387804368',
          tipoIdentificacao: 1,
          nomeRazaoSocial: 'MARISTELLA MYRIAN ARAUJO DUQUE',
          situacao: 1,
          dataInativacao: null,
          email: '004857C5@sefaz.ce.gov.br',
          ddd: null,
          celular: null,
          detalhe: null
        }
      },
      {
        codigo: 2196043,
        inicio: '2019-01-10T00:00:00',
        fim: null,
        ativo: true,
        tipoAprovacaoUsuario: {
          codigo: 1,
          descricao: 'APROVAÇÃO AUTOMÁTICA'
        },
        perfilAcesso: { codigo: 2, descricao: 'CONTRIBUINTE', ativo: true },
        cliente: {
          codigo: 1,
          razaoSocial: 'Secretaria da Fazenda do Estado do Ceará',
          dataCriacao: '2018-08-06T14:54:04',
          inicioContrato: '2018-08-06T14:54:04',
          fimContrato: null,
          ativo: true,
          configuracao: null
        },
        usuario: {
          codigo: 620028,
          dataCriacao: '2018-05-24T09:13:33',
          cpfCnpj: '62387804368',
          tipoIdentificacao: 1,
          nomeRazaoSocial: 'MARISTELLA MYRIAN ARAUJO DUQUE',
          situacao: 1,
          dataInativacao: null,
          email: '004857C5@sefaz.ce.gov.br',
          ddd: null,
          celular: null,
          detalhe: null
        }
      }
    ];
  }

  handleResponse(res: any) {
    this.showPage = true;
    this.perfis = res['resultado'];
    this.dataPerfis = res['resultado'];

    this.perfis.forEach((elem: any) => {
      elem['selected'] = false;
      if (elem['usuario']['detalhe'] && elem['usuario']['detalhe'] !== null) {
        elem['usuario'].inscricao =
          elem['usuario']['detalhe']['inscricaoMunicipal'] !== null
            ? elem['usuario']['detalhe']['inscricaoMunicipal']
            : 'Não Informado';
      }
    });
    
    window.localStorage.setItem(
      'hasletters',
      (res['resultado'].length === 1).toString()
    );
  }

  handleError(error: any) {
    console.log(error);
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      prefeitura: ['', Validators.required]
    });
  }

  orderData(val: any) {
    if (val === 'ascendente') {
      let asc = this.perfis;
      asc.sort(this.compare);
    } else {
      let desc = this.perfis;
      desc.sort(this.compare).reverse();
    }
  }

  compare(a: { nome: string; }, b: { nome: string; }) {
    const nomeA = a.nome.toUpperCase();
    const nomeB = b.nome.toUpperCase();
    let comparison = 0;
    if (nomeA > nomeB) {
      comparison = 1;
    } else if (nomeA < nomeB) {
      comparison = -1;
    }
    return comparison;
  }

  select(cliente: any) {
    //console.log(cliente, this.currentPerfil, cliente === this.currentPerfil);
    if (this.currentPerfil === cliente) {
      return;
    }

    this.currentPerfil.selected = false;
    cliente.selected = true;
    this.currentPerfil = cliente;
    this.allow = true;
  }

  desistir() {
    this.router.navigate(['/index']);
    this.userService.sendInfo({ loggedIn: false });
    this.sessionService.clearSession();
  }

  proximo() {
    let obj = this.currentPerfil;
    this.data.storage = obj;
    this.loginService
      .obterSessao(obj.codigo)
      .then((res: any) => {
        if (res['resultado'].perfilAcesso.codigo !== 3) {
          this.userService.sendInfo({ loggedIn: true, ...res['resultado'] });
          console.log("passou")
          this.router.navigate(['home']);
        } else {
          this.userService.sendInfo({ loggedIn: false, ...res['resultado'] });
          this.router.navigate(['/login/procuracoes']);
        }
      })
      .catch(err => {
        const error = err['error'];

        swal.fire(getSwal('error', ``, error.excecao.recomendacao, 'ok', null)).then(
          res => {
            this.userService.sendInfo({
              loggedIn: false
            });
            this.sessionService.clearSession();
            this.router.navigate(['index']);
          }
        );
      });
  }
}
