import { DomSanitizer } from '@angular/platform-browser';
import { ConsultaUsuarioService } from './../../../../../service/consulta-usuario/consultar.user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessagesService } from '../../../../../service/messages/messages.service';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';
import { SessionService } from '../../../../../service/session/session.service';
import moment from 'moment';

import * as FileSaver from 'file-saver';
import swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { ProcuracoesService } from 'src/app/service/procuracoes/procuracoes.service';
import { CpfCnpjPipe } from 'src/app/pipes/cpf-cnpj.pipe';
import { AlertasService } from 'src/app/service/alertas/alertas.service';

@Component({
  selector: '[app-message]',
  templateUrl: 'message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageDetailsComponent implements OnInit {
  subscription: Subscription;
  reply = false;
  mensagem: any = null;
  codigo: number = null;
  mensagens: Array<object> = [];
  index: number = null;
  alreadyReplying = false;
  messagesList: Array<any> = [];
  public nomearAdvogado: boolean = false;  public botaoCiencia: boolean = false;
  loading = false;
  cpfCnpjContribuinte: any;
  nomeRazaoSocialContribuinte: any;
  cpfCnpjProcurador: any;
  nomeRazaoSocialProcurador: any;
  cpfRepresentanteLegal: any;
  nomeRazaoSocialRepresentanteLegal: any;
  cpfCnpjUsuario: any;
  bloquearResposta: boolean = false;
  userLogado: String;
  quantidadeComunicados: number;
  filtroMensagensPa: Object = {};
  token: string = null;
  alertas: Array<any> = [];

  constructor(
    private route: ActivatedRoute,
    private caixaPostalService: CaixaPostalService,
    private sessionService: SessionService,
    private router: Router,
    private consultarUsuarioService: ConsultaUsuarioService,
    private domSanitizer: DomSanitizer,
    private procuracoesService: ProcuracoesService,
    private pipeDoc: CpfCnpjPipe,
    private alertasService: AlertasService,
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.getCurrentNavigation();
    let teste = this.router.getCurrentNavigation();
    if (teste && teste.extras && teste.extras.state) {
      this.filtroMensagensPa = teste.extras.state.obj;
    }
    this.token = sessionService.getToken();
  }

  ngOnInit() {
    this.consultarAlertas();
    this.route.paramMap.subscribe(params => {
      this.codigo = parseInt(params.get('id'));
      this.consultarMensagens();
    });

    let usuario = JSON.parse(localStorage.getItem('session'));
    this.cpfCnpjUsuario = usuario.usuario.cpfCnpj;
  }
  
  consultarAlertas() {
    this.alertasService.getNaoLidos(this.token).subscribe((res: any) => {
      this.alertas = res.resultado;
    });
  }

  consultarMensagens() {
    this.messagesList = [];
    this.loading = true;
    let session = this.sessionService.getSession();
    this.userLogado = session['usuario'].cpfCnpj;

    this.caixaPostalService
      .consultarMensagem(this.codigo)
      .subscribe(res => {
        this.handleResponse(res);
        for (let alerta of this.alertas) {
          if (alerta.complemento == this.codigo) {
            this.alertasService.marcarComoLido(alerta.codigo, this.token);
            break;
          }
        }
      }, error => console.log(error));
  }

  mensagemAnterior() {
    let index =
      this.mensagens.findIndex(
        item => item['protocolo'] == this.mensagem['protocolo']
      ) - 1;
    const mensagem = this.mensagens[index];
    const protocolo = mensagem['protocolo'];
    console.log('index', index)
    this.router.navigate([`/caixa-postal/mensagem/${protocolo}`]);
  }

  mensagemPosterior() {
    let index =
      this.mensagens.findIndex(
        item => item['protocolo'] == this.mensagem['protocolo']
      ) + 1;
    const mensagem = this.mensagens[index];
    const protocolo = mensagem['protocolo'];
    console.log('index', index)
    this.router.navigate([`/caixa-postal/mensagem/${protocolo}`]);
  }

  darCiencia() {
    this.router.navigate([`/caixa-postal/certificados/${this.codigo}`]);
  }

  nomearAdvogados() {
    this.router.navigate([`/caixa-postal/nomear-advogado/${this.codigo}`]);
  }

  arquivarMensagem() {
    this.loading = true;
    const protocolo = this.codigo;
    this.caixaPostalService.arquivarMensagem(protocolo).subscribe(
      res => {
        this.loading = false;

        swal.fire(
          getSwal(
            'success',
            'Sucesso!',
            'O comunicado foi arquivado com êxito.',
            'ok',
            null,
          ),
        ).then(res => {
          this.router.navigate(['/caixa-postal/mensagens']);
        });
      },
      error => {
        this.loading = false;
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o comunicado não pôde ser arquivado.',
            'ok',
            null,
          ),
        );
      },
    );
  }

  desarquivarMensagem() {
    this.loading = true;
    const protocolo = this.codigo;
    this.caixaPostalService.desarquivarMensagem(protocolo).subscribe(
      res => {
        this.loading = false;
        swal.fire(
          getSwal(
            'success',
            'Sucesso!',
            'O comunicado foi desarquivado com êxito.',
            'ok',
            null
          )
        ).then(()=> {
            this.router.navigate(['/caixa-postal/mensagens']);
        });

      },
      error => {
        swal.fire(
          getSwal(
            'info',
            'Ops!',
            'Ocorreu um erro e o comunicado não pôde ser desarquivado.',
            'ok',
            null
          )
        );
      }
    );
  }

  gerarDossie() {
    this.loading = true;
    this.caixaPostalService.gerarDossie(this.codigo).subscribe(
      response => {
        this.loading = false;
        var blob = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(blob, `dossie`);
      },
      error => {
        console.log('erro no download', error);
        this.loading = false;
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro e o download do histórico falhou, tente novamente.',
            'ok',
            null,
          ),
        );
      },
    );
  }

  handleResponse(res) {
    console.log('mensagem: ', res);
    this.mensagem = res.resultado;
    if(res.resultado.tipoComunicado.mensagemPadrao){
      this.mensagem.respostas = this.mensagem.respostas;

      let codigoUsuario = res.resultado.destinatario.usuario.codigo;
      let cpfCnpj = res.resultado.destinatario.usuario.cpfCnpj;
      this.getDestinatario(codigoUsuario, cpfCnpj);
    } else {
      this.mensagem.situacaoMensagem.dataSituacao = moment(this.mensagem.situacaoMensagem.dataSituacao).format('DD/MM/YYYY [às] HH:mm' );
      this.mensagem.respostas = this.mensagem.respostas;
      this.formatMessages(this.mensagem);
    }

    if(this.mensagem.tipoComunicado.respostaBloqueada) {
      this.verificarRespostaBloqueada(this.mensagem.tipoComunicado.prazoCiencia, this.mensagem.dataEnvio);
    }

    this.nomearAdvogado = false;

    if (res.resultado.tipoComunicado.nomearAdvogado) {
      this.nomearAdvogado = true;
    }

    if (this.mensagem.tipoComunicado.tipoPrazo.codigo == 3)
      this.botaoCiencia = true;

    this.loading = false;
    const obj = {};
    if (this.mensagem.situacaoMensagem.situacao === 2) {
      obj['arquivados'] = true;
    }
    this.caixaPostalService.getListaPaginacaoResposta(this.filtroMensagensPa).subscribe(
      response => {
        this.mensagens = response.resultado;
        console.log('mensa', this.mensagens)
        this.quantidadeComunicados = this.mensagens.length
        this.index =
          this.mensagens.findIndex(
            item => item['protocolo'] === this.mensagem['protocolo']
          ) + 1;
      },
      error => console.log(error)
    );
  }

  verificarRespostaBloqueada(prazoCiencia, dataEnvio) {
    if(prazoCiencia > 0) {
      let dataLimite = moment(dataEnvio).add(prazoCiencia, 'days');
      let dataAtual = moment();
      let verificaBloqueio = moment(dataLimite).isAfter(dataAtual);
      if(verificaBloqueio == true) {
        this.bloquearResposta = false;
      } else {
        this.bloquearResposta = true;
      }
    }
  }

  getDestinatario(codigo, cpfCnpj){
    this.consultarUsuarioService.obterUsuario(codigo).subscribe(res => {
      let perfilAcesso = res.perfis;
      perfilAcesso.forEach(data =>{
        if(data.codigo == 2){
          this.cpfCnpjContribuinte = this.pipeDoc.transform(res.cpfCnpj);
          this.nomeRazaoSocialContribuinte = res.nomeRazaoSocial;
        }
        if(data.codigo == 3){
          this.cpfCnpjProcurador = this.pipeDoc.transform(res.cpfCnpj);
          this.nomeRazaoSocialProcurador = res.nomeRazaoSocial;
        }
      })
    });

    this.procuracoesService.getRepresentanteLegal(cpfCnpj).subscribe(res => {
      this.cpfRepresentanteLegal = this.pipeDoc.transform(res['resultado']['outorgante']['cpfCnpj']);
      this.nomeRazaoSocialRepresentanteLegal = this.pipeDoc.transform(res['resultado']['outorgante']['nomeRazaoSocial']);
    })

    setTimeout(() => {
      this.trocaVariaveisTexto();
    }, 500);
  }

  trocaVariaveisTexto() {
    let dataCurta = moment(this.mensagem.dataEnvio).format('DD/MM/YYYY');
    let dataLonga = moment(this.mensagem.dataEnvio).format('LL');

    this.mensagem.mensagem = this.mensagem.mensagem
      .replace(/@{id do representante}/g, this.cpfRepresentanteLegal)
      .replace(/@{representante}/g, this.nomeRazaoSocialRepresentanteLegal)
      .replace(/@{id do contribuinte}/g, this.cpfCnpjContribuinte)
      .replace(/@{contribuinte}/g, this.nomeRazaoSocialContribuinte)
      .replace(/@{id do procurador}/g, this.cpfCnpjProcurador)
      .replace(/@{procurador}/g, this.nomeRazaoSocialProcurador)
      .replace(/@{data curta}/g, dataCurta)
      .replace(/@{data longa}/g, dataLonga)
      .replace(/null/g, "")
      .replace(/undefined/g, "");

    this.mensagem.mensagem = this.domSanitizer.bypassSecurityTrustHtml(this.mensagem.mensagem);
    this.formatMessages(this.mensagem);
  }

  formatMessages(msg) {
    this.messagesList.push(msg);
    msg['respostas'].forEach(element => {
      return this.formatMessages(element);
    });
    return 1;
  }

  checkReplyState(state: boolean) {
    this.alreadyReplying = state;
  }

  replyMessage() {
    this.reply = true;
  }
}
