import { DOCUMENT } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { version } from "src/environments/version";

@Component({
    selector: 'app-rodape',
    templateUrl:'./rodape.component.html',
    styleUrls:['./rodape.component.scss']
})
export class RodapeComponent {
    dominio: string;
    versao: string = version;
    
    constructor(
        @Inject(DOCUMENT) private document: Window
        ) {
            this.dominio = this.document.location.hostname;
    }    
}