import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PerfilUserComponent } from './pages/perfil.component'
import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

const routes: Routes = [{
	path: 'editar',
	component: PerfilUserComponent,
	data: { breadcrumb: 'Perfil' },
},
{
	path: 'editar/:value',
	component: PerfilUserComponent,
	data: { breadcrumb: 'Perfil' },
}];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class PerfilUserRoutingModule {

}