import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { IndexComponent } from "./index/index.component";

const routes: Routes = [
  { path: '', data: {modulo: '0'}, component: IndexComponent },

]

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class IndexRoutingModule {

}