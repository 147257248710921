import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment'
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class RegistroAcoesService {

  private registros = new Subject<any>();
  registros$ = this.registros.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  getData(): Observable<any> {
    const perfisAcesso = this.http.get(`${this.url}/perfisAcesso`).pipe(map(res => res));
    const tiposEvento = this.http.get(`${this.url}/tiposEvento/usuarios/ativos`).pipe(map(res => res));
    return forkJoin([perfisAcesso, tiposEvento]);
  }

  getRegistrosdeAcoes(searchParams: any): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/eventos/usuarios`, { params: params })
      .pipe(map(data => {
        const body = data;
        this.registros.next(body);
        return body;
      }));
  }

  exportarRegistros(searchParams: any): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/eventos/usuarios/exportar`, { 
      params: params,
      responseType: 'arraybuffer'
    })
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

}
