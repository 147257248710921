import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared.module';
import { CommonAppModule } from '../../features/common/common.module';
import { CadastroRoutingModule } from './auto-cadastro.routing.module';
import { ContribuintePessoaFisicaComponent } from './pages/contribuinte-pessoa-fisica/contribuinte-pessoa-fisica.component';
import {NgxMaskModule} from 'ngx-mask';
import { AutoCadastroComponent } from './auto-cadastro.component';
import { WizardComponent } from './componentes/wizard/wizard.component';
import { ResumoComponent } from './pages/resumo/resumo.component';
import { ResumoContribuinteComponent } from './pages/resumo/componentes/contribuinte/contribuinte.component';
import { ProcuradorPessoaFisicaComponent } from './pages/procurador-pessoa-fisica/procurador-pessoa-fisica.component';
import { ResumoProcuradorComponent } from './pages/resumo/componentes/procurador/procurador.component';
import { ContribuintePessoaJuridicaComponent } from './pages/contribuinte-pessoa-juridica/contribuinte-pessoa-juridica.component';
import { RepresentanteLegalComponent } from './pages/representante-legal/representante-legal.component';
import { CodigoComponent } from './pages/codigo/codigo.component';
import { ValidacaoComponent } from './pages/validacao/validacao.component';
import { PopUpTermoAdesaoComponent } from './pages/validacao/componentes/pop-up-termo-adesao/pop-up-termo-adesao.component';
import { IconesValidacaoComponent } from './pages/validacao/componentes/icones-validacao/icones-validacao.component';
import { ProcuradorPessoaJuridicaComponent } from './pages/procurador-pessoa-juridica/procurador-pessoa-juridica.component';
import { CertificadoComponent } from './pages/certificado/certificado.component';


@NgModule({
  imports: [ 
    SharedModule,
    CadastroRoutingModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    ContribuintePessoaFisicaComponent,
    AutoCadastroComponent,
    WizardComponent,
    ResumoComponent,
    ResumoContribuinteComponent,
    ProcuradorPessoaFisicaComponent,
    ContribuintePessoaJuridicaComponent,
    ResumoProcuradorComponent,
    RepresentanteLegalComponent,
    CodigoComponent,
    ValidacaoComponent,
    WizardComponent,
    PopUpTermoAdesaoComponent,
    IconesValidacaoComponent,
    ProcuradorPessoaJuridicaComponent,
    CertificadoComponent
  ],
  providers: [],
})
export class CadastroModule { }
