import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders  } from "@angular/common/http";
import { Observable } from 'rxjs';


import { environment } from '../../../environments/environment'
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CadastroService {

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  clientes(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(`${this.url}/clientes/ativos`).pipe(map(data => { return data }));
  }

  cadastrarContribuinte(data: any): Observable<any> {
    let autocadastro = data;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${this.url}/cadastrarContribuinte`, autocadastro, { headers });
  }

  cadastrarProcurador(data: any): Observable<any> {
    let autocadastro = data;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.post(`${this.url}/cadastrarProcurador`, autocadastro, { headers });
  }


  validarUsuario(codigoUsuario: number, codigoValidacao: any, endpoint: string): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: {
        codigoUsuario: codigoUsuario,
        codigoVerificacao: codigoValidacao
      }
    });
    return this.http.get(`${this.url}/${endpoint}`, {params: params}).pipe(map((data: any) => { return data }));
  }

  definirSenha(codigoUsuario: number, senha: any, endpoint: string): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: {
        codigoUsuario: codigoUsuario,
        senha: senha
      }
    });
    return this.http.get(`${this.url}/${endpoint}`, {params: params}).pipe(map(data => { return data }));
  }

  getConfiguracaoClienteExigeCertificado(): Observable<any> {
    return this.http.get(`${this.url}/exigeautocadastro`).pipe(map(data => { return data }))
  }

  getConfiguracaoClienteExigeCertificadoCNPJ(): Observable<any> {
    return this.http.get(`${this.url}/exigecertificadocnpj`).pipe(map(data => { return data }))
  }

  getConfiguracaoClienteExigeCertificadoCPF(): Observable<any> {
    return this.http.get(`${this.url}/exigecertificadocpf`).pipe(map(data => { return data }))
  }
  
  getEstadoCivil(): Observable<any> {
    return this.http.get(`${this.url}/estadoCivil`).pipe(map(data => {
      return data;
    }));
  }

}
