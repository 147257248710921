import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Desempenho } from 'src/app/models/Desempenho';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DesempenhoService {
  private readonly url: string = environment.BASE_URL_microservico;
  
  constructor(private http: HttpClient) { }

  getDesempenho(): Observable<Desempenho> {
    return this.http.get<Desempenho>(`${this.url}/api/dados-desempenho/dadosPortal`);
  }

  getIp(): Observable<any> {
    return this.http.get('https://api.ipify.org?format=json');
  }
}
