import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Subject } from 'rxjs';
// import "rxjs/add/operator/catch";
// import "rxjs/add/operator/map";
// import "rxjs/add/observable/throw";

import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class CaixaPostalService {
  private mensagens = new Subject<any>();
  mensagens$ = this.mensagens.asObservable();

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  getMessagesNumbers(): Observable<any> {
    const comResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensComRespostas`)
      .pipe(map((res) => res));
    const enviadas = this.http
      .get(`${this.url}/mensagens/totalMensagensEnviadas`)
      .pipe(map((res) => res));
    const semResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensSemRespostas`)
      .pipe(map((res) => res));
    return forkJoin([comResposta, enviadas, semResposta]);
  }

  getMessagesNumbersToBadge(): Observable<any> {
    const comResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensComRespostas`)
      .pipe(map((res) => res));
    const ciencia_tacita = this.http
      .get(`${this.url}/mensagens/totalMensagensCienciaTacita`)
      .pipe(map((res) => res));
    const enviadas = this.http
      .get(`${this.url}/mensagens/totalMensagensEnviadas`)
      .pipe(map((res) => res));
    const semResposta = this.http
      .get(`${this.url}/mensagens/totalMensagensSemRespostas`)
      .pipe(map((res) => res));
    const nao_lidas = this.http
      .get(`${this.url}/mensagens/totalMensagensEnviadas?lidas=false`)
      .pipe(map((res) => res));

    const cienciaAVencer = this.http
      .get(
        `${this.url}/mensagens/totalMensagensCienciaTacitaAVencerContribuinte`
      )
      .pipe(map((res) => res));

    const cienciaVencidas = this.http
      .get(
        `${this.url}/mensagens/totalMensagensCienciaTacitaVencidasContribuinte`
      )
      .pipe(map((res) => res));

    const naoLidosNoPrazoDeLeitura = this.http
      .get(`${this.url}/mensagens/totalComunicadosNaoLidosDentroPrazoLeitura`)
      .pipe(map((res) => res));

    const lidosDentroPrazoLeitura = this.http
      .get(`${this.url}/mensagens/totalComunicadosLidosDentroPrazoLeitura`)
      .pipe(map((res) => res));

    return forkJoin([
      comResposta,
      ciencia_tacita,
      enviadas,
      semResposta,
      nao_lidas,
      cienciaAVencer,
      cienciaVencidas,
      naoLidosNoPrazoDeLeitura,
      lidosDentroPrazoLeitura,
    ]);
  }

  getMensagens(searchParams: object): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/mensagens`, { params: params }).pipe(
      map((data) => {
        const body = data;
        this.mensagens.next(body);
        return body;
      })
    );
  }
  
  getListaPaginacaoResposta(searchParams): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/mensagens/listaPaginacao`, { params })
      .pipe(map(data => {
        const body = data;
        this.mensagens.next(body);
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  consultarMensagem(codigo: any): Observable<any> {
    const protocolo = codigo;
    return this.http.get(`${this.url}/mensagens/${protocolo}`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  consultarPopUpMensagem(): Observable<any> {
    return this.http.get(`${this.url}/mensagens/popup`).pipe(
      map((data) => {
        return data;
      })
    );
  }

  consultarContribuinte(cpf_cnpj: string): Observable<any> {
    const params = new HttpParams().set('cpf_cnpj', cpf_cnpj);
    return this.http.get(`${this.url}/contribuintes`, { params: params }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  mensagemLida(protocolo: number): Observable<any> {
    const params = new HttpParams().set('protocolo', protocolo);
    return this.http
      .get(`${this.url}/mensagens/marcarComoLida`, { params: params })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  arquivarMensagem(protocolo: any): Observable<any> {
    const params = new HttpParams().set('protocolo', protocolo);
    return this.http
      .get(`${this.url}/mensagens/arquivar`, { params: params })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  desarquivarMensagem(protocolo: any): Observable<any> {
    const params = new HttpParams().set('protocolo', protocolo);
    return this.http
      .get(`${this.url}/mensagens/desarquivar`, { params: params })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  gerarDocumentoCiencia(protocolo: number, certificado: any): Observable<any> {
    const fd = new FormData();
    fd.append('certificado', certificado);
    return this.http
      .post(`${this.url}/mensagens/${protocolo}/gerarDocumentoCiencia`, fd, {
        headers: new HttpHeaders().set('Content-Type', 'multipart/form-data'),
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  darCiencia(protocolo: number, documento: object): Observable<any> {
    return this.http
      .post(`${this.url}/mensagens/${protocolo}/darCiencia`, documento)
      .pipe(
        map((data) => {
          let body = data;
          return body;
        })
      );
  }

  gerarDossie(protocolo: number): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/${protocolo}/dossie`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  gerarProtocolo(protocolo: number): Observable<any> {
    return this.http.get(`${this.url}/mensagens/${protocolo}/protocolo`, {
      responseType: 'arraybuffer',
    });
  }

  nomearAdvogado(protocolo: number, procuracoes: any): Observable<any> {
    return this.http.post(
      `${this.url}/mensagens/${protocolo}/nomearAdvogados`,
      procuracoes
    );
  }

  responderMensagem(
    protocolo: string,
    mensagem: string,
    anexos: any
  ): Observable<any> {
    const fd = new FormData();
    fd.append('protocolo', protocolo);
    fd.append('mensagem', mensagem);
    Array.from(Array(anexos.length).keys()).map((x) => {
      fd.append('anexos', anexos[x], anexos[x].name);
    });
    return this.http.post(`${this.url}/mensagens/responder`, fd, {
      headers: new HttpHeaders()
        .set('Content-Type', 'multipart/form-data,charset=UTF-8')
        .set('Content-Transfer-Encoding', 'charset=UTF-8'),
    });
  }

  baixarAnexo(protocolo: number, codigoAnexo: number) {
    return this.http.get(
      `${this.url}/mensagens/${protocolo}/anexos/${codigoAnexo}`,
      {
        responseType: 'arraybuffer',
      }
    );
  }

  baixarTodosAnexos(protocolo: number) {
    return this.http.get(`${this.url}/mensagens/${protocolo}/anexos`, {
      headers: new HttpHeaders().set('Content-Type', 'application/zip'),
      responseType: 'arraybuffer',
    });
  }

  enviarMensagem(mensagem: any) {
    return this.http.post(`${this.url}/mensagens/enviar`, mensagem);
  }

  obterAplicacoesPorTipoComunicado(id: number): Observable<any> {
    return this.http
      .get(`${this.url}/mensagens/listaAplicacoesPorTipoComunicado/${id}`)
      .pipe(
        map((data) => {
          const body = data;
          return body;
        })
      );
  }
}
