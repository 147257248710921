import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filtro-contribuinte',
  templateUrl: 'filtro-contribuinte.component.html',
  styleUrls: ['./filtro-contribuinte.component.scss'],
})
export class FiltroContribuinteComponent implements OnInit {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();

  mascara: string = '000.000.000-00';
  tipo: string = 'cpf';
  contribuinteForm!: FormGroup;
  @Input()
  visible!: string;
  contribuinte: boolean = false;
  labels!: any;

  constructor(private fb: FormBuilder, private translate: TranslateService) {
    this.setLabels();
  }

  ngOnInit() {
    this.criarFormulario();
  }

  criarFormulario() {
    this.contribuinteForm = this.fb.group({
      tipo: '',
      numero: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)]),
      ],
    });
  }

  alterarIdentificador(tipo: string) {
    this.contribuinteForm.controls.numero.reset();

    switch (tipo) {
      case 'cpf':
        this.mascara = '000.000.000-00';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.minLength(11),
        );
        this.contribuinteForm.controls.numero.updateValueAndValidity();
        break;
      case 'cnpj':
        this.mascara = '99.999.999/9999-99';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.minLength(8),
        );
        this.contribuinteForm.controls.numero.updateValueAndValidity();
        break;
      case 'inscricao':
        this.mascara = 'A*';
        this.contribuinteForm.controls.numero.setValidators(
          Validators.minLength(1),
        );
        this.contribuinteForm.controls.numero.updateValueAndValidity();
        break;
    }
  }

  limparFiltros() {
    this.onClear.emit(this.contribuinteForm.value);
    this.contribuinte = false;
    // this.visible = false;
    this.contribuinteForm.reset();
  }

  pesquisarContribuinte() {
    this.contribuinte = true;
    // this.visible = false;
    const value = this.contribuinteForm.value;
    let obj: any = {};
    value.tipo === 'inscricao'
      ? (obj['inscricao'] = value.numero)
      : (obj['cpf_cnpj'] = value.numero);
    this.onFilter.emit(obj);
  }

  setLabels() {
    this.labels = {
      cgf: this.translate.instant('GLOBAIS.INSCRICAO'),
    };
  }
}
