import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { PerfilDataService } from '../../service/perfil/perfil.service';

@Injectable()
export class LoginService {
  url: string = environment.BASE_URL;
  dadosCertificado: any;

  constructor(
    private http: HttpClient,
    private perfilService: PerfilDataService
  ) {}

  login(credentials: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: { cpfCnpj: credentials.cpfCnpj, senha: credentials.senha, sistema: '0' }
    });
    return this.http.get(`${this.url}/autenticar`, { params: params })
      .pipe(map((data: any) => {
        let body = data;
        return body;
    }));
  }

  clientes(): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        sistema: '0'
      }
    });
    return this.http.get(`${this.url}/listarClientes`, { params: params }).pipe(map(data => {
        return data;
      }));
  }

  obterSessao(codigo: number) {
    return new Promise((resolve, reject) => {
      this.getObterSessao(codigo).subscribe((res: Object) => {
          this.getImages(res).then(sessionObject => {
              resolve(sessionObject);
            }).catch(error => {
              reject(error);
            });
        },
        (error: Object) => {
          reject(error);
        }
      );
    });
  }

  getObterSessao(codigo: number): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        codigoUsuarioCliente: codigo,
        sistema: '0'
      }
    });
    return this.http.get(`${this.url}/obterSessaoUsuario`, { params: params }).pipe(
      map(
        data => {
          return data;
        },
        (error: Object) => {
          return error;
        }
      )); 
  }

  getImages(sessionObject: any) {
    return new Promise((resolve, reject) => {
      this.getUserImage()
        .then((userImage: any) => {
          if (userImage !== null) {
            sessionObject.resultado.usuario.imagem = userImage;
          }
          return this.getClientImage();
        })
        .then(clientImage => {
          if (clientImage !== null) {
            if (sessionObject.resultado.cliente.configuracao === null) {
              sessionObject.resultado.cliente.configuracao = {};
            }
            sessionObject.resultado.cliente.configuracao.imagem = clientImage;
          }
          resolve(sessionObject);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  getUserImage() {
    return new Promise((resolve, reject) => {
      this.perfilService.getUserImage().subscribe(
        image => {
          if (image !== null) {
            this.getProcessedImageBlob(image).then((img: any) => {
              resolve(img);
            });
          } else {
            resolve(null);
          }
        },
        error => {
          resolve(null);
        }
      );
    });
  }

  getProcessedImageBlob(imageBlob: Blob) {
    return new Promise((resolve, reject) => {
      let reader: any = new FileReader();
      reader.addEventListener('load', () => {
        resolve(reader.result.toString());
      },
        false
      );
      reader.addEventListener(
        'error',
        () => {
          console.log('error');
        },
        false
      );

      if (imageBlob) {
        reader.readAsDataURL(imageBlob);
      }
    });
  }

  getClientImage() {
    return new Promise((resolve, reject) => {
      this.perfilService.getClientImage().subscribe(
        image => {
          if (image !== null) {
            this.getProcessedImageBlob(image).then(img => {
              resolve(img);
            });
          } else {
            resolve(null);
          }
        },
        error => {
          resolve(null);
        }
      );
    });
  }

  getNonce(): Observable<any> {
    return this.http.get(`${this.url}/obterNonce`).pipe(map(data => {
      let dados = JSON.stringify(data);
      localStorage.setItem('certificado', dados);
      return data;
    }));
  }

  loginViaCertificado(
    nonce: string | Blob,
    certEndcoded: string | Blob,
    assinatura: string | Blob,
    certificado: any
  ): Observable<any> {
    this.dadosCertificado = {
      nonce: nonce,
      certEndcoded: certEndcoded,
      assinatura: assinatura,
      certificado: certificado
    };
    const fd = new FormData();
    fd.append('nonce', nonce);
    fd.append('certificado', certEndcoded);
    fd.append('assinatura', assinatura);
    fd.append('sistema', '0');
    return this.http
      .post(`${this.url}/autenticarPorCertificadoDigital`, fd)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  loginViaCertificadoEmpresas(
    nonce: string | Blob,
    certEndcoded: string | Blob,
    assinatura: string | Blob,
    codigoEmpresa: string | Blob
  ): Observable<any> {
    const fd = new FormData();
    fd.append('nonce', nonce);
    fd.append('certificado', certEndcoded);
    fd.append('assinatura', assinatura);
    fd.append('codigoEmpresa', codigoEmpresa);
    fd.append('sistema', '0');
    return this.http
      .post(`${this.url}/autenticarPorCertificadoDigital`, fd)
      .pipe(map((data: any) => {
        return data;
      }));
  }

  procuracoes(): Observable<any> {
    return this.http
      .get(`${this.url}/obterProcuracoesAtivasDoProcurador`);
  }

  atualizarSessao(codigoProcuracao: any) {
    return new Promise((resolve, reject) => {
      this.getAtualizarSessao(codigoProcuracao).subscribe(
        (res: any) => {
          this.getImages(res)
            .then(sessionObject => {
              resolve(sessionObject);
            })
            .catch(error => {
              reject(error);
            });
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getAtualizarSessao(codigoProcuracao: any): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        codigoProcuracao: codigoProcuracao
      }
    });
    return this.http
      .get(`${this.url}/atualizarSessaoUsuario`, { params: params });
  }

  trocarProcuracao(codigoProcuracao: any): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        codigoProcuracao: codigoProcuracao
      }
    });
    return this.http
      .get(`${this.url}/trocarProcuracao`, { params: params });
  }

  loginCnpj(credentials: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams({
      fromObject: {
        codigoEmpresa: credentials.codigoEmpresa,
        cpfCnpj: credentials.cpfCnpj,
        senha: credentials.senha,
        sistema: '0'
      }
    });
    return this.http
      .get(`${this.url}/autenticar`, { params: params })
  }
}
