import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators,AbstractControl } from '@angular/forms';
import { PerfilDataService } from '../../../service/perfil/perfil.service';
import Swal from "sweetalert2";
import moment from 'moment';
import { getSwal } from '../../../utils/swalObject';
import { CpfCnpjPipe } from '../../../pipes/cpf-cnpj.pipe';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-perfil',
  templateUrl: 'perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilUserComponent implements OnInit {
  EditUser: FormGroup;
  user: object = null;
  loadDataCriacao: any;

  constructor(
    private perfilDataService: PerfilDataService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private docPipe: CpfCnpjPipe,
  ) {}

  ngOnInit() {
    this.createForm();
    this.perfilDataService.getDateUSer().subscribe(data => {
      this.updateForm(data.resultado);
    });
  }

  private customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } {
    const emailError = Validators.email(control);
    if (control.value && emailError) {
      return { email: {} };
    }

    return null;
  }


  createForm() {
    this.EditUser = this.fb.group({
      tipoIdentificacao: [{ value: '', disabled: true }, Validators.required],
      cpfCnpj: [{ value: '', disabled: false }, Validators.required],
      nomeRazaoSocial: [{ value: '', disabled: false }, Validators.required],
      dataCriacao: [{ value: '', disabled: true }, Validators.required],
      situacao: [{ value: '', disabled: false }, Validators.required],
      email: [{ value: '', disabled: false }, Validators.required],
      ddd:  [{ value: '', disabled: false }],
      celular:  [{ value: '', disabled: false }],
      detalhe: this.fb.group({
        inscricaoMunicipal:  [{ value: '', disabled: false }],
        documentoLink:  [{ value: '', disabled: false }],
        situacaoUsuario: [{value: '', disabled: false}, Validators.required],
      }),
    });
  }

  updateForm(data) {
    for (let key in data) {
      if (data[key] && this.EditUser.controls[key]) {
        if (key === 'cpfCnpj') {
          this.EditUser.controls[key].patchValue(
            this.docPipe.transform(data[key]),
          );
        } else {
          this.EditUser.controls[key].patchValue(data[key]);
        }
      }
    }
    this.user = data;
    this.loadDataCriacao = data['dataCriacao'];
    const dateString = moment(new Date(data['dataCriacao'])).format(
      'DD/MM/YYYY - HH:mm:ss',
    );
    this.EditUser.controls['dataCriacao'].patchValue(dateString);
  }

  onSubmit() {
    const form = this.EditUser.getRawValue();
    console.log('form', form);
    for (let key in form) {
      if (key !== 'detalhe' && this.user[key] !== undefined) {
        this.user[key] = form[key];
      } else if (key === 'detalhe') {
        let detalhe = form['detalhe'];
        for (let key in detalhe) {
          if (typeof detalhe[key] === 'undefined' || detalhe[key] === '') {
            detalhe[key] = null;
          }
        }
        this.user['detalhe'] = detalhe;
      }
    }
    this.user['dataCriacao'] = this.loadDataCriacao;

    this.perfilDataService.editarUsuario(this.user).subscribe(
      resposta => {
        Swal.fire(
          getSwal(
            'success',
            'Alterado',
            'Usuário Atualizado com Sucesso',
            'ok',
            null,
          ),
        ).then(res => {
          this.router.navigate(["/home"]);
        });
      },
      error => {
        console.log('error', error);
        Swal.fire(
          getSwal(
            'error',
            'Ops!',
            error.error.excecao.recomendacao,
            'ok',
            null,
          )
        );
        
      },
    );
  }

  desistir() {
    this.router.navigate(["/home"]);
  }

  ajuda() {
    Swal.fire({
      html:'Se estiver em Sim, o usuário, assim como os comunicados trocados por este, não irão ser contabilizados em relatórios e consultas.',
      confirmButtonText:'Fechar'
    })
  }
}