import { Component, OnInit, Output, EventEmitter, Input, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-filtro-arquivados',
  templateUrl: 'filtro-arquivados.component.html',
  styleUrls: ['./filtro-arquivados.component.scss'],
})
export class FiltroArquivadosComponent implements OnInit, AfterViewChecked {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Input() resetFilte!: boolean;
  arquivados: boolean = false;
  @Input()
  visible!: string;
  @Input() filtroAtivo: any;

  obj: any = {
    arquivados: false,
  };

  constructor() {}

  ngOnInit() {}

  ngAfterViewChecked() {
    const sessaoFiltro = JSON.parse(sessionStorage.getItem('filtro')!);
    if(sessaoFiltro) {
      if(sessaoFiltro.arquivados) {
        this.filtroAtivo = true;
      } else {
        this.filtroAtivo = false;
      }
    } else {
      this.filtroAtivo = false;
    }
  }

  limparFiltros() {
    this.onClear.emit(this.obj);
    this.arquivados = false;
    this.filtroAtivo = false;

    let storage = JSON.parse(sessionStorage.getItem('filtro')!);
    if(storage.arquivados) {
      delete storage.arquivados;
    }

    sessionStorage.setItem('filtro', JSON.stringify(storage));
  }

  pesquisarArquivados() {
    this.arquivados = true;
    this.obj['arquivados'] = true;
    this.onFilter.emit(this.obj);
    this.filtroAtivo = true;
  }
}
