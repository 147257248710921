import { Component, OnInit, Output, Input, EventEmitter, OnChanges, AfterViewChecked } from '@angular/core';
import * as moment from 'moment';

import { Moment } from 'moment';

@Component({
  selector: 'app-filtro-periodo',
  templateUrl: 'filtro-periodo.component.html',
  styleUrls: ['./filtro-periodo.component.scss'],
})
export class FiltroPeriodoComponent implements OnInit {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Input() resetFilter!: boolean;
  @Input() filtroAtivo: any = false;

  periodo: boolean = false;
  dataInicial: any = null;
  dataFinal: any = null;
  @Input()
  visible!: string;
  inicio!: any;
  fim!: any;
  dataInclusaoValida: boolean = false;
  dataFimInclusaoValida: boolean = false;

  obj = {
    data_inicial: moment(),
    data_final: moment(),
  };
  configuracaoData: Object = {
    locale: 'pt-br'
  }

  constructor() {
    sessionStorage.removeItem('filtro');
  }

  ngOnInit() { }

  selecionarDataInicial(event: any) {
    this.inicio = event;
    console.log('thisinicio', this.inicio)
    this.obj['data_inicial'] = this.inicio
      ? this.inicio.format()
      : null;    if (this.inicio > this.fim) {
      setTimeout(() => {
        this.obj['data_final'] = null; //  moment.Moment;
        this.fim = null; //moment.Moment;
      }, 100);
    }
  }

  selecionarDataFinal(event) {
    this.fim = event;
    console.log('thisfim', this.fim)

    const dataAtual = moment();
    const dataSelecionadaFim = moment(this.fim);
    if (dataSelecionadaFim.isAfter(dataAtual, 'day')) {
      this.dataFimInclusaoValida = false;
      return;
    }

    if (this.fim) {
      this.dataFinal = this.fim;
      this.dataFimInclusaoValida = true;
    }
    this.obj['data_final'] = this.fim ? this.fim.format() : null;
  }


  pesquisarPeriodo() {
    this.periodo = true;
    this.filtroAtivo = true;
    this.onFilter.emit(this.obj);
  }

  limparFiltros() {
    this.onClear.emit(this.obj);
    this.obj = {
      data_inicial: null,
      data_final: null,
    };

    this.fim = null;
    this.inicio = null;
    this.periodo = false;
    this.filtroAtivo = false;

    let storage = JSON.parse(sessionStorage.getItem('filtro')!);
    if (storage.data_inicial && storage.data_final) {
      delete storage.data_inicial
      delete storage.data_final
    }

    sessionStorage.setItem('filtro', JSON.stringify(storage));
  }

  resetarParametros() {
    this.onClear.emit(this.obj);
    this.obj = {
      data_inicial: null,
      data_final: null,
    };

    this.fim = null;
    this.inicio = null;
    this.periodo = false;
    this.filtroAtivo = false;

    let storage = JSON.parse(sessionStorage.getItem('filtro')!);
    if (storage.data_inicial && storage.data_final) {
      delete storage.data_inicial
      delete storage.data_final
    }

    sessionStorage.setItem('filtro', JSON.stringify(storage));
  }
}
