import { Component, OnInit, Input } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { DatePipe } from "@angular/common";
import { CaixaPostalService } from "../../../../../service/caixa-postal/caixa-postal.service";
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import * as FileSaver from "file-saver";
import { CpfCnpjPipe } from "../../../../../pipes/cpf-cnpj.pipe";

import { getSwal } from "../../../../../utils/swalObject";
import * as moment from "moment";
import { SessionService } from "src/app/service/session/session.service";

@Component({
  selector: "app-messages-list",
  templateUrl: "messages-list.component.html",
  styleUrls: ["./messages-list.component.scss"]
})
export class MessagesComponent implements OnInit {
  @Input() view = 0;

  selected!: number;
  mensagens: any = [];
  loading: boolean = true;
  usuario!: Object;
  dataContribuinte!: Object;
  exibirPopUpContribuinte: boolean = false;
  enviarMensagem: boolean = false;
  podeEnviarComunicados!: boolean;
  escreverMensagem: boolean = false;
  tipoComunicado!: Object;
  detalhesLeitura: boolean = false;
  data: any = {
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0,
    itemsPorPagina: 10
  };
  index!: number;
  filterVisible: number = 0;
  numeroComunicados: string = "10";
  cpfCnpjUsuario!: string;
  dadosLeitura: any;
  filtroMensagensPag: Object = {}

  //showEnviarButton: boolean = appClient.indexOf("sefaz") === -1;

showEnviarButton: boolean = this.enviarMensagem;

public messagesNumbers: any = {
    recebidas: "",
    respondidas: "",
    ciencia_tacita: "",
    a_vencer_ciencia: ""
  };
  public currentFilter: any = {
    ciencia_tacita: false,
    a_vencer: false,
    respondidas: false,
    total_pagina: 5
  };
  public currentBadge: string = "";
  public showBadges: boolean = false;
  public _showBadges: boolean = false;
  public toResetFilter: boolean = false;
  public toCleanBadges: boolean = true;
  labels: any;

  constructor(
    private caixaPostalService: CaixaPostalService,
    private router: Router,
    private translate: TranslateService,
    private docPipe: CpfCnpjPipe,
    private sessionService: SessionService,
  ) {
    this.setLabels();
  }

  ngOnInit() {
    // this.caixaPostalService.mensagens$.subscribe(res => {
    //   this.mensagens = res.resultado;
    // });
  }

  ngAfterViewInit() {
    this.obterComunicadosPlataformaDTe()
      .then(result => {
        this.podeEnviarComunicados = true;
        // Carregamento da mensagens a cada 10 segundos
        // setInterval(() => {
        //   this.loadMensagens();
        // }, 10000);
        return this.loadMensagens();
      })
      .then(result => {
        this.loading = false;
      })
      .catch(err => {
        console.log("error: ", err);
      });
    this.getMessagesNumbers();
  }

  obterComunicadosPlataformaDTe() {
    return new Promise((resolve, reject) => {
      resolve('');
      //   this.comunicadosService.obterComunicadosPlataformaDTe().subscribe(
      //     res => {
      //       console.log(' obterComunicadosPlataformaDTe : ', res);
      //       if (res.resultado.length !== 0) {
      //         this.podeEnviarComunicados = true;
      //         this.comunicadosList = res.resultado;
      //       }
      //       resolve();
      //     },
      //     err => {
      //       reject(err);
      //     },
      //   );
    });
  }

  loadMensagens() {
    this.loading = true;
    return new Promise((resolve, reject) => {
      var parametros: any = {};
      parametros = Object.assign(parametros, JSON.parse(sessionStorage.getItem('filtro')!));
      if(parametros['protocolo']) {
        delete parametros['protocolo'];
      }
      parametros["pagina"] = this.data["paginaAtual"];
      parametros["total_pagina"] = this.data["itemsPorPagina"];

      this.caixaPostalService.getMensagens(parametros).subscribe(
        res => {
          this.filtroMensagensPag = parametros;
          delete this.filtroMensagensPag["pagina"]
          delete this.filtroMensagensPag["paginaAtual"]
          delete this.filtroMensagensPag["total_pagina"]
          this.processGetMessagesResult(res);
          this.loading = false;
          resolve('');
        },
        error => reject(error)
      );
    });
  }

  getMessagesNumbers() {
    this.caixaPostalService.getMessagesNumbersToBadge().subscribe(
      data => {
        this.messagesNumbers["respondidas"] = data[0].resultado;
        this.messagesNumbers["ciencia_tacita"] = data[1].resultado;
        this.messagesNumbers["recebidas"] = data[2].resultado;
        this.messagesNumbers["a_vencer_ciencia"] = data[5].resultado;
        this.messagesNumbers["vencidas_ciencia"] = data[6].resultado;
        this.messagesNumbers["naoLidosNoPrazoDeLeitura"] = data[7].resultado;
        this.messagesNumbers["lidosDentroPrazoLeitura"] = data[8].resultado;
      },
      error => {
        console.log(error);
      }
    );
  }

  gerarProtocolo(mensagem: any) {
    this.loading = true;
    this.caixaPostalService.gerarProtocolo(mensagem.protocolo).subscribe(
      response => {
        this.loading = false;
        var blob = new Blob([response], { type: "application/pdf" });
        FileSaver.saveAs(blob, `dossie`);
      },
      error => {
        this.loading = false;
        swal.fire(
          getSwal(
            "success",
            "",
            "Comunicado " +
              mensagem.assunto +
              " recebido em " +
              moment(mensagem.dataEnvio).format("DD/MM/YYYY HH:mm:ss") +
              " de número " +
              mensagem.protocolo,
            "ok",
            null
          )
        );
      }
    );
  }

  fecharPopUpLeitura(event: any) {
    this.detalhesLeitura = false;
  }

  next() {
    this.data["paginaAtual"]++;
    this.onFilterChange(this.currentFilter);
  }

  previous() {
    this.data["paginaAtual"]--;
    this.onFilterChange(this.currentFilter);
  }

  primeiraPagina() {
    this.data['paginaAtual'] = 1;
    this.onFilterChange(this.currentFilter);
  }

  ultimaPagina() {
    this.data['paginaAtual'] = this.data['totalPaginas'];
    this.onFilterChange(this.currentFilter);
  }

  onFilterByBadge(mode: string): void {
    delete this.currentFilter["ciencia_tacita"];
    delete this.currentFilter["nao_lidas"];
    delete this.currentFilter["a_vencer"];
    delete this.currentFilter["respondidas"];
    switch (mode) {
      case "ciencia_tacita":
        this.currentFilter["ciencia_tacita"] = true;
        break;
      case "recebidas":
        delete this.currentFilter["ciencia_tacita"];
        delete this.currentFilter["nao_lidas"];
        delete this.currentFilter["a_vencer"];
        delete this.currentFilter["respondidas"];
        break;
      case "todas":
        this.toResetFilter = true;
        this.currentFilter = {};
        this.currentBadge = "";
        break;
      case "respondidas":
        this.currentFilter["respondidas"] = true;
        break;
      case "nao_lidas":
        this.currentFilter["nao_lidas"] = true;
        break;
    }
    this.onFilterChangeWithBadge(this.currentFilter);
    //mode == this.currentBadge ? this.currentBadge = "" : this.currentBadge = mode;
  }

  cleanBadgesFilters(): void {
    // this.toResetFilter = true;
    // setTimeout(()=>{
    //   this.toResetFilter = false;
    //   this.currentFilter['ciencia_tacita'] = false;
    //   this.currentFilter['a_vencer'] = false;
    //   this.currentFilter['respondidas'] = false;
    //   this.onFilterChangeWithBadge(this.currentFilter);
    //   this.currentBadge = "";
    // },200);
  }

  onResetBadges(): void {}

  onFilterChange(value) {
    if (typeof value !== 'object' || value === null) {
      value = {};
    }

    value["pagina"] = this.data["paginaAtual"];
    value["total_pagina"] = this.data["itemsPorPagina"];
    value['paginaAtual'] = this.data['paginaAtual'] = 1
    this.currentFilter = value;
    if (this.toResetFilter) {
      this.toResetFilter = true;
      this.onFilterChangeWithBadge(this.currentFilter);
    } else {
      this.loading = true;
      this.toCleanBadges = true;
      this.caixaPostalService.getMensagens(value).subscribe(
        data => {
          // this.mensagens = data.resultado;
          this.processGetMessagesResult(data);
          this.toResetFilter = false;
          this.loading = false;
          this.showBadges = true;
        },
        error => {
          // this.onDataChange.emit(false);
        }
      );
    }
  }

  onFilterChangeWithBadge(value: any) {
    let count = 0;
    this.currentFilter = value;
    this.loading = true;
    this.toCleanBadges = false;
    this.caixaPostalService.getMensagens(value).subscribe(
      data => {
        this.filtroMensagensPag = value;
        delete this.filtroMensagensPag["pagina"]
        delete this.filtroMensagensPag["paginaAtual"]
        delete this.filtroMensagensPag["total_pagina"]
        this.processGetMessagesResult(data);
        this.loading = false;
        this.showBadges = true;
        this.toResetFilter = false;
        //window.scrollTo(0, 360);
      },
      error => {
        // this.onDataChange.emit(false);
      }
    );
  }

  iniciarComunicado() {
    this.filterVisible += 1;
    this.enviarMensagem = true;
	  this.showEnviarButton = true;
  }

  processGetMessagesResult(res: any) {
    res.resultado.forEach((mensagem: any) => {
      mensagem.remetenteTipo =
        mensagem.remetente.aplicacao && mensagem.remetente.aplicacao !== null
          ? "aplicacao"
          : "usuario";

      mensagem.destinatarioTipo =
        mensagem.destinatario.aplicacao &&
        mensagem.destinatario.aplicacao !== null
          ? "aplicacao"
          : "usuario";

      mensagem.classDataEnvioIcon =
        mensagem.remetenteTipo === "aplicacao"
          ? "resposta-aplicacao"
          : "resposta-usuario";

      mensagem.mensagemLimpa = this.cleanMessage(mensagem.mensagem);

      // TODO mock coments
      mensagem.cienciaMensagem =
        mensagem.cienciaMensagem === null ? [] : mensagem.cienciaMensagem;

      mensagem.cienciaMensagem.forEach((cienciaMe: any) => {
        if (cienciaMe.usuario) {
          cienciaMe.usuario.cpfCnpj = this.docPipe.transform(
            cienciaMe.usuario.cpfCnpj
          );
        }
      });
    });

    this.mensagens = res.resultado;
    
    this.mensagens.forEach((res: any, index: any) => {
      // ----- REMETENTE -----
      if(this.mensagens[index].remetente.usuario === null) {
        this.mensagens[index].remetente = this.mensagens[index].remetente.aplicacao.descricao;
      } else {
        this.mensagens[index].remetenteCpfCnpj = this.mensagens[index].remetente.usuario.cpfCnpj;
        this.mensagens[index].remetente = this.mensagens[index].remetente.usuario.nomeRazaoSocial;
      }

      // ----- DATA DE ENVIO -----
      this.mensagens[index].dataEnvio = moment(this.mensagens[index].dataEnvio).format('DD/MM/YYYY');
      // ----- DATA DE LEITURA -----
      if(this.mensagens[index].leitura.length != 0) { 
        this.mensagens[index].dataLeitura = moment(this.mensagens[index].leitura[0].dataCiencia).format('DD/MM/YYYY');
        } else {
          this.mensagens[index].dataLeitura = '';
        }
      // ----- DATA DE CIÊNCIA -----
      if(this.mensagens[index].cienciaMensagem.length != 0) {
        if(this.mensagens[index].destinatario.aplicacao == null) {
          this.mensagens[index].cienciaMensagem.forEach((res: any) => {
            if(res.usuario.cpfCnpj == this.docPipe.transform(this.mensagens[index].destinatario.usuario.cpfCnpj)) {
              this.mensagens[index].dataCiencia = moment(res.dataCiencia).format('DD/MM/YYYY');
            }
          })
        } else {
          this.mensagens[index].dataCiencia = moment(this.mensagens[index].cienciaMensagem[0].dataCiencia).format('DD/MM/YYYY');
        }
      } else {
        this.mensagens[index].dataCiencia = '';
      }
      if(this.mensagens[index].tipoComunicado.prazoCiencia > 0) {
        let diasPrazo = this.mensagens[index].tipoComunicado.prazoCiencia;
        this.mensagens[index].dataLimite = moment(this.mensagens[index].dataEnvio, 'DD/MM/YYYY HH:mm:ss').add(diasPrazo,'days').format('DD/MM/YYYY');
      } else {
        this.mensagens[index].dataLimite = '';
      }
    })
    if (Math.round(this.data["paginaAtual"]) > Math.round(res.totalPaginas)) {
      this.data["paginaAtual"] = 1;
      // this.onFilterChange(this.filterCurrent);
    } else {
      this.data["totalPaginas"] = res.totalPaginas;
      this.data["paginaAtual"] = res.paginaAtual;
      this.data["primeiraPagina"] = res.primeiraPagina;
      this.data["ultimaPagina"] = res.ultimaPagina;
    }
    this.data['totalDeItems'] = res.totalRegistros;
  }

  cleanMessage(message: any) {
    return message
      .toLowerCase()
      .split("<br>")
      .join(" ");
  }

  setLoadingState(e: any) {
    this.loading = e;
  }

  getColor(tipoComunicado: any) {
    if (tipoComunicado.cor !== null) {
      return tipoComunicado.cor.cor;
    } else {
      return "#a1a1a1";
    }
  }

  getTextColor(tipoComunicado: any){
    if (tipoComunicado.cor !== null) {
      if(tipoComunicado.cor.cor === '#ffffff'){
        return '#000000';
      }
      return tipoComunicado.cor.cor === '#ffffff' ? '#000000' : '#ffffff';
    } else {
      return '#ffffff';
    }
  }

  corNovoComunicado(mensagem: any) {
    let usuario = JSON.parse(localStorage.getItem('session')!);
    this.cpfCnpjUsuario = usuario.usuario.cpfCnpj;
    let corComunicado;
    let destinatario: any;

    if(mensagem.destinatario.usuario !== null) {
      destinatario = mensagem.destinatario.usuario.cpfCnpj;
    }

    if(mensagem.leitura.length !== 0 && mensagem.respostas.length == 0) {
      if(mensagem.destinatario.aplicacao == null) {
        mensagem.leitura.forEach((res: { usuario: { cpfCnpj: any; }; }) => {
          if(res.usuario.cpfCnpj == destinatario){
            corComunicado = "#e4e4e479";
          }
        })
      } else {
        mensagem.leitura.forEach((res: { usuario: { cpfCnpj: any; }; }) => {
          if(res.usuario.cpfCnpj !== mensagem.remetente){
            corComunicado = "#e4e4e479";
          }
        })
      }
    } else if(mensagem.respostas.length !== 0) {
      corComunicado = this.verificarRespostas(mensagem.respostas)
    }

    return corComunicado;
  }

  verificarRespostas(resposta: any) {
    let corComunicado;
    let destinatario: any;
    
    resposta.forEach((res: { destinatario: { usuario: { cpfCnpj: any; } | null; }; leitura: any[]; respostas: string | any[]; }, index: any) => {
      if(res.destinatario.usuario !== null) {
        destinatario = res.destinatario.usuario.cpfCnpj;
      }

      if(res.leitura.length !== 0 && res.respostas.length == 0) {
        res.leitura.forEach((leitura: { usuario: { cpfCnpj: any; }; }) => {
        if(leitura.usuario.cpfCnpj == destinatario){
            corComunicado = '#e4e4e479';
          }
        })
      } else if(res.respostas.length == 0 && res.leitura.length == 0) {
        corComunicado = null;

      } else if(res.leitura.length == 0) {
        corComunicado = null;

      } else if(res.respostas.length !==0 ) {
        corComunicado = this.verificarRespostas(res.respostas);
      } 
    })

    return corComunicado
  }

  openMessage(mensagem: { destinatario: { usuario: { cpfCnpj: any; } | null; }; situacaoMensagem: { situacao: number; }; protocolo: any; }) {
    this.loading = true;
    let usuario = JSON.parse(localStorage.getItem('session')!);
    let cpfCnpjUsuario = usuario.usuario.cpfCnpj;
    let destinatario;

    if(mensagem.destinatario.usuario !== null) {
      destinatario = mensagem.destinatario.usuario.cpfCnpj
    }

    if (mensagem.situacaoMensagem.situacao === 0 && (destinatario == cpfCnpjUsuario) ) {
      this.marcarComoLida(mensagem);
    } else {
      this.loading = false;
      this.router.navigateByUrl(`/caixa-postal/mensagem/${mensagem.protocolo}`, { state: { obj: this.filtroMensagensPag } })
    }
  }

  marcarComoLida(mensagem: any) {
    if (
      mensagem.situacaoMensagem.situacao === 0 &&
      mensagem.tipoComunicado.exigeAssinaturaDigital
    ) {
      this.loading = false;
      this.abrirCertificado(mensagem.cienciaMensagem, mensagem.protocolo);
    } else {
      this.caixaPostalService.mensagemLida(mensagem.protocolo).subscribe(
        res => {
          this.loading = false;
          this.router.navigate([
            `/caixa-postal/mensagem/${mensagem.protocolo}`
          ]);
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
    }
  }

  arquivarMensagem(mensagem: { situacaoMensagem: { situacao: number; }; protocolo: any; }) {
    if (mensagem.situacaoMensagem.situacao !== 2) {
      this.caixaPostalService.arquivarMensagem(mensagem.protocolo).subscribe(
        res => {
          this.atualizarMensagens();
        },
        error => console.log(error)
      );
    }
  }

  atualizarMensagens() {
    var parametros: any = {};

    parametros["pagina"] = 1;
    parametros["total_pagina"] = 10;

    this.caixaPostalService.getMensagens(parametros).subscribe(
      res => {
        this.filtroMensagensPag = parametros;
        delete this.filtroMensagensPag["pagina"]
        delete this.filtroMensagensPag["paginaAtual"]
        delete this.filtroMensagensPag["total_pagina"]
        console.log(res)
      },
      error => console.log(error)
    );
  }

  showData(index: string | number) {
    swal.fire({
      title: 'Responsável pela assinatura digital:',
      text: this.mensagens[index].cienciaMensagem[0].usuario.nomeRazaoSocial,
      icon: 'info',
      width: 600,
    });
  }

  abrirPopUpContribuinte(cpfCnpj: string) {
    this.loading = true;
    this.caixaPostalService.consultarContribuinte(cpfCnpj).subscribe(
      res => {
        this.loading = false;
        this.exibirPopUpContribuinte = true;
        this.dataContribuinte = res.resultado;
      },
      err => {
        this.loading = false;

        swal.fire(getSwal("error", "Ops!", err.error.excecao.mensagem, "ok", null));
      }
    );
  }

  fecharPopUpContribuinte(event: any) {
    this.exibirPopUpContribuinte = event;
  }

  fecharPopUpMensagem(event: any) {
    this.enviarMensagem = event;
    this.escreverMensagem = false;
  }

  abrirPopUpMensagem(event: any) {
    this.enviarMensagem = false;
    this.escreverMensagem = true;
    this.tipoComunicado = event[0];
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant("CAIXAPOSTAL.TITLE"),
      iniciarComunicado: this.translate.instant("CAIXAPOSTAL.INITCOMUNICACAO"),
      habilitarComuncado: this.translate.instant("CAIXAPOSTAL.HABILITARCOMUNICAO"),
      mensagemNaoEncontrada: this.translate.instant("CAIXAPOSTAL.MENSAGEMNAOENCONTRADA")
    };
  }

  abrirCertificado(cienciaMensagem: string | any[], codigo: any) {
    if (cienciaMensagem.length == 0) {
      this.router.navigate([`/caixa-postal/certificados/${codigo}/1`]);
    } else {
      swal.fire(
        getSwal(
          "error",
          "Ops!",
          "Não é possível Dar Ciência para esta mensagem: ela já conta com ciência vigente",
          "ok",
          null
        )
      );
    }
  }

  quantidadeComunicados(numero: any){

    this.data['paginaAtual'] = 1;
    switch (numero) {
      case "10":
        this.data['itemsPorPagina'] = 10;
        console.log(this.data['itemsPorPagina']);
        break;
      case "20":
        this.data['itemsPorPagina'] = 20;
        console.log(this.data['itemsPorPagina']);
        break;
      case "50":
        this.data['itemsPorPagina'] = 50;
        console.log(this.data['itemsPorPagina']);
        break;
      case "100":
        this.data['itemsPorPagina'] = 100;
        console.log(this.data['itemsPorPagina']);
        break;
    }
    this.loading = true;
    return this.loadMensagens()
    .then(result => {
      this.loading = false;
    });

  }
}
