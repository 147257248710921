import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class OutorgarProcuracaoService {
  private data = new Subject<any>();
  data$ = this.data.asObservable();

  public outorgarData: any;

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  getData(): Observable<any> {
    const sistemas = this.http
      .get(`${this.url}/aplicacoes/funcionalidadesAutorizadasProcuracao`)
      .pipe(map((res: any) => res));
    const tiposProcuracao = this.http
      .get(`${this.url}/tiposProcuracao/ativos`)
      .pipe(map(res => res));
    return forkJoin([sistemas, tiposProcuracao]);
  }
  getFuncionalidadeAutorizadasProcuracao(codigoTipoProcuracao: any): Observable<any> {
    const params = new HttpParams().set('codigoTipoProcuracao', codigoTipoProcuracao);
    return this.http
      .get(`${this.url}/aplicacoes/funcionalidadesAutorizadasProcuracao`, { params: params });
  }

  buscarProcurador(cpfCnpj: any): Observable<any> {
    const params = new HttpParams().set('cpfCnpj', cpfCnpj);
    return this.http
      .get(`${this.url}/usuarios/procuradores/ativos`, { params: params });
  }

  salvarUsuarioProcuracao(data: any): Observable<any> {
    const usuario = data;

    const formData = new FormData();
    formData.append('cpfCnpj', usuario.cpfCnpj);
    formData.append('nomeRazaoSocial', usuario.nomeRazaoSocial);
    formData.append('email', usuario.email);
    formData.append('ddd', usuario.ddd);
    formData.append('celular', usuario.celular);

    return this.http
      .post(`${this.url}/usuarios/procurador`, formData);
  }

  setData(data: any) {
    this.data.next(data);
  }

  gerarDocumento(data: any): Observable<any> {
    const outorga = data;
    console.log('outorga ', outorga);
    return this.http
      .post(`${this.url}/procuracoes/gerarDocumento`, outorga, {
        headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
      });
  }

  gerarDocumentoRevogacao(
    certificado: string,
    codigoProcuracao: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('certificado', certificado);
    return this.http
      .post(
        `${this.url}/procuracoes/${codigoProcuracao}/gerarDocumentoRevogacao`,
        formData,
        {
          headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
        }
      );
  }

  outorgarProcuracao(data: any): Observable<any> {
    const outorgaProcuracao = data;
    return this.http
      .post(`${this.url}/procuracoes`, outorgaProcuracao, {
        headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
      });
  }
}
