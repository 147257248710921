import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';

import swal from 'sweetalert2';
import moment from 'moment-timezone';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../../../utils/swalObject';

declare var $;

@Component({
  selector: 'app-enviar-mensagem',
  templateUrl: 'enviar-mensagem.component.html',
  styleUrls: ['./enviar-mensagem.component.scss'],
})
export class EnviarMensagemComponent implements OnInit {
  @Output() onClosePopUp: EventEmitter<any> = new EventEmitter<any>();
  @Input() tipoComunicado: Object = {};
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  loading: boolean = false;
  enviarComunicadoForm!: FormGroup;
  today: any = moment().format();
  destinatarios: any;
  anexos: Array<any> = [];
  exibirPesquisaDestinatarios: boolean = false;
  enviarTodos: boolean = false;
  usuarios: Array<any> = [];
  aplicacoes: Array<any> = [];
  aplicacaoSelecionada: number;
  aplicacao: any;
  config: any;
  labels: any;
  isAssuntoValido: any;
  caracteresTextoPadrao: number = 0;
  atingiuMaximo: boolean = false;
  mensagem: string;
  configPadrao: any;

  constructor(
    private fb: FormBuilder,
    private caixaPostalService: CaixaPostalService,
    private translate: TranslateService,
  ) {
    this.setLabels();
    this.anexos = [];
  }

  ngOnInit() {
    this.caixaPostalService.obterAplicacoesPorTipoComunicado(this.tipoComunicado['codigo']).subscribe(
      response => {
        this.aplicacoes = response.resultado;
        //console.log("Aplicações: ", this.aplicacoes)
      },
      error => console.log(error)
    );
    this.criarFormulario();
    this.configToolBar();
    this.configCaixaTexto();
  }

  selecionarAplicacao(event) {

  }

  criarFormulario() {
    this.enviarComunicadoForm = this.fb.group({
      assunto: ['', [Validators.required, Validators.maxLength(80)]],
      mensagem: ['', Validators.required],
      enviarTodos: [false, Validators.required],
      aplicacao: ['', Validators.required]
    });

    this.enviarComunicadoForm.get('assunto').valueChanges.subscribe(value => {
      this.isAssuntoValido = value.trim() !== '';
    });
  }

  isFormInValid() {
    if (this.caracteresTextoPadrao < 20 || this.atingiuMaximo) {
      return true;
    }
  }

  // exibirDestinatarios() {
  //   this.enviarComunicadoForm.controls.enviarTodos.value === false ? this.exibirPesquisaDestinatarios = true : this.exibirPesquisaDestinatarios = false;
  // }

  fecharPopUp() {
    this.onClosePopUp.emit(false);
  }

  getColor(tipoComunicado) {
    if (tipoComunicado.cor !== null) {
      return tipoComunicado.cor.cor;
    } else {
      return '#a1a1a1';
    }
  }

  exibirUsuariosSelecionados(usuario) {
    if (this.usuarios.length !== 0) {
      this.usuarios.forEach(element => {
        if (usuario.cpfCnpj !== element.cpfCnpj) {
          this.usuarios.push(usuario);
        }
      });
    } else {
      this.usuarios.push(usuario);
    }
    this.destinatarios = this.usuarios;
    this.exibirPesquisaDestinatarios = false;
  }

  removeUser(usuario) {
    this.usuarios = this.usuarios.filter(
      element => element.codigo === usuario.codigo,
    );
  }

  fileChangeEvent(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      let file = {
        nomeArquivo: event.target.files[i].name,
        arquivo: null,
      };
      if (event.target.files && event.target.files[i]) {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onload = (evt) => {
          this._handleReaderLoaded(evt, file);
        };
      }
    }
  }

  _handleReaderLoaded(evt, file) {
    const binaryString = evt.target.result.split(',')[1];
    file.arquivo = binaryString;
    this.anexos.push(file);
  }

  removeAnexo(index: number) {
    this.anexos.splice(index, 1);
    this.fileInput.nativeElement.value = '';
  }

  onSubmit() {
    this.loading = true;
    let obj: Object = {};
    obj = this.enviarComunicadoForm.getRawValue();
    this.anexos.length === 0
      ? (obj['anexos'] = null)
      : (obj['anexos'] = this.anexos);
    obj['tipoComunicado'] = this.tipoComunicado;
    obj['dataEnvio'] = this.today;

    obj['destinatarios'] = [{
      aplicacao: obj['aplicacao']
    }]

    console.log("REQUEST");
    console.log(obj);

    this.caixaPostalService.enviarMensagem(obj).subscribe(
      res => {
        this.loading = false;
        swal.fire(
          getSwal(
            'success',
            'Sucesso!',
            'Seu comunicado foi enviado com êxito.',
            'ok',
            null,
          ),
        ).then(res => {
          this.fecharPopUp();
        });
      },
      err => {
        this.loading = false;
        swal.fire(getSwal('error', 'Ops!', err.error.excecao.mensagem, 'ok', null));
      },
    );
  }

  configToolBar() {
    this.config = {
      placeholder: 'Escreva aqui...',
      height: '150px',
      width: '100%',
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
      ],
    }
  }

  configCaixaTexto() {
    const those = this;
    this.config = {
      disabledDragAndDrop: true,
      disableResizeEditor: true,
      placeholder: 'Escreva aqui...',
      tabsize: 2,
      height: '200px',
      width: '100%',
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
      ],
      lang: "pt-BR",
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Courier New', 'Roboto', 'Times'],
      callbacks: {
        onKeydown: function (e) {
          let caracteres = $(".note-editable").text().trim();
          let totalCaracteres = caracteres.length;
          this.caracteresTexto = totalCaracteres;

          if (e.keyCode === 8 && totalCaracteres > 0) {
            those.caracteresTextoPadrao = totalCaracteres - 1;
          }

          if (totalCaracteres >= 4000 && !this.isMensagemValida) {
            const teclasPermitidas = [8, 37, 38, 39, 40, 46, 88, 67, 65];
            if (
              teclasPermitidas.indexOf(e.keyCode) === -1 ||
              (e.keyCode == 88 && !e.ctrlKey) ||
              (e.keyCode == 65 || e.keyCode == 67)
            ) {
              e.preventDefault();
              this.atingiuMaximo = true;
            } else {
              this.atingiuMaximo = false;
            }
          }
        },
        onChange: function (e) {
          let caracteres = $(".note-editable").text().trim();
          let totalCaracteres = caracteres.length;
          those.caracteresTextoPadrao = totalCaracteres;
        },
        onKeyup: function (e) {
          var t = e.currentTarget.innerText;
          let totalCaracteres = t.replace(/\s+/g, '').length;
          $('#maxContentPost').text(Math.max(0, 4000 - totalCaracteres));
        },
        onPaste: function (e) {
          var t = e.currentTarget.innerText;
          let caracteres = $(".note-editable").text();
          let totalCaracteres = caracteres.length;
          var bufferText = ((e.originalEvent || e).clipboardData).getData('Text');

          e.preventDefault();
          var maxPaste = bufferText.length;
          if (totalCaracteres + bufferText.length > 4000) {
            maxPaste = 4000 - t.length;
          }
          if (maxPaste > 0) {
            document.execCommand('insertText', false, bufferText.substring(0, maxPaste).trim());
          }

          let novoTotalCaracteres = $(".note-editable").text().replace(/\s+/g, '').length;
          $('#maxContentPost').text(Math.max(0, 4000 - novoTotalCaracteres));
        },
        onInput: function (e) {
          var t = e.currentTarget.innerText;
          let totalCaracteres = t.length;
          $('#maxContentPost').text(Math.max(0, 4000 - totalCaracteres));
        },
      }
    }
    $('.note-editable').css('font-size', '16px');
    $('.note-editable').css('font-family', 'Calibri');
    $('.note-current-fontsize').text('14');
    $('.note-current-fontname').text('Calibri');
    $('.note-editable').css('font-weight', 'normal');
    $('.popover > .arrow').css('display', 'block');
    $('.note-hint-popover .popover-content').css('width', 'max-content');
    $('.note-hint-popover').css('border', 'none');
    $('.note-hint-popover .popover-content').css('position', 'absolute');
  }

  setLabels() {
    this.labels = {
      enviatodos: this.translate.instant('CAIXAPOSTAL.ENVIARTODOS'),
      incluirComunicado:
        this.translate.instant('GLOBAIS.INCLUIR') +
        ' ' +
        this.translate.instant('GLOBAIS.COMUNICADO'),
      nao: this.translate.instant('GLOBAIS.NAO'),
    };
  }
}
