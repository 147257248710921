import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { SessionService } from './service/session/session.service';
import { UserService } from './service/user-service/user.service';
import { PerfilDataService } from './service/perfil/perfil.service';
import { LoginService } from './service/login/login.service';
import { AmbienteService } from './service/ambiente/ambiente.service';
import { ManualService } from './service/manual/manual.service';
import { CommonModule } from '@angular/common';
import { ConsultaUsuarioService } from './service/consulta-usuario/consultar.user.service';
import { WizardService } from './service/wizard/wizard.service';
import { CadastroService } from './service/cadastro/cadastro.service';
import { CboService } from './service/cbo/cbo.service';
import { CertificadoService } from './service/certificado/certificado.service';
import { CpfCnpjPipe } from './pipes/cpf-cnpj.pipe';
import { CelularPipe } from './pipes/celular.pipe';
import { CgfPipe } from './pipes/cgf.pipe';
import { FilterIrrestritasPipe } from './pipes/filter-app-irrestritas';
import { FilterPipe } from './pipes/filter.pipe';
import { FirstLetterUpperCasePipe } from './pipes/first-letter-upper-case';
import { SearchProcuracaoPipe } from './pipes/searchProcuracao.pipe';
import { SearchProcuracaoSituacaoPipe } from './pipes/searchProcuracaoSituacao.pipe';
import { RecuperarSenhaService } from './service/recuperar-senha/recuperar-senha.service';
import { NgxMaskModule } from 'ngx-mask';
import { AuthGuardService } from './service/auth-guard/auth-guard.service';
import { CervelloService } from './service/cervello/cervello.service';
import { ProcuracoesService } from './service/procuracoes/procuracoes.service';
import { OutorgarProcuracaoService } from './service/procuracoes/outorgar-procuracao.service';
import { AplicacoesService } from './service/aplicacoes/aplicacoes.service';
import { CaixaPostalService } from './service/caixa-postal/caixa-postal.service';
import { TranslateService } from '@ngx-translate/core';
import { WindowRef } from './utils/WindowRef';
import { HttpsRequestInterceptor } from './service/interceptor/interceptor.service';
import { AlertasService } from './service/alertas/alertas.service';
import { PermissoesService } from './service/permissoes/permissoes.service';
import { TiposComunicadoService } from './service/tipos-comunicado/tipos-comunicado.service';
import { ComunicadosService } from './service/comunicados/comunicados.service';
import { ClickOutDirective } from './form-utils/clickout.directive';
import { RegistroAcoesService } from './service/registro-acoes/registro-acoes.service';
import { MessagingService } from './service/push-notification/messaging.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    CpfCnpjPipe,
    FilterPipe,
    CelularPipe,
    CgfPipe,
    SearchProcuracaoPipe,
    SearchProcuracaoSituacaoPipe,
    FilterIrrestritasPipe,
    FirstLetterUpperCasePipe,
    NgxMaskModule,
    ClickOutDirective
  ],
  declarations: [
    CpfCnpjPipe,
    FilterPipe,
    CelularPipe,
    CgfPipe,
    SearchProcuracaoPipe,
    SearchProcuracaoSituacaoPipe,
    FilterIrrestritasPipe,
    FirstLetterUpperCasePipe,
    ClickOutDirective
  ],
  providers: [
    WindowRef,
    PerfilDataService,
    SessionService,
    LoginService,
    AmbienteService,
    ManualService,
    ConsultaUsuarioService,
    WizardService,
    CadastroService,
    CboService,
    CertificadoService,
    RecuperarSenhaService,
    AuthGuardService,
    CervelloService,
    MessagingService,
    ProcuracoesService,
    OutorgarProcuracaoService,
    AplicacoesService,
    CaixaPostalService,
    TiposComunicadoService,
    TranslateService,
    AlertasService,
    PermissoesService,
    ComunicadosService,
    RegistroAcoesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
