import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessagesService } from '../../../service/messages/messages.service';
import { UserService } from '../../../service/user-service/user.service';


@Component({
  selector: 'app-caixa-postal',
	templateUrl: './caixa-postal.component.html',
	styleUrls: ['./caixa-postal.component.scss']
})
export class CaixaPostalComponent implements OnInit  {

	private isSidebarClosed: boolean = localStorage.getItem('is_caixa_postal_sidebar_closed') == 'true';
	private selected: any;
	selectedItem:number = 0
	subscription!: Subscription;
	hasMsg:boolean = false;
	
	showFilter:boolean = false;

	
	constructor(private router: Router, private messagesService: MessagesService, private userService: UserService) {
		
	}

	ngOnInit() {
		this.subscription = this.messagesService.message$.subscribe(message => {
			if(message) {
				this.hasMsg = true;
			}
		})	
	}

	onSidebarToggle(isClosed: boolean) {
 		this.isSidebarClosed = isClosed;
	 }

	 toggleView(index: any) {
		this.selectedItem = index;
	}

 	onItemSelecionado(item: any) {
		this.selected = item;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	onVoltar() {
		this.selected = null;
	}

	onSearch() {
		this.showFilter = false;
	}
}
