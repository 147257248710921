import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from "../../../service/user-service/user.service";
import { Subscription } from "rxjs";
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  host: { 'class': 'sidebarBox' },
  encapsulation: ViewEncapsulation.None
})

export class SidebarComponent {
  subscription: Subscription;
  selectedItem = 0;
  estadoMenu = {
    sidebarFechada: true
  };
  icon = '#menu'
  recarregamento: boolean = false;
  loading: boolean = false;
  
  constructor(
    private userService: UserService,
    private router: Router
  ) {
    this.subscription = this.userService.getInfo().subscribe((info: any) => {
      this.estadoMenu.sidebarFechada = info.sidebarFechada;
      if(!this.estadoMenu.sidebarFechada) {
        this.icon = '#close'
      } else {
        this.icon = '#menu'
      }
    });
    
    if (!localStorage.getItem('recarregamento')) {
      this.loading = true;
      setTimeout(() => {

        this.router.navigate(['/home']);
      }, 500);
      localStorage.setItem('recarregamento', 'true');
    } else {
      this.loading = false;
      this.router.navigate(['/home']);
    }
  }

  fecharSidebar() {
    this.estadoMenu.sidebarFechada = !this.estadoMenu.sidebarFechada;
    this.userService.addData(this.estadoMenu);
  }
}
