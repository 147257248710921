import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeAnimation } from 'src/app/animations';
import { LoginService } from 'src/app/service/login/login.service';
import { UserService } from 'src/app/service/user-service/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-login-senha',
  templateUrl: './login-senha.component.html',
  styleUrls: ['./login-senha.component.scss'],
  animations: [fadeAnimation]
})
export class LoginSenhaComponent implements OnInit {
  loginForm!: FormGroup;

  cpf_cnpj: any;
  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';
  pureResult: any;
  maskedId: any;
  val: any;
  v: any;
  errorMsg: string = '';
  hasError = false;
  currentCnpj!: string;
  exigeCertificadoCnpj: boolean = false;
  loading: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private loginService: LoginService
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.criarFormularioLogin();
    this.loading = false;
  }

  criarFormularioLogin() {
    this.loginForm = this.fb.group({
      cpfCnpj: [
        '',
        Validators.compose([Validators.required, Validators.minLength(11)]),
      ],
      senha: ['', Validators.required],
    });
  }

  onSubmit(submitBtn: HTMLButtonElement) {
    this.loading = true;
    this.currentCnpj = this.loginForm.controls['cpfCnpj'].value;
    submitBtn.disabled = true;
    this.hasError = false;
    this.loading = true;
    const obj = this.loginForm.value;
    this.loginForm.reset();
    const unformatedCpfCnpj = this.unFormat(obj.cpfCnpj);

    obj['cpfCnpj'] = unformatedCpfCnpj;
    this.userService.setLogOnData(obj['cpfCnpj'], obj['senha']);

    this.loginService.login(obj).subscribe(
      (res) => {
        this.handleResponse(res);
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  handleResponse(res: any) {
    this.loading = false;

    if (this.currentCnpj.length >= 15) {
      if (res.resultado.empresas !== null) {
        this.userService.setCompanysAvailable(res.resultado.empresas);
        this.router.navigate(['/login/filiais']);
        this.userService.sendInfo({
          loggedIn: false,
          ...res['resultado'].empresas,
        });
      } else {
        this.userService.sendInfo({ loggedIn: false, ...res.resultado });
        this.loginConfigs(res.resultado);
      }
    } else {
      this.userService.sendInfo({ loggedIn: false, ...res.resultado });
      this.loginConfigs(res.resultado);
    }
  }

  loginConfigs(obj: any) {
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      (res: { resultado: any[] }) => {
        let response: any = res.resultado;
        if (response.length <= 1) {
          response = res.resultado[0];
          this.loginService.obterSessao(response.codigo).then((data: any) => {
            this.loading = false;
            if (data['resultado'].perfilAcesso.codigo !== 3) {
              this.userService.sendInfo({
                loggedIn: true,
                firstVisit: true,
                ...data['resultado'],
              });
              this.router.navigate(['/home']);
            } else {
              this.userService.sendInfo({
                loggedIn: false,
                ...data['resultado'],
              });
              this.router.navigate(['/procuracoes']);
            }
          }).catch((error: any) => {
              this.handleError(error);
          });
        } else {
          this.router.navigate(['/login/perfis']);
        }
      },
      (error: any) => this.handleError(error)
    );
  }

  handleError(err: any) {
    console.log("error ", err);
    const error = err['error'];
    this.loading = false;
    this.hasError = true;
    this.errorMsg = error.excecao.recomendacao;
    
    swal.fire({
      icon:
        ['E000', 'E004'].indexOf(error.excecao.codigo) >= 0
          ? 'warning'
          : 'error',
      title: 'Ops!',
      text: error.excecao.recomendacao,
    }).then((res) => {
      this.loginForm.reset();
      this.userService.sendInfo({
        loggedIn: false,
      });
      //this.sessionService.clearSession();
      this.loading = false;
      this.router.navigate(['index']);
    });
  }

  format(valString: any) {
    if (!valString) {
      return '';
    }
    const val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    if (parts[0].length <= 11) {
      this.maskedId = this.cpf_mask(parts[0]);
      return this.maskedId;
    } else {
      this.maskedId = this.cnpj(parts[0]);
      return this.maskedId;
    }
  }

  unFormat(val: any) {
    if (!val) {
      return '';
    }
    val = val.replace(/\D/g, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  }

  cpf_mask(v: string) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d)/, '$1.$2');
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    return v;
  }

  cnpj(v: string) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/, '$1.$2');
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
    return v;
  }
}
