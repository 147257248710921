import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse  } from "@angular/common/http";
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RecuperarSenhaService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  verificarUsuario(credentials: { cpf: any; ddd: any; celular: any; email: any; }): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        cpfCnpj : credentials.cpf , 
        email: credentials.email,
        ddd : credentials.ddd , 
        celular: credentials.celular
    }});
    return this.http.get(`${this.url}/verificarUsuario`, {params: params}).pipe(map((data: any) => {
      let body = data;
      return body;
    }))
  }

  recuperarSenha(codigoUsuario: string | number | boolean, endpoint: string): Observable<any> {
    let params = new HttpParams().set('codigoUsuario', codigoUsuario);
    return this.http.get(`${this.url}/${endpoint}`, {params: params}).pipe(map(data => { return data }))
  }

  validarCodigoSMS(codigoUsuario: any, codigoVerificacao: any){
    const params = new HttpParams({
      fromObject: {
        codigoUsuario  : codigoUsuario, 
        codigoVerificacao : codigoVerificacao
    }});
    return this.http.get(`${this.url}/validarCodigoSms`, {params: params}).pipe(map((data: any) => { return data }))
  }

  validarCodigoEmail(codigoUsuario: any, codigoVerificacao: any) {
    const params = new HttpParams({
      fromObject: {
        codigoUsuario: codigoUsuario,
        codigoVerificacao: codigoVerificacao
      }
    });
    return this.http.get(`${this.url}/validarCodigoEmail`, {params: params}).pipe(map((data: any) => { return data }))
  }

  obterDadosViaToken(token: string | number | boolean): Observable<any> {
    let params = new HttpParams().set('tokenTemporal', token);
    return this.http.get(`${this.url}/obterDadosTokenTemporal`, {params: params}).pipe(map((data: any) => { return data }))
  }

  redefinirSenha(tokenTemporal: any, senha: any){
    const params = new HttpParams({
      fromObject: {
        tokenTemporal  : tokenTemporal, 
        senha : senha,
    }});
    return this.http.get(`${this.url}/redefinirSenha`, {params: params}).pipe(map((data: any) => { return data }))
  }
}