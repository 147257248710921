import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  AfterViewChecked
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: "app-filtro-validade",
  templateUrl: "filtro-validade.component.html",
  styleUrls: ["./filtro-validade.component.scss"],
})
export class FiltroValidadeComponent implements OnInit {
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Input() resetFilter!: boolean;
  @Input() filtroAtivo: any;
  @Input() visible!: string;

  searchForm!: FormGroup;
  validades: any = [];
  validade = "Validade";
  validadeSelecionada!: string | null;
  validadeDescricao!: string;
  obj: {
    a_vencer: boolean;
    vencidos: boolean;
    respostaAVencer: boolean;
    respostasVencidas: boolean;
    respondidas: boolean;
  };

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.criarFormulario();
    this.montarValidades();
  }

  criarFormulario() {
    this.searchForm = this.fb.group({
      validadeSelecionada: [null],
    });
  }

  montarValidades() {
    this.validades = [
      {
        descricao: 'Prazo da ciência a vencer',
        id: 'a_vencer'
      },
      {
        descricao: 'Prazo vencido de ciência',
        id: 'vencidos'
      },
      {
        descricao: 'Prazo para a resposta vencer',
        id: 'respostaAVencer'
      },
      {
        descricao: 'Prazo vencido para resposta',
        id: 'respostasVencidas'
      },
    ]
  }

  validadeFilter(event: any, descricao: any) {
    this.validadeDescricao = descricao;
    this.validadeSelecionada = event.target.id;
  }

  limparFiltros() {
    this.obj = {
      a_vencer: false,
      vencidos: false,
      respostaAVencer: false,
      respostasVencidas: false,
      respondidas: false
    };

    this.onClear.emit(this.obj);
    this.validade = "Validade";
    this.filtroAtivo = false;
    this.validadeSelecionada = null;
    this.onClear.emit('validade');

    this.onFilter.emit(this.obj);
  }

  pesquisarValidade() {
    this.validade = this.validadeDescricao;
    this.obj = {
      a_vencer: false,
      vencidos: false,
      respostaAVencer: false,
      respostasVencidas: false,
      respondidas: false,
    };

    switch (this.validadeSelecionada) {
      case "a_vencer": {
        this.obj["a_vencer"] = true;
        break;
      }
      case "vencidos": {
        this.obj["vencidos"] = true;
        break;
      }
      case "respostaAVencer": {
        this.obj["respostaAVencer"] = true;
        break;
      }
      case "respostasVencidas": {
        this.obj["respostasVencidas"] = true;
        break;
      }
    }

    this.onFilter.emit(this.obj);
    this.filtroAtivo = true;
  }

  resetarParametros() {
    this.validadeSelecionada = null;
    this.filtroAtivo = false;
    this.validade = "Validade";
    this.limparFiltros();
  }
}
