import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { fadeAnimation } from "src/app/animations";
import { AmbienteService } from "src/app/service/ambiente/ambiente.service";
import { CadastroService } from "src/app/service/cadastro/cadastro.service";
import { CboService } from "src/app/service/cbo/cbo.service";
import { ConsultaUsuarioService } from "src/app/service/consulta-usuario/consultar.user.service";
import { WizardService } from "src/app/service/wizard/wizard.service";
import { getSwal } from "src/app/utils/swalObject";
import swal from 'sweetalert2';

@Component({
  selector: 'app-procurador-pessoa-juridica',
  templateUrl: 'procurador-pessoa-juridica.component.html',
  styleUrls: ['./procurador-pessoa-juridica.component.scss'],
  animations: [fadeAnimation]
})
export class ProcuradorPessoaJuridicaComponent implements OnInit {

  labels: Object = {};
  signUpForm!: FormGroup;
  cadastroIntegrado: boolean = false;
  exibirPopUp: boolean = false;
  data!: any;
  personalidadeJuridica: Array<any> = [];
  setorAtuacao: Array<any> = [];
  loading: boolean = true;
  cadastroProcurador: boolean = false;
  perfilProcurador: boolean = false;
  cliente: any = {};
  estadoCivil: any = null;
  dadoCbo!: any;
  tokenCbo: string = '';
  objCbo = {
    grant_type: "password",
    apikey: "CP7049PivNaOKpQE1gLt7mSJdGdJ0O9adGLhozrbd+cV5HG4Khk+8mnqISweKrQ/ZqLMGQxJTP2Q97A19o/vXg==",
    tipoLogin: 5
  }
  
  constructor(
    private translate: TranslateService, 
    private fb: FormBuilder,
    private consultaUsuario: ConsultaUsuarioService,
    private router: Router,
    private wizardService: WizardService,
    private ambienteService: AmbienteService,
    private cadastroService: CadastroService,
    private cboService: CboService
  ) {
    this.cboService.getToken(this.objCbo).subscribe((res: any)=> {
      this.getCbo(res['access_token']);
  });
  }

  ngOnInit(): void {
    this.criarFormulario();
    this.getCliente();
    this.personalidadesJuridicasativos();
    this.setorAtuacaosativos();
    this.getEstadoCivil();
  }

  criarFormulario() {
    this.signUpForm = this.fb.group({
      cpfCnpjProcurador: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(18),
        ]),
      ],
      nomeRazaoSocialProcurador: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(160)]),
      ],
      numeroRG: ['', Validators.compose([Validators.required, Validators.maxLength(11)])],
      orgaoEmissorRG: ['', Validators.compose([Validators.required, Validators.maxLength(4)])],
      telefoneCelular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(11),
          Validators.minLength(11),
        ]),
      ],
      email: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60), Validators.email]),
      ],
      comboPersonalidadeJuridicaProcurador: [
        "", 
        Validators.compose([Validators.required])],
      comboSetorAtuacaoProcurador: [
        "", 
        Validators.compose([Validators.required])],
      personalidadeJuridicaProcurador: {
        codigo: ''
      },
      setorAtuacaoProcurador: {
        codigo: ''
      },
      codigoCBO: ['', Validators.compose([Validators.required])],
      codigoEstadoCivil: ['', Validators.compose([Validators.required])],
      inscricao: ['']
    });
  }

  getCbo(token: string) {
    this.cboService.getCbo(token).subscribe(
      (res: any) => {
        this.dadoCbo = res['resultado'];
        this.dadoCbo.sort((a: { descricao: string; },b: { descricao: any; }) => a.descricao.localeCompare(b.descricao));
        if(this.dadoCbo.length == 0 || this.dadoCbo == null){
          swal.fire(
            getSwal(
              'error',
              'Ops!',
              'Ambiente não configurado. Aguarde o Administrador da Plataforma finalizar a liberação do ambiente.',
              'ok',
              null
            )
          );
        }
      }, error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar a lista de Profissões.',
            'ok',
            null
          )
        );
      }    
    )
  }
  
  getCliente() {
    this.ambienteService.getCliente().subscribe((res: any) => {
      this.cliente = {
        servicoEmail: res.resultado[0].configuracao.servicoEmail,
        servicoSms: res.resultado[0].configuracao.servicoSms,
        exigeCertificadoCnpj: res.resultado[0].configuracao.exigeCertificadoCnpj
      };

      this.wizardService.userData.prefeituras = res.resultado;
    });
  }

  razaoSocialInvalida() {
    if(this.signUpForm.controls["razaoSocialProcurador"].value.trim().length == 0) {
      this.signUpForm.controls["razaoSocialProcurador"].setValue("");
    }
  }

  personalidadesJuridicasativos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        this.personalidadeJuridica = res.resultado;
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  setorAtuacaosativos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        this.setorAtuacao = res.resultado;
        this.loading = false;
      },
      error => {
        this.loading = false;
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  consultarUsuario(id: string) {
    if (this.signUpForm.controls.cpfCnpjProcurador.valid || this.signUpForm.controls.cpfCnpjProcurador.disabled) {
      this.consultaUsuario.consultarUsuario(this.signUpForm.controls.cpfCnpjProcurador.value).subscribe(
        res => {
          this.loading = false;
          let data = res.resultado;
          this.wizardService.userData['id'] = data.cpfCnpj;
          this.wizardService.userData['codigo'] = data.codigo;

          if (data.situacao === 0) {
            this.signUpForm.patchValue({
              nomeRazaoSocialProcurador: data.nomeRazaoSocial,
              telefoneCelular:
                data.ddd &&
                data.celular
                  ? data.ddd.replace(/\D+/g, '').concat(
                    data.celular,
                  )
                  : '',
              email: data.email,
              comboPersonalidadeJuridicaProcurador:
                  data.detalhe && data.detalhe.personalidadeJuridica
                    ? data.detalhe.personalidadeJuridica.codigo
                    : "",
              comboSetorAtuacaoProcurador:
                  data.detalhe && data.detalhe.setorAtuacao
                    ? data.detalhe.setorAtuacao.codigo
                    : "",      
              inscricao:
                  data.detalhe && data.detalhe.inscricaoMunicipal
                    ? data.detalhe.inscricaoMunicipal
                    : "",  
            });
            let obj = this.signUpForm.value
            for (let key in obj) {
              if (key !== 'cpfCnpjProcurador' && this.signUpForm.controls[key].value !== '') {
                this.signUpForm.controls[key].disable();
              }
            }
          } else {
            swal.fire(
              getSwal(
                'success',
                'Usuário já cadastrado',
                'Você já está cadastrado em nosso sistema, defina sua senha para acessá-lo',
                'ok',
                null,
              ),
            ).then(res => {
              this.router.navigate(['/recuperar-senha']);
            });
          }
        },
        error => {
          if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
            swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            ).then(() => this.signUpForm.controls['cpfCnpjProcurador'].setValue(null));
          }
          this.loading = false;
        },
      );
    }
  }

  getEstadoCivil(){
    this.cadastroService.getEstadoCivil().subscribe(
      res => {
        this.estadoCivil = res.resultado;
        if(this.estadoCivil?.length == 0 || this.estadoCivil == null){
          swal.fire(
            getSwal(
              'error',
              'Ops!',
              'Ambiente não configurado. Aguarde o Administrador da Plataforma finalizar a liberação do ambiente.',
              'Ok',
              null
            )
          )
        }
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar o Estado Civil.',
            'ok',
            null
          )
        );
      }
    );
  }

  proximo() {
    let data = this.signUpForm.getRawValue();
    data["dddTelefoneCelular"] = data["telefoneCelular"].replace(/\D+/g, "").substring(0, 2);
    data["telefoneCelular"] = data["telefoneCelular"].replace(/\D+/g, "").substr(2, 11);
    data["pessoa"] = 'juridica';
   
    if (this.cliente.exigeCertificadoCnpj == 1) {
      data.personalidadeJuridicaProcurador.codigo = data.comboPersonalidadeJuridicaProcurador;
      data.setorAtuacaoProcurador['codigo'] = data.comboSetorAtuacaoProcurador;
    }

    for(let i = 0; i < this.dadoCbo.length; i++){
      if(this.signUpForm.controls.codigoCBO.value == this.dadoCbo[i].idOcupacao){
        data['descricaoCbo'] = this.dadoCbo[i].descricao;
      }
    } 

    for(let i = 0; i < this.estadoCivil.length; i++){
      if(this.signUpForm.controls.codigoEstadoCivil.value == this.estadoCivil[i].codigo){
        data['dscEstadoCivil'] = this.estadoCivil[i].dscEstadoCivil;
      }
    }

    if(localStorage.getItem('certificado')){
      let certificado: any = localStorage.getItem('certificado');
      let obj = JSON.parse(certificado);
      data["nonce"] = obj.resultado.nonce;
    }

    this.wizardService.userData.infoBasica = data;
    this.wizardService.userData.infoBasica["pessoaFisica"] = false;
    this.wizardService.userData.perfil = 'procurador';
    localStorage.setItem("data", JSON.stringify(this.wizardService.userData));

    this.router.navigate(['cadastro/representante']);
  }

  ajuda(valor: string) {
    let msg1=
    `<p style="text-align='justify">A Carteira de Identidade é um documento oficial de identificação que contém nome, 
      data de nascimento, filiação, impressão digital e fotografia. </p>
      <p style="text-align='justify">Tem validade em todo o território nacional 
      e é expedida para Brasileiros Natos, Naturalizados e os Portugueses que possuam Igualdade de Direitos. 
      A Carteira de Identidade não tem prazo de validade.</p>
      `;

  let msg2=
    `<p> Estado civil é o termo jurídico que faz referência à situação de um cidadão em relação ao matrimônio. A legislação brasileira identifica cinco tipos diferentes de estado civil, são eles: solteiro, casado, separado, divorciado e viúvo.
    </p>`;

  let msg3=
    `
    <p> A Classificação Brasileira de Ocupações (CBO) é um documento que retrata a realidade das profissões do mercado de trabalho brasileiro. Foi instituída com base legal na Portaria nº 397, de 10.10.2002.
    </p>`;
 

    switch(valor) {
      case 'numeroRG': {
        swal.fire({
          html:msg1,
          confirmButtonText:'OK'
        })
         break;
      }
    }

    switch(valor) {
      case 'codigoEstadoCivil': {
        swal.fire({
          html:msg2,
          confirmButtonText:'OK'
        })
         break;
      }
    }

    switch(valor) {
      case 'codigoCBO': {
        swal.fire({
          html:msg3,
          confirmButtonText:'OK'
        })
        break;
      }
    }
  }

  desistir() {
    this.wizardService.resetData();
    this.router.navigate(["/index"]);
  }
}