import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { AlertasService } from "../../../service/alertas/alertas.service";
import { SessionService } from "../../../service/session/session.service";
import * as moment from "moment";


@Component({
  selector: "app-alertas",
  templateUrl: "./alertas.component.html",
  styleUrls: ["./alertas.component.scss"]
})
export class AlertasComponent implements OnInit {
  url: string = environment.BASE_URL.replace(/^http(s?)/i, "");
  token: string = '';
  alertas: Array<any> = [];
  alertFocus: any;

  @Output() fecha = new EventEmitter();

  constructor(
    private alertasService: AlertasService,
    private sessionService: SessionService,
    private router: Router
  ) {
    this.token = sessionService.getToken();
  }

  ngOnInit() {
    this.alertasService.getNaoLidos(this.token).subscribe((res: any) => {
      this.alertas = res.resultado;
      this.fixDescriptions();
    });
  }

  fixDescriptions() {
    this.alertas.map(alerta => {
      if (alerta.tipoAlerta.descricao.length > 37) {
        alerta.tipoAlerta["desc"] =
          moment(alerta.dataCriacao).format("DD/MM/YYYY HH:mm") +
          " - " +
          alerta.tipoAlerta.descricao.substring(0, 37) +
          "...";
      } else {
        alerta.tipoAlerta["desc"] =
          moment(alerta.dataCriacao).format("DD/MM/YYYY HH:mm") +
          " - " +
          alerta.tipoAlerta.descricao;
      }
    });
  }

  marcarTodosComoLido() {
    this.alertasService.marcarTodosComoLido(this.token);
    this.alertasService.updateAlerts({});
    this.alertas = [];
  }

  redirectToMessage(alerta: any) {
    if (alerta.dataLeitura === null) {
      this.alertasService.marcarComoLido(alerta.codigo, this.token);
    }

    this.alertasService.setProtocolo(alerta.complemento);

    if (alerta.tipoAlerta.codigo == 3) {
      this.router.navigate(["/perfil/editar/alerta"]);
      this.fecha.emit();
    } else if (
      alerta.tipoAlerta.codigo == 6 ||
      alerta.tipoAlerta.codigo == 7 ||
      alerta.tipoAlerta.codigo == 8
    ) {
      this.router.navigate([`/procuracao/outorgar/` + alerta.complemento]);
      this.fecha.emit();
    } else {
      this.router.navigate([`/caixa-postal/mensagem/` + alerta.complemento]);
      this.fecha.emit();
    }
  }
}
