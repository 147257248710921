import swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultaUsuarioService } from 'src/app/service/consulta-usuario/consultar.user.service';
import { Router } from '@angular/router';
import { WizardService } from 'src/app/service/wizard/wizard.service';
import { getSwal } from 'src/app/utils/swalObject';
import { fadeAnimation } from 'src/app/animations';
import { AmbienteService } from 'src/app/service/ambiente/ambiente.service';

@Component({
  selector: 'app-contribuinte-pessoa-fisica',
  templateUrl: 'contribuinte-pessoa-fisica.component.html',
  styleUrls: ['./contribuinte-pessoa-fisica.component.scss'],
  animations: [fadeAnimation]
})
export class ContribuintePessoaFisicaComponent implements OnInit {

  labels: Object = {};
  signUpForm!: FormGroup;
  cadastroIntegrado: boolean = false;
  exibirPopUp: boolean = false;
  data!: any;
  personalidadeJuridica: Array<any> = [];
  setorAtuacao: Array<any> = [];
  loading: boolean = true;
  cadastroContribuinte: boolean = false;
  perfilContribuinte: boolean = false;
  cliente: any = {};

  constructor(
    private translate: TranslateService, 
    private fb: FormBuilder,
    private consultaUsuario: ConsultaUsuarioService,
    private router: Router,
    private wizardService: WizardService,
    private ambienteService: AmbienteService
  ) {
    this.setLabels();
  }

  ngOnInit() {
    this.loading = true;
    this.criarFormulario();
    this.personalidadesJuridicasativos();
    this.setorAtuacaosativos();
    this.getCliente();
  }
  

  criarFormulario() {
    this.signUpForm = this.fb.group({
      cnpjContribuinte: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(14),
        ]),
      ],
      razaoSocialContribuinte: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(160)]),
      ],
      inscricao: [''],
      telefoneCelular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(11),
          Validators.minLength(10),
        ]),
      ],
      email: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60), Validators.email]),
      ],
      ativo: [
        false, 
        Validators.required],
      comboPersonalidadeJuridicaContribuinte: [
        "0", 
        Validators.compose([Validators.required])],
      comboSetorAtuacaoContribuinte: [
        "0", 
        Validators.compose([Validators.required])],
      personalidadeJuridicaContribuinte: {
        codigo: ''
      },
      setorAtuacaoContribuinte: {
        codigo: ''
      }
    });
  }

  getCliente() {
    this.ambienteService.getCliente().subscribe((res: any) => {
      this.cliente = {
        servicoEmail: res.resultado[0].configuracao.servicoEmail,
        servicoSms: res.resultado[0].configuracao.servicoSms,
        exigeCertificadoCnpj: res.resultado[0].configuracao.exigeCertificadoCnpj
      };

      this.wizardService.userData.prefeituras = res.resultado;
    });
  }

  razaoSocialInvalida() {
    if(this.signUpForm.controls["razaoSocialContribuinte"].value.trim().length == 0) {
      this.signUpForm.controls["razaoSocialContribuinte"].setValue("");
    }
  }

  consultarUsuario(id: string) {
    if (this.cadastroIntegrado === true) {
      this.consultaUsuario.getApiExterna(id).subscribe(async res => {
        if (res.resultado.length > 1) {
          this.exibirPopUp = true;
          this.data = res;
          var codigo = 1
          this.data['resultado'].map((item: any) => {
            item['codigo'] = codigo;
            item['checked'] = false;
            codigo++;
          });
        } else {
          this.contribuiteSelecionado(res['resultado'])
        }
      }, (error => {
        swal.fire({
          icon: 'error',
          title: 'Ops',
          text: error.error.excecao.recomendacao
        });
      }));
    } else {
      if (this.signUpForm.controls.cnpjContribuinte.valid) {
        this.loading = true;

        this.consultaUsuario.consultarUsuario(id).subscribe((res: any) => {
          this.loading = false;
          let data = res.resultado;
          this.wizardService.userData["id"] = data.cpfCnpj;
          this.wizardService.userData["codigo"] = data.codigo;
          const docs = this.transformDoc(data.cpfCnpj);

          let perfis = res.resultado.perfis;

          perfis.forEach((element: any) => {
            if (element.codigo === 2) {
              this.perfilContribuinte = true;
              return;
            } else {
              this.perfilContribuinte = false;
            }
          });

          if (this.perfilContribuinte) {
            // contribuinte === 2
            swal.fire(
              getSwal(
                "success",
                "Usuário já cadastrado",
                "Você já está cadastrado em nosso sistema, defina sua senha para acessá-lo",
                "ok",
                null
              )
            ).then((res) => {
              this.router.navigate(["index"]);
            });
          } else if (data.situacao == 2) {
            // contribuinte === 2
            swal.fire(
                getSwal(
                  "error",
                  "Usuário Inativo",
                  "Você já está cadastrado em nosso sistema, mas está com acesso inativo, favor solicitar apoio ao administrador",
                  "ok",
                  null
                )
              )
              .then((res) => {
                this.router.navigate(["index"]);
              });
          } else {
            // procurador === 1
            swal.fire(
                getSwal(
                  "success",
                  `CPF ${docs} já cadastrado`,
                  "Você já está cadastrado como Procurador. Deseja também ter um cadastro de Contribuinte ?",
                  "Sim",
                  "Não"
                )
              )
              .then((res) => {
                if (res.value) {
                  this.cadastroContribuinte = true;
                  setTimeout(() => {
                    if (data.nomeRazaoSocial) {
                      this.signUpForm.controls.razaoSocialContribuinte.setValue(
                        data.nomeRazaoSocial
                      );
                      this.signUpForm.controls[
                        "razaoSocialContribuinte"
                      ].disable();
                    }
                    if (data.email) {
                      this.signUpForm.controls.email.setValue(data.email);
                      this.signUpForm.controls["email"].disable();
                    }
                    if (
                      data.ddd &&
                      data.celular
                    ) {
                      this.signUpForm.controls.telefoneCelular.setValue(
                        data.ddd +
                        data.celular
                      );
                      this.signUpForm.controls["telefoneCelular"].disable();
                    }
                    if(
                      data.detalhe &&
                      data.detalhe.setorAtuacao
                    ) {
                      this.signUpForm.controls.comboSetorAtuacaoContribuinte.setValue(
                        data.detalhe.setorAtuacao.codigo
                      );
                      this.signUpForm.controls["comboSetorAtuacaoContribuinte"].disable();
                    }
                    if(
                      data.detalhe &&
                      data.detalhe.personalidadeJuridica
                    ) {
                      this.signUpForm.controls.comboPersonalidadeJuridicaContribuinte.setValue(
                        data.detalhe.personalidadeJuridica.codigo
                      );
                      this.signUpForm.controls["comboPersonalidadeJuridicaContribuinte"].disable();
                    }
                    if(
                      data.detalhe &&
                      data.detalhe.inscricaoMunicipal
                    ) {
                      this.signUpForm.controls.inscricao.setValue(
                        data.detalhe.inscricao
                      );
                      this.signUpForm.controls["comboSetorAtuacaoContribuinte"].disable();
                    }
                  }, 150);
                } else {
                  setTimeout(() => {
                    this.router.navigate(["/index"]);
                  }, 150);
                }
              });
            }
        }, (error) => {
          if (error.error.excecao.codigo && error.error.excecao.codigo == 'E000') {
            swal.fire(
              getSwal(
                'error',
                '',
                error.error.excecao.recomendacao,
                'ok',
                null
              )
            ).then(() => this.signUpForm.controls.cnpjContribuinte.setValue(null));
          }
          this.loading = false;
        });
      }
    }
  }

  contribuiteSelecionado(event: any) {
    this.exibirPopUp = false;
    event = event[0];
    this.signUpForm.patchValue({
      razaoSocialContribuinte: event.razaoSocialContribuinte,
      email: event.email,
      telefoneCelular: event.dddTelefoneCelular + event.telefoneCelular,
      cpfRepresentanteLegal: event.cpfRepresentanteLegal,
      celularRepresentanteLegal: event.dddCelularRepresentanteLegal + event.celularRepresentanteLegal,
      emailRepresentanteLegal: event.emailRepresentanteLegal,
      nomeRepresentanteLegal: event.nomeRepresentanteLegal
    });
    
  }

  personalidadesJuridicasativos() {
    this.consultaUsuario.getPersonalidadesJuridicasAtivos().subscribe(
      res => {
        this.personalidadeJuridica = res.resultado;
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  setorAtuacaosativos() {
    this.consultaUsuario.getSetoresAtuacaoAtivos().subscribe(
      res => {
        this.setorAtuacao = res.resultado;
        this.loading = false;
      },
      error => {
        this.loading = false;
        swal.fire(
          getSwal(
            'error',
            'Ops!',
            'Ocorreu um erro ao carregar personalidades juridicas.',
            'ok',
            null
          )
        );
      }
    );
  }

  proximo() {
    let data = this.signUpForm.getRawValue();
    data["dddTelefoneCelular"] = data["telefoneCelular"]
      .replace(/\D+/g, "")
      .substring(0, 2);
    data["telefoneCelular"] = data["telefoneCelular"]
      .replace(/\D+/g, "")
      .substr(2, 11)
    data["pessoa"] = 'fisica';
    this.wizardService.userData.infoBasica = data;
    this.wizardService.userData.perfil = 'contribuinte';
    if (this.cliente.exigeCertificadoCnpj == 1) {
      data.personalidadeJuridicaContribuinte.codigo = data.comboPersonalidadeJuridicaContribuinte;
      data.setorAtuacaoContribuinte['codigo'] = data.comboSetorAtuacaoContribuinte;
    }
    this.wizardService.userData.infoBasica = data;
    this.wizardService.userData.infoBasica["pessoaFisica"] = true;
    localStorage.setItem("data", JSON.stringify(this.wizardService.userData));
    if (this.cadastroContribuinte === true) {
      this.router.navigate(["cadastro/contribuinte-fisico/resumo-atribuir-contribuinte"]);
    } else {
      this.router.navigate(["cadastro/resumo"]);
    }
  }

  desistir() {
    this.wizardService.resetData();
    this.router.navigate(["/index"]);
  }

  transformDoc(value: any): any {
    if (value && value.length === 11) {
      return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(
        6,
        3
      )}-${value.substr(9, 2)}`;
    } else if (value && value.length === 14) {
      return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(
        5,
        3
      )}/${value.substr(8, 4)}-${value.substr(12, 2)}`;
    }

    return value;
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('GLOBAIS.SEUSDADOS'),
      cnpj: this.translate.instant('GLOBAIS.CNPJ'),
      cpf: this.translate.instant('GLOBAIS.CPF'),
      inscricao: this.translate.instant('GLOBAIS.INSCRICAO'),
      nome: this.translate.instant('GLOBAIS.NOME'),
      razaoSocial: this.translate.instant('GLOBAIS.RAZAOSOCIAL'),
      telefone: this.translate.instant('GLOBAIS.TELEFONE'),
      comercial: this.translate.instant('GLOBAIS.COMERCIAL'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      exigirAssinatura: this.translate.instant('GLOBAIS.EXIGIRASSINATURA'),
      sim: this.translate.instant('GLOBAIS.SIM'),
      nao: this.translate.instant('GLOBAIS.NAO'),
      voltar: this.translate.instant('GLOBAIS.VOLTAR'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      prosseguir: this.translate.instant('GLOBAIS.PROSSEGUIR'),
    };
  }
}

