import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AplicacoesService {
  url: string = environment.BASE_URL;
  //obter lista de aplicações
  private aplicacoes = new Subject<any>();
  aplicacoes$ = this.aplicacoes.asObservable();
  //obter a aplicação selecionada, seja em inclusao ou edicao
  private aplicacao = new BehaviorSubject<any>('');
  aplicacao$ = this.aplicacao.asObservable();
  //obter uma funcionalidade, seja em inclusao ou edicao
  private funcionalidade = new BehaviorSubject<any>('');
  funcionalidade$ = this.funcionalidade.asObservable();
  private editFuncionalidade = new BehaviorSubject<any>('');
  editFuncionalidade$ = this.editFuncionalidade.asObservable();
  private funcionalidades = [];

  constructor(private http: HttpClient) {}

  obterDadosGraficos(): Observable<any> {
    const registradas = this.http
      .get(`${this.url}/aplicacoes/quantidadeAplicacoesParaComunicado`)
      .pipe(map(res => res));
    const contribuinte = this.http
      .get(`${this.url}/aplicacoes/quantidadeAplicacoesAcessiveisViaPortal`)
      .pipe(map(res => res));
    const auditor = this.http
      .get(`${this.url}/aplicacoes/quantidadeAplicacoesAcessiveisViaAuditor`)
      .pipe(map(res => res));
    return forkJoin([registradas, contribuinte, auditor]);
  }

  obterDadosFormaAcesso(): Observable<any> {
    const formasAcesso = this.http
      .get(`${this.url}/formasAcesso/ativos`)
      .pipe(map(res => res));
    const perfisAcesso = this.http
      .get(`${this.url}/perfisAcesso/ativos`)
      .pipe(map(res => res));
    const perfisUsuario = this.http
      .get(`${this.url}/tiposProcuracao/ativos`)
      .pipe(map(res => res));
    return forkJoin([formasAcesso, perfisAcesso, perfisUsuario]);
  }

  setAplicacao(aplicacao: any) {
    this.aplicacao.next(aplicacao);
  }

  registrarAcesso(aplicacao: number) {
    return this.http
      .get(`${this.url}/aplicacoes/${aplicacao}/registrarAcesso`)
      .pipe(map(res => res));
  }

  // setFuncionalidade(funcionalidade: Object): void {
  //   if (!this.funcionalidades.find((o: any) => o.codigo === funcionalidade.codigo)) {
  //     this.funcionalidades.push(funcionalidade);
  //   } else {
  //     const index = this.funcionalidades.findIndex(
  //       obj => obj.codigo === funcionalidade.codigo
  //     );
  //     this.funcionalidades[index] = funcionalidade;
  //   }
  //   // this.funcionalidades.push(funcionalidade);
  //   this.funcionalidade.next(this.funcionalidades);
  // }

  setFuncionalidadesOnEdit(funcionalidades: any) {
    this.funcionalidades = funcionalidades;
    this.funcionalidade.next(this.funcionalidades);
  }

  resetFuncionalidade() {
    this.funcionalidades = [];
    this.funcionalidade.next(this.funcionalidades);
  }

  editarFuncionalidade(funcionalidade: any) {
    this.editFuncionalidade.next(funcionalidade);
  }

  getData(): Observable<any> {
    const perfis = this.http.get(`${this.url}/perfisAcesso`).pipe(map(res => res));
    const formasAcesso = this.http
      .get(`${this.url}/formasAcesso/ativos`)
      .pipe(map((res: any) => res));
    return forkJoin([perfis, formasAcesso]);
  }

  getAplicacoes(searchParams: any): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/aplicacoes`, { params: params })
      .pipe(map(data => {
        const body = data;
        this.aplicacoes.next(body);
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  criaAplicacao(params: any): Observable<any> {
    const aplicacao = params;
    return this.http.post(`${this.url}/aplicacoes`, aplicacao).pipe(map(data => {
      const body = data;
      return body;
    }));
  }

  editaAplicacao(code: number, params: any): Observable<any> {
    const aplicacao = params;
    const codigo = code;
    return this.http
      .put(`${this.url}/aplicacoes/${codigo}`, aplicacao)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  obterAplicacao(id: number): Observable<any> {
    return this.http.get(`${this.url}/aplicacoes/${id}`).pipe(map((data: any) => {
      const body = data;
      this.setFuncionalidadesOnEdit(data['resultado'].funcionalidades);
      return body;
    }));
  }

  obterAplicacaoComAcessoIrrestrito() {
    return this.http
    .get(`${this.url}/aplicacoesIrrestritasContribuinte`)
    .pipe(map((data: any) => {
      const body = data;
      return body;
    }));
  }

  obterAplicacaoComAcessoRestrito() {
    return this.http
      .get(`${this.url}/aplicacoesRestritasContribuinte`)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }
}
