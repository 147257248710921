import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

import { CaixaPostalComponent } from './caixa-postal/caixa-postal.component';
import { MessagesComponent } from './caixa-postal/pages/messages-list/messages-list.component';
import { MessageDetailsComponent } from './caixa-postal/pages/message/message.component';
import { CertificadosComponent } from './caixa-postal/pages/certificados/certificados.component';
import { NomearAdvogadoComponent } from './caixa-postal/pages/nomear-advogado/nomear-advogado.component';
// import { SelecaoTiposComunicadoComponent } from './caixa-postal/pages/tipos-comunicado/tipos-comunicado.component';
// import { EnviarMensagemComponent } from './caixa-postal/pages/enviar-mensagem/enviar-mensagem.component';



const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: CaixaPostalComponent,
    data: { breadcrumb: 'Caixa Postal', modulo: '2001' },
    children: [
      {
        path: '',
        redirectTo: 'mensagens',
        pathMatch: 'full'
      },
      {
        path: 'mensagens',
        component: MessagesComponent
      },
      {
        path: 'mensagem/:id',
        component: MessageDetailsComponent,
        data: { breadcrumb: 'Mensagem' }
      },
      {
        path: 'certificados/:id',
				component: CertificadosComponent,
				data: { breadcrumb: 'Certificados' }
      },
      {
        path: 'certificados/:id/:lista',
				component: CertificadosComponent,
				data: { breadcrumb: 'Certificados' }
      },
      {
        path: 'nomear-advogado/:id',
				component: NomearAdvogadoComponent,
				data: {
					breadcrumb: 'Nomear Advogados'
				}
      },
      // {
      //   path: 'tipos-comunicado',
      //   component: SelecaoTiposComunicadoComponent,
			// 	data: { breadcrumb: 'Tipos de Comunicado' }
      // },
      // {
      //   path: 'enviar',
      //   component: EnviarMensagemComponent,
			// 	data: { breadcrumb: 'Incluir Comunicado' }
      // },
    ]
  }

];
@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CaixaPostalEletronicaRoutingModule { }

