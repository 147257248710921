import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CaixaPostalService } from '../../../../../service/caixa-postal/caixa-postal.service';
import { ProcuracoesService } from '../../../../../service/procuracoes/procuracoes.service';

import swal from 'sweetalert2';
import { getSwal } from '../../../../../utils/swalObject';
import { CpfCnpjPipe } from 'src/app/pipes/cpf-cnpj.pipe';

@Component({
  selector: 'app-nomear-advogados',
  templateUrl: 'nomear-advogado.component.html',
  styleUrls: ['./nomear-advogado.component.scss'],
})
export class NomearAdvogadoComponent implements OnInit {
  data: Array<any> = [];
  selectedEntities!: any[];
  protocolo!: number;
  loading: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private caixaPostalService: CaixaPostalService,
    private procuracaoService: ProcuracoesService,
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.protocolo = parseInt(params.get('id')!);
      this.obterProcuracoes();
    });
  }

  obterProcuracoes() {
    this.procuracaoService.getOutorgadasAdvogados().subscribe(response => {
      this.data = response.resultado;
    });
  }

  public setSelectedEntities($event: any) {
    this.selectedEntities = $event;
  }

  nomearAdvogado() {
    this.caixaPostalService
      .nomearAdvogado(this.protocolo, this.selectedEntities)
      .subscribe(
        response => {
          this.router.navigate([`/caixa-postal/mensagem/${this.protocolo}`]);
          swal.fire(
            getSwal(
              'success',
              'Sucesso!',
              'Nomeação aplicada com êxito.',
              'ok',
              null,
            ),
          );
        },
        error => {
          this.router.navigate([`/caixa-postal/mensagem/${this.protocolo}`]);

          swal.fire(
            getSwal(
              'error',
              'Ops!',
              'Ocorreu um erro e a nomeação não pôde ser aplicada.',
              'ok',
              null,
            ),
          );
        },
      );
  }

  desistir() {
    this.router.navigate([`/caixa-postal/mensagem/${this.protocolo}`]);
  }
}
