import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContribuintePessoaFisicaComponent } from './pages/contribuinte-pessoa-fisica/contribuinte-pessoa-fisica.component';
import { AutoCadastroComponent } from './auto-cadastro.component';
import { ResumoComponent } from './pages/resumo/resumo.component';
import { ProcuradorPessoaFisicaComponent } from './pages/procurador-pessoa-fisica/procurador-pessoa-fisica.component';
import { ContribuintePessoaJuridicaComponent } from './pages/contribuinte-pessoa-juridica/contribuinte-pessoa-juridica.component';
import { RepresentanteLegalComponent } from './pages/representante-legal/representante-legal.component';
import { CodigoComponent } from './pages/codigo/codigo.component';
import { ValidacaoComponent } from './pages/validacao/validacao.component';
import { ProcuradorPessoaJuridicaComponent } from './pages/procurador-pessoa-juridica/procurador-pessoa-juridica.component';
import { CertificadoComponent } from './pages/certificado/certificado.component';

const routes: Routes = [
	{ path: '', component: AutoCadastroComponent, children: [
		{ path: 'contribuinte-fisico', component: ContribuintePessoaFisicaComponent },
		{ path: 'procurador-fisico', component: ProcuradorPessoaFisicaComponent },
		{ path: 'contribuinte-juridico', component: ContribuintePessoaJuridicaComponent },
		{ path: 'representante', component: RepresentanteLegalComponent },
		{ path: 'procurador-juridico', component: ProcuradorPessoaJuridicaComponent },
		{ path: 'resumo', component: ResumoComponent},
		{ path: ':perfil/codigo', component: CodigoComponent},
		{ path: ':perfil/senha', component: ValidacaoComponent},
		{ path: ':perfil/codigo-fisica', component: CodigoComponent},
		{ path: 'certificado/:codigoUsuario/:codigoCliente', component: CertificadoComponent}
	]}
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class CadastroRoutingModule { 

}
