import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SessionService } from '../session/session.service';

@Injectable({providedIn: 'root'})
export class UserService {
  private userInfo: Subject<any> = new Subject<any>();
  userInfo$ = this.userInfo.asObservable();
  private url = new Subject<string>();
  private companysAvailable: Array<Object> = [];
  private logOnData: any = {
    cnpj: '',
    word: ''
  };

  constructor(public sessionService: SessionService) {
    const data = this.sessionService.getSession();
    if (data) {
      this.userInfo.next({ ...data });
    }
  }

  sendInfo(valorRecebido: any) {
    this.userInfo.next({ ...valorRecebido });
    console.log("chamouu", valorRecebido)
    this.sessionService.setSession({ ...valorRecebido })
  }

  addData(valor: any) {
    const data = this.sessionService.getSession();
    const updatedObject = Object.assign(data, valor);
    this.sendInfo(updatedObject);
  }

  updateDataWithPhoto(image: any) {
    const data: any = this.sessionService.getSession();
    data['usuario'].imagem = image;
    this.sendInfo(data);
  }

  getUserData(): any {
    return this.sessionService.getSession();
  }

  getInfo(): Observable<any> {
    console.log("foiii")
    return this.userInfo.asObservable();
  }

  refreshUrl(url: any) {
    this.url.next(url);
  }

  getCurrentUrl() {
    return this.url.asObservable();
  }

  setCompanysAvailable(companys: Array<Object>): void {
    this.companysAvailable = companys;
  }

  getCompanysAvailable(): Array<Object> {
    return this.companysAvailable;
  }

  setLogOnData(cpnj: string, pass: string): void {
    this.logOnData['cnpj'] = cpnj;
    this.logOnData['word'] = pass;
  }
  getLogOnData(): Object {
    return this.logOnData;
  }
}
