import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Subject } from 'rxjs';


import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ComunicadosService {
  url: string = environment.BASE_URL;
  private comunicados = new Subject<any>();
  comunicados$ = this.comunicados.asObservable();

  constructor(private http: HttpClient) {}

  getComunicados(searchParams: any): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/tiposComunicado`, { params: params })
      .pipe(map(data => {
        const body = data;
        this.comunicados.next(body);
        return body;
      }));
  }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  getData(): Observable<any> {
    const tiposPrazo = this.http
      .get(`${this.url}/tiposPrazo/ativos`)
      .pipe(map(res => res));
    const aplicacoes = this.http
      .get(`${this.url}/aplicacoes/ativos`)
      .pipe(map(res => res));
    return forkJoin([tiposPrazo, aplicacoes]);
  }

  obterCores(): Observable<any> {
    return this.http.get(`${this.url}/coresTipoComunicado`);
  }

  obterComunicadosPlataformaDTe(searchParams: any): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http
      .get(`${this.url}/tiposComunicado/autorizadosPortalContribuinte`, {
        params: params
      })
      .pipe(map(res => res));
  }

  obterDadosGraficos(): Observable<any> {
    const registradas = this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorAno`)
      .pipe(map(res => res));
    const contribuinte = this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorMes`)
      .pipe(map(res => res));
    const auditor = this.http
      .get(`${this.url}/mensagens/quantidadeComunicadosPorDia`)
      .pipe(map(res => res));
    return forkJoin([registradas, contribuinte, auditor]);
  }

  criaComunicado(comunicado: any): Observable<any> {
    const tipoComunciado = comunicado;
    return this.http
      .post(`${this.url}/tiposComunicado`, tipoComunciado);
  }

  editaComunicado(comunicado: any, id: any): Observable<any> {
    const tipoComunicado = comunicado;
    return this.http
      .put(`${this.url}/tiposComunicado/${id}`, tipoComunicado);
  }

  obterComunicado(id: number): Observable<any> {
    return this.http.get(`${this.url}/tiposComunicado/${id}`);
  }



}
