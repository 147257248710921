import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class TiposComunicadoService {
  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) {}

  obterTiposComunicado(): Observable<any> {
    return this.http.get(`${this.url}/tiposComunicado`).pipe(map(data => data));
  }  
}
