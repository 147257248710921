import { Component, OnInit } from "@angular/core";
import { fadeAnimation } from "src/app/animations";

@Component({
  selector: 'app-acessibilidade',
  templateUrl: 'acessibilidade.component.html',
  styleUrls: ['./acessibilidade.component.scss'],
  animations: [fadeAnimation]
})

export class AcessibilidadeComponent implements OnInit {

  loading: boolean = true;

  constructor() {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.loading = false;
  }
}