import { Component, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../service/user-service/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PermissoesService } from '../../../../service/permissoes/permissoes.service';
import { SessionService } from './../../../../service/session/session.service';

@Component({
  selector: '[sidebar-menu]',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  encapsulation: ViewEncapsulation.None

})

export class SidebarMenuComponent implements OnInit {
  @Output() onSignOut = new EventEmitter<boolean>();
  subscription: Subscription;
  navSubscription: Subscription;
  sidebarFechada = false;

  links: Array<any> = [
    {
      title: 'Caixa Postal', route: '/caixa-postal', icon: '#caixa-postal-menu', modulo: '2001'
    },
    {
      title: 'Procuração', route: '/procuracao/grid', icon: '#tools', modulo: '2003'
    },
    {
      title: 'Histórico de Acessos', route: '/registro', icon: '#folder', modulo: '2002'
    },
    
  ];
  
  permissoes: Array<any> | null = null;
  selectedItem = 0;

  constructor(private router: Router, private userService: UserService, private route: ActivatedRoute, private permissoesService: PermissoesService, private session: SessionService) {
    this.subscription = this.userService.getInfo().subscribe((info: any) => {
      this.sidebarFechada = info.sidebarFechada;
    });

    this.navSubscription = this.userService.getCurrentUrl().subscribe(current => {
      const obj = this.links.reduce(function (prev, curr) { return (curr.route === current) ? curr : prev; }, null);
      this.selectedItem = this.links.indexOf(obj);
    });
  }

  onItemSelected(link: any): void {
    this.selectedItem = this.links.indexOf(link);
    this.router.navigate([link.route]);
  }

  ngOnInit() {
    this.links.unshift({title: 'Página Inicial', route: '/home', icon: '#homepage', modulo: '0', visible: true})
    this.permissoesService.obterPermissoes().subscribe(res => {
      this.permissoes = res.resultado;
      this.links.map(link => {
        let index = this.permissoes?.findIndex(permissao => permissao.modulo === link.modulo)
        if(link.title === 'Página Inicial' || index !== -1 ) {
          link['visible'] = true;
        } else {
          link['visible'] = false;
        }
      })
    });
   }

   signOut() {
    this.userService.sendInfo({ loggedIn: false });
    this.session.clearSession();
    this.onSignOut.emit(false);
    this.router.navigate(['/index']);
  }

}
