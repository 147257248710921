import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class PerfilDataService {
  public storage: any;
  url: string = environment.BASE_URL;

  userName: any;
  userId: any;

  constructor(private http: HttpClient) {}

  alterarFoto(imagem: string): Observable<any> {
    const fd = new FormData();
    fd.append('imagem', imagem);
    return this.http
      .post(`${this.url}/usuarios/imagens`, fd)
      .pipe(map(data => {
        return data;
      }));
  }

  getDateUSer(): Observable<any> {
    this.userName = window.localStorage.getItem('session');
    this.userName = JSON.parse(this.userName);
    this.userId = this.userName.usuario.codigo;

    return this.http.get(`${this.url}/usuarios/${this.userId}`).pipe(map(data => {
      const body = data;
      return body;
    }));
  }

  getUSer(codigo: any): Observable<any> {
    return this.http.get(`${this.url}/usuarios/${codigo}`).pipe(map(data => {
      const body = data;
      return body;
    }));
  }

  editarUsuario(usuario: any): Observable<any> {
    return this.http
      .put(`${this.url}/usuarios/${usuario.codigo}`, usuario)
      .pipe(map(data => {
        const body = data;
        return body;
      }));
  }

  getUserImage(): Observable<any> {
    return this.http
      .get(`${this.url}/usuarios/imagens`, {
        responseType: 'blob',
      }).pipe(map(
        res => {
          return res;
        },
        (error: any) => {
          return error;
        },
      ));
  }

  getClientImage(): Observable<any> {
    return this.http.get(`${this.url}/clientes/imagens`, {
      responseType: 'blob',
    })
    .pipe(map(
      res => {
        return res;
      },
      (error: any) => {
        return error;
      },
    ));
  }


  getCliente(): Observable<any> {
    return this.http.get(`${this.url}/clientes`).pipe(map(data => {
      const body = data;
      return body;
    }));
  }

  obterImagem(): Observable<any> {
    return this.http.get(`${this.url}/clientes/logoBase64`);
  }
}
