import {
  formatDate,
  registerLocaleData,
  ViewportScroller,
} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  Renderer2,
  Inject
} from '@angular/core';
import { AmbienteService } from 'src/app/service/ambiente/ambiente.service';
import localePT from '@angular/common/locales/pt';
import { ManualService } from 'src/app/service/manual/manual.service';
import * as FileSaver from 'file-saver';
import { fadeAnimation } from 'src/app/animations';
import swal from 'sweetalert2';
import { PerfilDataService } from 'src/app/service/perfil/perfil.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { AlertasService } from 'src/app/service/alertas/alertas.service';
import * as moment from 'moment';
import { SessionService } from 'src/app/service/session/session.service';
import { UserService } from 'src/app/service/user-service/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { getSwal } from 'src/app/utils/swalObject';
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'headerBox' },
  animations: [
    fadeAnimation,
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  @Input() hasSession!: boolean;
  @Input() subItem!: number;
  @Output() onSidebarToggle = new EventEmitter<boolean>();

  cliente: any;
  dataDeHoje: string = '';
  loading: boolean = true;
  token: string | null;
  hasImg: boolean;
  hasClientImg: boolean;
  routeSub: Subscription;
  idPerfilAcesso: any;
  labels!: any;
  possuiFilial!: boolean;
  alertas: any = [];
  subscription!: Subscription;
  watchAlerts!: Subscription;
  userData: any;
  trocaProcuracao!: boolean;
  userImg: any;
  dadosCliente: any;
  codigoCliente: any;
  clientImg!: string;
  imgLogo!: string;
  user: boolean = false;
  contrasteAtivado: any;
  exibirAlertas: boolean = false;
  altoContrasteAtivo = false;
  zoomLevel = 1;

  constructor(
    private ambienteService: AmbienteService,
    private viewportScroller: ViewportScroller,
    private manualService: ManualService,
    private perfilService: PerfilDataService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private alertasService: AlertasService,
    private sessionService: SessionService,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2, @Inject(DOCUMENT) private document: Document
  ) {
    registerLocaleData(localePT);
    this.token = window.localStorage.getItem('token');
    this.hasImg = false;
    this.hasClientImg = false;
    this.setLabels();
    this.routeSub = this.route.params.subscribe((params) => {
      if (params['id']) {
        this.idPerfilAcesso = params['id'];
      }
    });
  }

  ngOnInit(): void {
    // this.toggleContraste();
    const promisses = [this.formatarData(), this.getCliente()];
    Promise.all(promisses).then((res) => {
      this.loading = false;
    });

    let filial = parseInt(window.localStorage.getItem('PossuiFilial')!);
    if (filial >= 2) {
      this.possuiFilial = true;
    } else {
      this.possuiFilial = false;
    }

    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.token = window.localStorage.getItem('token');
        if (this.token && event.url === '/home') {
          this.alertasService.getNaoLidos(this.token).subscribe((res: any) => {
            this.alertas = res.resultado;
            this.fixDescriptions();
          });
        }
        if (this.token && event.url.includes('/caixa-postal/mensagem/')) {
          new Promise((f) => setTimeout(f, 500));
          this.alertasService.getNaoLidos(this.token).subscribe((res: any) => {
            this.alertas = res.resultado;
            this.fixDescriptions();
          });
        }
      });

    this.verifySession(this.sessionService.getSession());
    this.subscription = this.userService.getInfo().subscribe((data) => {
      this.verifySession(data);
    });

    this.watchAlerts = this.alertasService.getUpdates().subscribe((data) => {
      this.alertas = [];
    });
  }

  ngAfterViewInit() {
    this.obterLogo();
  }
  
  zoomIn() {
    this.zoomLevel += 0.1;
    document.body.style.setProperty('zoom', this.zoomLevel.toString());
    (document.querySelector('.menuAcessibilidade') as HTMLElement).style.setProperty('zoom', (1 / this.zoomLevel).toString());
  }

  zoomOut() {
    if (this.zoomLevel > 0.5) {
      this.zoomLevel -= 0.1;
      document.body.style.setProperty('zoom', this.zoomLevel.toString());
      (document.querySelector('.menuAcessibilidade') as HTMLElement).style.setProperty('zoom', (1 / this.zoomLevel).toString());
    }
  }
  
  contraste() {
    this.altoContrasteAtivo = !this.altoContrasteAtivo;
    if (this.altoContrasteAtivo) {
      this.renderer.addClass(this.document.body, 'alto-contraste');
    } else {
      this.renderer.removeClass(this.document.body, 'alto-contraste');
    }
  }

  fixDescriptions() {
    this.alertas.map((alerta: any) => {
      if (alerta.tipoAlerta.descricao.length > 37) {
        alerta.tipoAlerta['desc'] =
          moment(alerta.dataCriacao).format('DD/MM/YYYY HH:mm') +
          ' - ' +
          alerta.tipoAlerta.descricao.substring(0, 37) +
          '...';
      } else {
        alerta.tipoAlerta['desc'] =
          moment(alerta.dataCriacao).format('DD/MM/YYYY HH:mm') +
          ' - ' +
          alerta.tipoAlerta.descricao;
      }
    });
  }

  verifySession(data: any) {
    this.userData = data;
    if (data.usuario) {
      if (data.usuario.tipoIdentificacao == 1) {
        this.labels.cpfCnpf = 'CPF';
        this.labels.nomeRazaoSocial = 'Nome';
        this.labels.inscricao = '';
      } else {
        this.labels.cpfCnpf = 'CNPJ';
        this.labels.nomeRazaoSocial = 'Razão Social';
        this.labels.inscricao = this.translate.instant('GLOBAIS.INSCRICAO');
      }
    }

    if (window.localStorage.getItem('hasletters') === 'true') {
      this.trocaProcuracao = true;
    } else {
      this.trocaProcuracao = this.possuiFilial;
    }

    if (data.usuario && data.usuario.imagem) {
      this.hasImg = true;
      this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
        data['usuario'].imagem.toString()
      );
    } else {
      this.hasImg = false;
      data.loggedIn ? this.ongetUserImage() : console.log(data);
    }

    if (data.loggedIn === true) {
      this.hasClientImg = true;
      this.obterImagem();
      this.obterLogo();
    } else {
      this.hasClientImg = false;
    }
  }

  trocarEmpresa() {
    swal
      .fire(
        getSwal(
          'warning',
          'Alterar Contribunte',
          'Deseja acessar com outro contribuinte?',
          'sim',
          'não'
        )
      )
      .then((res) => {
        if (res.value) {
          const newUserData = Object.assign({}, this.userData);
          newUserData['loggedIn'] = false;
          this.userService.sendInfo({ ...newUserData });
          if (newUserData['perfilAcesso'].codigo == 2) {
            setTimeout(() => {
              this.router.navigate(['/login/certificado']);
            }, 150);
          } else {
            setTimeout(() => {
              this.router.navigate(['/procuracoes']);
            }, 150);
          }
        }
      });
  }

  obterImagem() {
    this.perfilService.obterImagem().subscribe(
      (res) => {
        this.clientImg = 'data:image/jpeg;base64,' + res.resultado;
      },
      (err) => {
        console.log(err.error.text);
      }
    );
  }

  obterLogo() {
    this.dadosCliente = JSON.parse(localStorage.getItem('session')!);
    this.codigoCliente = this.dadosCliente.cliente.codigo;

    this.ambienteService
      .obterDadosAmbiente(this.codigoCliente)
      .subscribe((res) => {
        console.log('res', res)
        this.imgLogo =
          'data:image/jpeg;base64,' + res.resultado.configuracao.logoDte;
      });
  }

  // contraste() {
  //   if (!this.contrasteAtivado) {
  //     $('body').css('background-color', 'black');
  //     $('form').css('background-color', 'black');
  //     $('div').css('background-color', 'black');
  //     $('div.table').css('background-color', 'black');
  //     $('table').css('background-color', 'black');
  //     $('section').css('background-color', 'black');
  //     $('.headerBox').css('background-color', '#333333');
  //     $('.menuAcessibilidade').css('background-color', 'black');
  //     $('p, th, td, tr, label, button, h1, img, span').css('color', 'yellow');
  //     $('a').css('color', 'orange');
  //     $('.data-manual').css('color', 'yellow');
  //     this.contrasteAtivado = true;
  //   } else {
  //     $('body').css('background-color', '');
  //     $('form').css('background-color', '');
  //     $('footer').css('background-color', '');
  //     $('div').css('background-color', '');
  //     $('div.table').css('background-color', '');
  //     $('table').css('background-color', '');
  //     $('section').css('background-color', '');
  //     $('.headerBox').css('background-color', '');
  //     $('.menuAcessibilidade').css('background-color', '');
  //     $('p, th, td, tr, label, button, h1, img, span').css('color', '');
  //     $('a').css('color', '');
  //     $('.data-manual').css('color', '');
  //     this.contrasteAtivado = false;
  //   }
  // }

  // toggleContraste() {
  //   // Verificar se o contraste está ativado ou não
  //   this.contrasteAtivado = localStorage.getItem('contrasteAtivado');

  //   // Converter o valor de string para booleano
  //   this.contrasteAtivado = this.contrasteAtivado === 'true';

  //   // Inverter o estado do contraste
  //   this.contrasteAtivado = !this.contrasteAtivado;

  //   // Aplicar estilos com base no estado do contraste
  //   if (this.contrasteAtivado) {
  //     // Aplicar estilos de alto contraste
  //     $(
  //       'body, form, footer, tfoot, div, div.table, table, section, .headerBox, .menuAcessibilidade'
  //     ).css('background-color', 'black');
  //     $('p, th, td, tr, label, button, h1, img, span').css('color', 'yellow');
  //     $('a').css('color', 'orange');
  //     $('.data-manual').css('color', 'yellow');
  //   } else {
  //     // Remover estilos de alto contraste
  //     $(
  //       'body, form, footer, tfoot, div, div.table, table, section, .headerBox, .menuAcessibilidade'
  //     ).css('background-color', '');
  //     $('p, th, td, tr, label, button, h1, img, span').css('color', '');
  //     $('a').css('color', '');
  //     $('.data-manual').css('color', '');
  //   }

  //   // Armazenar o estado do contraste no armazenamento local (como string)
  //   localStorage.setItem('contrasteAtivado', this.contrasteAtivado.toString());
  // }

  ongetUserImage() {
    return new Promise((resolve, reject) => {
      this.perfilService.getUserImage().subscribe(
        (image) => {
          if (image !== null) {
            this.getProcessedImageBlob(image).then((img: any) => {
              resolve(img);
              this.hasImg = true;
              this.userImg = this.domSanitizer.bypassSecurityTrustUrl(
                img.toString()
              );
            });
          } else {
            this.hasImg = false;
            resolve(null);
          }
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  getProcessedImageBlob(imageBlob: any) {
    return new Promise((resolve, reject) => {
      let reader: any = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          resolve(reader.result.toString());
        },
        false
      );
      reader.addEventListener(
        'error',
        () => {
          console.log('error');
        },
        false
      );

      if (imageBlob) {
        reader.readAsDataURL(imageBlob);
      }
    });
  }

  redirect() {
    if (this.userData['loggedIn']) {
      this.router.navigate(['home']);
    } else {
      this.userService.sendInfo({ loggedIn: false });
      this.sessionService.clearSession();
      this.router.navigate(['index']);
    }
  }

  clickUsuario() {
    this.user = !this.user;
  }

  onSignOut(val: boolean) {
    this.user = val;
  }

  formatarData() {
    return new Promise((resolve, reject) => {
      let diaDaSemana = formatDate(new Date(), 'EE', 'pt').slice(0, -1);
      let diaMesAno = formatDate(new Date(), 'longDate', 'pt');
      this.dataDeHoje = diaDaSemana + ', ' + diaMesAno;
      resolve(this.dataDeHoje);
    });
  }

  getCliente() {
    return new Promise((resolve, reject) => {
      this.ambienteService.getCliente().subscribe(
        (res) => {
          let data = res.resultado[0];
          this.cliente = {
            codigo: data.codigo,
            razaoSocial: data.razaoSocial,
            logoDte: 'data:image/jpeg;base64,' + data['configuracao'].logoDte,
          };

          if (data['configuracao'].logo !== null) {
            this.cliente['logoCliente'] =
              'data:image/jpeg;base64,' + data['configuracao'].logo;
          }

          this.idPerfilAcesso = res.resultado['0'].codigo;

          resolve(this.cliente);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  onClickScroll(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  baixarManual() {
    this.loading = true;
    this.manualService.baixarManual(this.cliente.codigo).subscribe(
      (res) => {
        const blob = new Blob([res], { type: `application/pdf}` });
        FileSaver.saveAs(blob, `manualContribuinte.pdf`);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        swal.fire({
          icon: 'error',
          title: 'Ops!',
          text: 'O manual não foi definido',
        });
      }
    );
  }

  setLabels() {
    this.labels = {
      logo: this.translate.instant('GLOBAIS.LOGO'),
      brasao: this.translate.instant('GLOBAIS.BRASAO'),
      titulo: this.translate.instant('GLOBAIS.TITULO'),
    };
  }
}
