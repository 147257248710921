import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { SessionService } from "../session/session.service";
import { UserService } from "../user-service/user.service";

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(private sessionService: SessionService, private userService: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.sessionService.getToken();
    let headers = new HttpHeaders();

    if (typeof token !=='undefined' && token) {
      headers = headers.append("Authorization", token).append('Cache-Control', 'no-cache').append('Pragma', 'no-cache'); 
    }

    const dupReq = req.clone({ headers });
    return next.handle(dupReq).pipe(catchError((err: HttpErrorResponse) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 0 ) {
          window.localStorage.setItem("sentToSignIn", 'true');
          this.userService.sendInfo({ loggedIn: false });
          this.sessionService.clearSession();
        };
      }
      return throwError(err);
    }));
  }
}