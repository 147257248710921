import { Component, OnInit } from '@angular/core';
import { WizardService } from '../../../../../../service/wizard/wizard.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'resumo-procurador-app',
  templateUrl: 'procurador.component.html',
  styleUrls: ['./procurador.component.scss'],
})
export class ResumoProcuradorComponent implements OnInit {
  routeSub!: Subscription;
  perfil: string = '';
  index: number = 4;
  data: any = {};
  infoBasica: any = null;
  repLegal: any = null;
  procurador: any = null;
  prefeituras = null;
  tipo: string | null = null;
  semOutorga: boolean = false;

  constructor(
    private wizardService: WizardService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.data = this.wizardService.userData;
    this.infoBasica = this.data['infoBasica'];
    this.repLegal = this.data['representanteLegal'];
    this.procurador = this.data['procurador'];
    this.prefeituras = this.data['prefeituras'];
    this.tipo = this.infoBasica['pessoa'];
    this.perfil = this.data['perfil'];
      
    if (this.procurador['semOutorga']) this.semOutorga = true;
    if (this.perfil === 'contribuinte') this.tipo = 'juridica';
  }

  edit(index: any, title: string) {
    this.wizardService.updateIndex(index);
    let profile = this.perfil;
    switch (title) {
      case 'prefeituras':
        this.router.navigate(['cadastro', profile, title]);
        break;
      case 'dados':
        this.perfil === 'contribuinte'
          ? this.router.navigate(['cadastro/contribuinte/dados'])
          : this.router.navigate(['cadastro/procurador/dados-procurador']);
        break;
      case 'representante':
        console.log(profile);
        this.router.navigate(['cadastro', profile, title]);
        break;
      default:
        this.router.navigate(['cadastro', profile, 'resumo']);
        break;
    }
  }
}
