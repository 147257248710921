import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from 'web-pki';
import { UserService } from '../../../service/user-service/user.service';
import { SessionService } from '../../../service/session/session.service';
import { LoginService } from '../../../service/login/login.service';
import { CertificadoService } from '../../../service/certificado/certificado.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../utils/swalObject';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-certificado',
  templateUrl: './certificado.component.html',
  styleUrls: ['./certificado.component.scss'],
})
export class CertificadoComponent implements OnInit {
  private pki: LacunaWebPKI;
  searchResults: Array<any> = [];
  buscando: boolean = false;
  serverData: Object = {};
  disabled: boolean = false;
  loading: boolean = false;
  dadosCertificado = [];

  today: Date = new Date();
  labels: any;
  cdCadastro = false;
  tipoCadastro: string = '';

  private ngUnsubscribe: Subject<any> = new Subject<any>();

  constructor(
    private config: CertificadoService,
    private ngZone: NgZone,
    private loginService: LoginService,
    private userService: UserService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    window.scrollTo(0, 0);
    this.pki = new LacunaWebPKI(config.webPkiLicense);
    this.setLabels();
    this.tipoCadastro = this.activatedRoute.snapshot.params.tipo;
  }

  ngOnInit() {
    if (this.router.url.split('/')[1] === 'certificado-digital') {
      this.cdCadastro = true;
    }
    this.pki.init({
      ngZone: this.ngZone,
      ready: this.onWebPkiReady,
      notInstalled: this.onWebPkiNotInstalled,
    });
    this.buscando = true;

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private onWebPkiReady = () => {
    this.carregarCertificados();
  };

  carregarCertificados() {
    this.buscando = true;
    this.pki.listCertificates({}).success((certs: any[]) => {
      this.searchResults = [];

      // const today = new Date('Tue Sep 10 2019 15:44:24 GMT-0300');

      certs.forEach((ct: any) => {
        let validityDate = new Date(ct.validityEnd);
        if (validityDate > this.today) {
          ct['validityStartFormated'] = moment(ct.validityStart).format(
            'DD/MM/YYYY',
          );
          ct['validityEndFormated'] = moment(ct.validityEnd).format(
            'DD/MM/YYYY',
          );
          console.log('ct: ', ct);
          this.searchResults.push(ct);
        }
      });

      this.buscando = false;
    });
  }

  update() {
    this.carregarCertificados();
  }

  private onWebPkiNotInstalled = () => {
    this.buscando = false;
    this.pki.redirectToInstallPage();
  };

  onSelect(e: any, certificado: any) {
    this.loading = true;
    e.stopPropagation();
    e.preventDefault();

    this.disabled = !this.disabled;

    this.dadosCertificado = certificado.pkiBrazil;

    if (this.cdCadastro) {
      if (certificado.pkiBrazil.isPessoaFisica === true && this.tipoCadastro == 'contribuinte'){
        swal.fire(
          getSwal(
            'warning',
            'Ops!',
            'Auto cadastro permitido apenas para e-CNPJ. Desculpe-nos o incômodo, em breve disponibilizaremos novos recursos.',
            'ok',
            null,
          ),
        ).then(res => {
          this.router.navigate(['index']);
        });
      } else {
        if (this.disabled) {
        this.loginService
          .getNonce()
          .pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
            this.pki
            .readCertificate(certificado.thumbprint)
            .success((certEncoded: string | Blob) => {
              this.pki
                .signData({
                  thumbprint: certificado.thumbprint,
                  data: res.resultado['nonce'],
                  digestAlgorithm: res.resultado['digestAlgorithm'],
                })
                .success((signature: string | Blob) => {
                  this.loginService
                    .loginViaCertificado(
                      res.resultado['nonce'],
                      certEncoded,
                      signature,
                      certificado,
                    )
                    .subscribe(
                      (res: any) => this.handleResponse(res),
                      (error: any) => this.handleError(error),
                    );
                });
            });
          });
        }
      }
    } else {
      if (this.disabled) {
        this.loginService
          .getNonce()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
          
            this.pki
            .readCertificate(certificado.thumbprint)
            .success((certEncoded: string | Blob) => {
              this.pki
                .signData({
                  thumbprint: certificado.thumbprint,
                  data: res.resultado['nonce'],
                  digestAlgorithm: res.resultado['digestAlgorithm'],
                })
                .success((signature: any) => {
                  this.loginService
                    .loginViaCertificado(
                      res.resultado['nonce'],
                      certEncoded,
                      signature,
                      certificado,
                    )
                    .subscribe(
                      (res: any) => this.handleResponse(res),
                      (error: any) => this.handleError(error),
                    );
                });
            });
          });
        }
    }
  }

  handleResponse(res: any) {
    this.loading = false;
    
    if(this.cdCadastro && this.tipoCadastro == 'contribuinte'){
      this.router.navigate(['cadastro/contribuinte/dados'], {queryParams: this.dadosCertificado});
      // this.location.back();
    } else if(this.cdCadastro && this.tipoCadastro == 'procurador') {
      this.router.navigate(['cadastro/procurador/dados-procurador'], {queryParams: this.dadosCertificado});
    }else{
      if (!res.excecao) {
        this.userService.sendInfo({ loggedIn: false, ...res.resultado });
        let userInfo = res.resultado;
        if (userInfo.empresas != null) {
          this.router.navigate(['/login/empresas']);
        } else {
          this.router.navigate(['/login/perfis']);
        }
      }
    }
  }

  handleError(err: any) {
    let error = err['error'];
    this.loading = false;
    
    if(this.cdCadastro && this.tipoCadastro == 'contribuinte'){
      this.router.navigate(['cadastro/contribuinte/dados'], {queryParams: this.dadosCertificado});
      // this.location.back();
    } else if(this.cdCadastro && this.tipoCadastro == 'procurador') {
      this.router.navigate(['cadastro/procurador/dados-procurador'], {queryParams: this.dadosCertificado});
    } else {
      swal.fire(getSwal('error', 'Ops!', error.excecao.recomendacao, 'ok', null)).then(
        res => {
          this.router.navigate(['index']);
        },
      );
    }
  }

  setLabels() {
    this.labels = {
      atualizar: this.translate.instant('GLOBAIS.ATUALIZAR'),
      validode: this.translate.instant('GLOBAIS.VALIDODE'),
      validoate: this.translate.instant('GLOBAIS.VALIDOATE'),
      vencido: this.translate.instant('PROCURACAO.VENCIDO'),
      titulo: this.translate.instant('LOGINCERTIFICADO.TITULO'),
      subtitulo: this.translate.instant('LOGINCERTIFICADO.SUBTITULO'),
      buscar: this.translate.instant('LOGINCERTIFICADO.BUSCAR'),
      encontrados: this.translate.instant('LOGINCERTIFICADO.ENCONTRADOS'),
      naoencontrados: this.translate.instant('LOGINCERTIFICADO.NAOENCONTRADOS'),
      selecione: this.translate.instant('LOGINCERTIFICADO.SELECIONE'),
      selecionareste: this.translate.instant('LOGINCERTIFICADO.SELECIONARESTE'),
    };
  }
}
