import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators';


@Injectable()
export class ConsultaUsuarioService {

  url: string = environment.BASE_URL;

  constructor(private http: HttpClient) { }

  setParams(searchParams: any) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for(const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }

  consultarUsuario(id: string): Observable<any> {
    const params = new HttpParams().set('cpfCnpj', id);
    return this.http.get(`${this.url}/usuarios`, { params: params }).pipe(map(data => {
      return data;
    }));
  }

  obterUsuario(codigoUsuario: any): Observable<any> {
    return this.http.get(`${this.url}/usuarios/${codigoUsuario}`).pipe(map((data: any) => {
      const body = data['resultado'];
      return body;
    }));
  }

  consultarUsuariosAtivos(searchParams: any): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get(`${this.url}/usuarios/ativos`, { params: params });
  }

  buscarProcurador(cpfCnpj: string): Observable<any> {
    const params = new HttpParams().set('cpfCnpj', cpfCnpj);
    return this.http
      .get(`${this.url}/usuarios/procuradores/ativos`, { params: params });
  }

  getApiExterna(cpfCnpj: string): Observable<any> {
    return this.http.get(`${this.url}/contribuintes/autocadastro?cpfCnpj=${cpfCnpj}`).pipe(map(data => {
      const body = data;
      return body;
    }));
  }

  getAutoCadastro(): Observable<any> {
    return this.http.get(`${this.url}/clientes/verificacaoIntegracaoAutocadastro`);
  }

  getTermoAdesao(codigoUsuario: any, cliente: any): Observable<any> {
    return this.http.get(`${this.url}/obterTermoAdesao?codigoCliente=${cliente}&codigoUsuario=${codigoUsuario}`).pipe(map(data => {
      const body = data;
      return body;
    }));
  }

  gerarDocumento(data: any): Observable<any> {
    const fd = new FormData();
    fd.append('certificado', data.certificado);
    fd.append('codigoCliente', data.codigoCliente);
    fd.append('codigoUsuario',data.codigoUsuario);
    return this.http
      .post(`${this.url}/gerarDocumentoCiencia`, fd, {
        headers: new HttpHeaders().set('Content-Type', 'multipart/form-data')
      });
  }

  darCiencia(codigoCliente: any, codigoUsuario: any, documento: any): Observable<any> {
    console.log('documento:::::::', documento)
    return this.http
      .post(`${this.url}/darCiencia?codigoCliente=${codigoCliente}&codigoUsuario=${codigoUsuario}`, 
        documento
      )
  }

  getPersonalidadesJuridicasAtivos(): Observable<any> {
    return this.http.get(`${this.url}/personalidadesJuridicas/ativos`).pipe(map(data => {
      const body = data;
      return body;
    }));
  }
  
  getSetoresAtuacaoAtivos(): Observable<any> {
    return this.http.get(`${this.url}/setoresAtuacao/ativos`).pipe(map(data => {
      const body = data;
      return body;
    }));
  }
}
