import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '../../../utils/password-validation';
import { RecuperarSenhaService } from '../../../service/recuperar-senha/recuperar-senha.service';

import swal from 'sweetalert2';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-resetar-senha',
  templateUrl: 'resetar-senha.component.html',
  styleUrls: ['./resetar-senha.component.scss'],
})
export class ResetarSenhaComponent implements OnInit {
  token: any;
  data: any = {};
  forgotPasswordForm!: FormGroup;
  loading: boolean = false;
  private routeSubscription: any;

  constructor(
    private route: ActivatedRoute,
    private recuperarSenhaService: RecuperarSenhaService,
    private fb: FormBuilder,
    private router: Router,
  ) {}

  ngOnInit() {
    this.routeSubscription = this.route.params.subscribe(params => {
      this.token = params['token'];
    });
    this.recuperarSenhaService.obterDadosViaToken(this.token).subscribe(
      res => this.handleResponse(res),
      error => {
        console.log('erro obtendo dados do token de recuperação: ', error);
        this.handleError();
      },
    );
    this.criarFormulario();
  }

  handleResponse(res: any) {
    this.data = res.resultado;
    this.data['usuario'].celular =
      this.data['usuario'].ddd &&
      this.data['usuario'].celular &&
      this.data['usuario'].ddd !== null &&
      this.data['usuario'].celular !== null
        ? this.data['usuario'].ddd + ' ' + this.data['usuario'].celular
        : '';
  }

  handleError() {
    this.loading = false;

    swal.fire(
      getSwal(
        'error',
        'Ops!',
        'Sessão deste Token expirou. Tente recuperar a senha novamente.',
        'ok',
        null,
      ),
    ).then(res => {
      this.router.navigate(['index']);
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  criarFormulario() {
    this.forgotPasswordForm = this.fb.group(
      {
        password: [
          '',
          Validators.compose([Validators.minLength(8), Validators.required]),
        ],
        passwordConfirm: [
          '',
          Validators.compose([Validators.minLength(8), Validators.required]),
        ],
      },
      {
        validator: [PasswordValidation.MatchPassword, PasswordValidation.ValidatorPassWord]
      },
    );
  }

  alterarSenha() {
    this.loading = true;
    let senha = this.forgotPasswordForm.controls.passwordConfirm.value;
    senha = senha.toString();
    this.token = this.token.toString();
    this.recuperarSenhaService.redefinirSenha(this.token, senha).subscribe(
      res => {
        this.loading = false;

        swal.fire(
          getSwal(
            'success',
            'Senha Alterada com sucesso',
            'Você será redirecionado para a página de login, informe seu CPF/CNPJ e senha atual para acessar o portal',
            'ok',
            null,
          ),
        ).then(res => {
          this.router.navigate(['/index']);
        });
      },
      error => {
        swal.fire(
          getSwal(
            'error',
            'Ops',
            'Ocorreu um erro e a senha não pôde ser recuperada',
            'ok',
            null,
          ),
        );
      },
    );
  }
}
