import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ManualService {

  url: string = environment.BASE_URL;
  constructor(private http: HttpClient) { }

  baixarManual(codigoCliente: string): Observable<any>{
    return this.http.get(`${this.url}/manuais/${codigoCliente}/manualPortal`, {
      responseType: 'arraybuffer'
    });
  }

  obterNomeArquivo(codigoCliente: number): Observable<any>{
    return this.http.get(`${this.url}/clientes/${codigoCliente}/manualUsuario/listar`);
  }
}