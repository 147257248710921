import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CaixaPostalService } from "../../../../../service/caixa-postal/caixa-postal.service";
import { graphicCollors } from "../../../../../../environments/version";

@Component({
  selector: "[app-graficos]",
  templateUrl: "graficos.component.html",
  styleUrls: ["./graficos.component.scss"]
})
export class GraficosMensagensComponent {
  recebidas!: number;
  nao_lidas!: number;
  comResposta!: number;
  ciencia_tacita!: number;
  ciencia_tacita_a_vencer!: number;
  ciencia_tacita_vencidos!: number;
  naoLidosNoPrazoDeLeitura!: number;
  lidosDentroPrazoLeitura!: number;
  enviadas!: number;
  semResposta!: number;
  max = 0;
  selected = "ativos";
  gColors = graphicCollors;
  @Output() setAChar: EventEmitter<any> = new EventEmitter<any>();
  constructor(private caixaPostalService: CaixaPostalService) {}

  ngOnInit() {
    this.caixaPostalService.getMessagesNumbersToBadge().subscribe(res => {
      this.comResposta = res[0].resultado;
      this.ciencia_tacita = res[1].resultado;
      this.enviadas = res[2].resultado;
      this.semResposta = res[3].resultado;
      this.nao_lidas = res[4].resultado;
      this.ciencia_tacita_a_vencer = res[5].resultado;
      this.ciencia_tacita_vencidos = res[6].resultado;
      this.naoLidosNoPrazoDeLeitura = res[7].resultado;
      this.lidosDentroPrazoLeitura  = res[8].resultado;

      this.recebidas = this.enviadas;
      this.max = this.comResposta + this.semResposta;
    });
  }

  public onSelectChar(mode: string): void {
    this.setAChar.emit(mode);
  }
}
