import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ComunicadosService } from '../../../../../service/comunicados/comunicados.service';

import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-selecao-tipos-comunicado',
  templateUrl: 'tipos-comunicado.component.html',
  styleUrls: ['./tipos-comunicado.component.scss']
})
export class SelecaoTiposComunicadoComponent implements OnInit {
  @Output() onClosePopUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectComunicado: EventEmitter<any> = new EventEmitter<any>();

  selectedEntities: any[] = [];
  loading: boolean = false;
  data: any = {
    resultado: [],
    primeiraPagina: true,
    ultimaPagina: false,
    paginaAtual: 1,
    totalPaginas: 1,
    totalDeItems: 0
  };
  searchForm!: FormGroup;
  searchParams: any = {
    pagina: 1,
    total_pagina: 10
  };

  constructor(
    private router: Router,
    private comunicadosService: ComunicadosService,
    private formBuilder: FormBuilder,
  ) {
    this.criarFormulario();
  }

  ngOnInit() {
    // this.loading = true;
    this.obterComunicados();

  }

  criarFormulario() {
    this.searchForm = this.formBuilder.group({
      titulo: ''
    })
  }

  obterComunicados() {
    this.loading = true;
    
    this.comunicadosService.obterComunicadosPlataformaDTe(this.searchParams).subscribe(
      res => {
        this.loading = false;
        this.data = res;

        this.data['resultado'].map((item: any) => {
          item['checked'] = false;
          item['dataCriacaoFormated'] = moment(item['dataCriacao']).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        });
      },
      err => {
        this.loading = false;
        this.fecharPopUp();
        Swal.fire('Ops!', err.error.excecao.mensagem, 'error');
      }
    );
  }

  next() {
    this.searchParams['pagina']++;
    this.obterComunicados();
  }

  previous() {
    this.searchParams['pagina']--;
    this.obterComunicados();
  }

  fecharPopUp() {
    this.onClosePopUp.emit(false);
  }

  selectRow(tipoComunicado: any) {

    this.data['resultado'].forEach((item: any) => {
      item.codigo === tipoComunicado.codigo
        ? (item.checked = !item.checked)
        : (item.checked = false);
    });
    this.selectedEntities = this.data['resultado'].filter(
      (item: any) => item.checked === true
    );


  }

  proximo() {
    this.onSelectComunicado.emit(this.selectedEntities);
  }

  search() {
    this.searchParams['descricao'] = this.searchForm.controls.titulo.value;
    this.searchParams['pagina'] = 1;

    this.comunicadosService.obterComunicadosPlataformaDTe(this.searchParams)
    .pipe(debounceTime(500)).subscribe(res => {
      this.data = res;
      this.data['resultado'].map((item: any) => {
        item['checked'] = false;
        item['dataCriacaoFormated'] = moment(item['dataCriacao']).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      });
    }, error => {
      console.log(error)
    });
  }
}
