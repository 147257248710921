import { DesempenhoService } from './../../../service/desempenho/desempenho.service';
import { ViewportScroller} from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeAnimation } from 'src/app/animations';
import { CadastroService } from 'src/app/service/cadastro/cadastro.service';
import { getSwal } from 'src/app/utils/swalObject';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  animations: [fadeAnimation]
})

export class IndexComponent implements OnInit{

  customClass!: {
    container: 'container-class';
    popup: 'popup-class';
    header: 'header-class';
    title: 'title-class';
    closeButton: 'close-button-class';
    icon: 'icon-class';
    image: 'image-class';
    content: 'content-class';
    input: 'input-class';
    actions: 'actions-class';
    confirmButton: 'confirm-button-class';
    cancelButton: 'cancel-button-class';
    footer: 'footer-class';
  };
  desempenho!: any;
  quantidadeDesempenho: number = 0;
  exibirAcessibilidade: boolean = false;
  loading: boolean = true;
  exigeCertificadoCpf: boolean = false;
  exigeCertificadoCNPJ: boolean = false;
  dadosPortal: number;


  constructor(
    private viewportScroller: ViewportScroller,
    private cadastroService: CadastroService,
    private desempenhoService: DesempenhoService,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.getAllDatas();
  }

  async getAllDatas() {
    const promisses = [
      this.getDesempenho(),
      this.getConfiguracaoExigeCertificadoCPF(),
      this.getConfiguracaoClienteExigeCertificado()
    ]

    await Promise.all(promisses).then((res) => {
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
    });

  }

  // getDesempenho() {
  //   return new Promise((resolve, reject) => {
  //     this.desempenho = {
  //       comunicadosTrocados: '18.457',
  //       contribuintesAtivos: '435 mil',
  //       dados: '100',
  //       procuradoresAtivos: '14.993'
  //     }

  //     resolve(this.desempenho);
  //     console.log('desempenho: ', this.desempenho)
  //   });
  // }

  getDesempenho() {
    this.desempenhoService.getDesempenho().subscribe((data) => {
      this.desempenho = {
        comunicadosTrocados: data.comunicadosTrocados,
        contribuintesAtivos: data.contribuintesAtivos,
        dados: data.dados,
        procuradoresAtivos: data.procuradoresAtivos
      }
      console.log('desempenho: ', this.desempenho)
    });
  }

  getConfiguracaoClienteExigeCertificado(){
    return new Promise((resolve, reject) => {
      this.cadastroService.getConfiguracaoClienteExigeCertificado().subscribe(
        res => {
          this.exigeCertificadoCNPJ = res.resultado;
          resolve(this.exigeCertificadoCNPJ);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  getConfiguracaoExigeCertificadoCPF(){
    return new Promise((resolve, reject) => {
      this.cadastroService.getConfiguracaoClienteExigeCertificadoCPF().subscribe(
        res => {
          this.exigeCertificadoCpf = res.resultado;
          resolve(this.exigeCertificadoCpf);
        },
        error => {
          reject(error);
        });
    })
  }

  exigeCertificado(profile: any) {
	  if(this.exigeCertificadoCNPJ) {
      Swal.fire(
        getSwal(
          'warning',
          'Exige Certificado',
          'Para fazer cadastro de Pessoa Jurídica, é exigido certificado digital padrão ICP-Brasil válido. Será exigido o certificado ao clicar em Prosseguir, e pode ser que seja redirecionado para tela de configuração de componente para leitura do certificado.',
          'Prosseguir',
          'Desistir'
        )
      ).then(res => {
        if (res.value) {
          setTimeout(() => {
            this.router.navigate(['/login/certificado-digital', profile]);
          }, 150);
        }else{
          setTimeout(() => {
            this.router.navigate(['/index']);
          }, 150);
        }
      });
    } else {
      this.router.navigate([`/cadastro/${profile}-juridico`]);
    }
  }

  exigeCertificadoProcuradorFisico(profile: string){
    if(this.exigeCertificadoCpf) {
      Swal.fire(
        getSwal(
          'warning',
          'Exige Certificado',
          'Para fazer cadastro de Procurador Pessoa Física, é exigido certificado digital padrão ICP-Brasil válido. Será exigido o certificado ao clicar em Prosseguir, e pode ser que seja redirecionado para a tela de configuração de componente para leitura do certificado.',
          'Prosseguir',
          'Desistir'
        )
      ).then(res => {
        if (res.value) {
          setTimeout(() => {
            this.router.navigate(['/login/certificado-digital', profile]);
          }, 150);
        }else{
          setTimeout(() => {
            this.router.navigate(['/index']);
          }, 150);
        }
      });
    } else {
      this.router.navigate([`/cadastro/${profile}-fisico`]);
    }
  }

}
