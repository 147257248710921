import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProcuracaoComponent } from './procuracao/procuracao.component';
import { GridProcuracaoComponent } from './grid-procuracao/grid-procuracao.component';
import { OutorgarComponent } from './outorgar/pages/outorgar/outorgar.component';
import { CertificadosComponent } from './outorgar/pages/certificados/certificados.component';
import { AuthGuardService } from '../../service/auth-guard/auth-guard.service';

const routes: Routes = [
  {
    path: 'procuracao/certificados/:id',
    data: { breadcrumb: 'Procuração', modulo: '2003' },
    component: CertificadosComponent
  },
  {
    path: '',
    component: ProcuracaoComponent,
    canActivate: [AuthGuardService],
    data: { breadcrumb: 'Procuração', modulo: '2003' },
    children: [
      {
        path: '',
        redirectTo: 'grid',
        pathMatch: 'full'
      },
      {
        path: 'grid',
        component: GridProcuracaoComponent
      },
      {
        path: 'outorgar',
        component: OutorgarComponent,
        data: {
          breadcrumb: 'Outorgar'
        }
      },
      {
        path: 'certificados/:id',
        data: { breadcrumb: 'Procuração', modulo: '2003' },
        component: CertificadosComponent
      },
      {
        path: 'certificados/:id/:filiais',
        data: { breadcrumb: 'Procuração', modulo: '2003' },
        component: CertificadosComponent
      },
      {
        path: 'outorgar/:procuracao',
        component: OutorgarComponent,
        data: {
          breadcrumb: 'Outorgar'
        }
      },
      {
        path: 'certificados',
        component: CertificadosComponent
      }
    ]
  }, 
  {
    path: 'certificados/login/:id/:filiais',
    data: { breadcrumb: 'Procuração', modulo: '2003' },
    component: CertificadosComponent
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcuracaoRoutingModule {}
