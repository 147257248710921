import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LacunaWebPKI, CertificateModel, ExceptionModel } from 'web-pki';
import { CertificadoService } from '../../../../../service/certificado/certificado.service';
import { ProcuracoesService } from '../../../../../service/procuracoes/procuracoes.service';
import { OutorgarProcuracaoService } from '../../../../../service/procuracoes/outorgar-procuracao.service';
// import 'rxjs/add/operator/takeUntil';
// import { takeUntil } from 'rxjs/Subject';
// import { Subject } from 'rxjs/Subject';
import { Location } from '@angular/common';

import swal from 'sweetalert2';

import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../../../utils/swalObject';

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.scss']
})
export class CertificadosComponent implements OnInit {
  
  private pki: LacunaWebPKI;
  searchResults: Array<any> = [];
  filiais: boolean;
  buscando = false;
  serverData: Object = {};
  disabled = false;
  loading = false;
  today: Date = new Date();
  outorgarData: any = null;
  certificado: any = null;
  certEncoded: any;
  labels: any;
  id: any;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private procuracoesService: ProcuracoesService,
    private outorgarProcuracaoService: OutorgarProcuracaoService,
    private config: CertificadoService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private _location: Location
  ) {
    this.pki = new LacunaWebPKI(config.webPkiLicense);
    this.setLabels();
  }

  ngOnInit() {
    this.buscando = true;
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.filiais = params['filiais'];

      if (!this.id) {
        this.outorgarData = this.outorgarProcuracaoService.outorgarData;
      }

      this.pki.init({
        ngZone: this.ngZone,
        ready: this.onWebPkiReady,
        notInstalled: this.onWebPkiNotInstalled
      });
    });
  }

  private onWebPkiReady = () => {
    this.carregarCertificados();
  };

  carregarCertificados() {
    this.buscando = true;
    this.pki.listCertificates({}).success(certs => {
      this.buscando = false;
      this.searchResults = certs;
      this.buscando = false;
    });
  }

  update() {
    this.carregarCertificados();
  }

  private onWebPkiNotInstalled = (status, message) => {
    this.buscando = false;
    this.pki.redirectToInstallPage();
  };

  onSelect(e, certificado) {
    this.loading = true;
    this.certificado = certificado;
    this.disabled = !this.disabled;
    this.pki.readCertificate(certificado.thumbprint).success(certEncoded => {
      this.certEncoded = certEncoded;
      if (!this.id) {
        this.outorgarData['certificado'] = certEncoded;
        this.outorgarProcuracaoService.setData(this.outorgarData);
      }

      this.handleDocument();
    });
  }

  handleDocument() {
    if (!this.id) {
      this.outorgarProcuracaoService
        .gerarDocumento(this.outorgarData)
        .subscribe(
          res => {
            this.signDocument(res.resultado);
            console.log(res);
          },
          error => {
            swal.fire(
              getSwal(
                'error',
                'Ops! Ocorreu um erro',
                'Ocorreu um erro e sua procuração não pôde ser outorgada, tente novamente',
                'ok',
                null
              )
            ).then(res => {
              this._location.back();
            });
          }
        );
    } else {
      this.outorgarProcuracaoService
        .gerarDocumentoRevogacao(this.certEncoded, this.id)
        .subscribe(
          res => {
            this.signDocument(res.resultado);
            console.log(res);
          },
          error => {
            swal.fire(
              getSwal(
                'error',
                'Ops! Ocorreu um erro',
                'Ocorreu um erro e sua procuração não pôde ser revogada, tente novamente',
                'ok',
                null
              )
            ).then(res => {
              this._location.back();
            });
          }
        );
    }
  }

  signDocument(response) {
    this.outorgarData = response;
    const thumbprint = this.certificado.thumbprint;
    const hash = response.toSignHash;
    const digestAlgorithm = response.digestAlgorithm;
    this.pki
      .signHash({
        thumbprint: thumbprint,
        hash: hash,
        digestAlgorithm: digestAlgorithm
      })
      .success(signature => {
        const docSign = response;
        docSign.assinatura = signature;
        if (this.id) {
          this.procuracoesService
            .cancelarProcuracao(this.id, docSign, this.filiais)
            .subscribe(
              res => {
                swal.fire(
                  getSwal(
                    'success',
                    'Sucesso!',
                    'A procuração foi revogada com êxito.',
                    'ok',
                    null
                  )
                ).then(res => {
                  this._location.back();
                });
              },
              error => {
                swal.fire(
                  getSwal(
                    'error',
                    'Erro',
                    'Ocorreu um erro e a procuração não pôde ser cancelada.',
                    'ok',
                    null
                  )
                );
              }
            );
        } else {
          this.outorgarData['assinatura'] = signature;
          this.outorgarProcuracaoService
            .outorgarProcuracao(this.outorgarData)
            .subscribe(
              res => {
                this.loading = false;

                swal.fire(
                  getSwal(
                    'success',
                    'Sucesso',
                    'A procuração foi outorgada com êxito',
                    'ok',
                    null
                  )
                ).then(res => {
                  this.router.navigate(['procuracao/grid']);
                });
              },
              error => {
                this.loading = false;

                swal.fire(
                  getSwal(
                    'error',
                    'Ops! Ocorreu um erro',
                    'Ocorreu um erro e sua procuração não pôde ser outorgada, tente novamente',
                    'ok',
                    null
                  )
                ).then(res => {
                  this._location.back();
                });
              }
            );
        }
      });
  }

  handleResponse(res) {
    this.loading = false;
  }

  handleError(err) {
    const error = err['error'];
    this.loading = false;
  }

  setLabels() {
    this.labels = {
      title: this.translate.instant('PROCURACAO.TITLEOUTORGAR'),
      atualizar: this.translate.instant('GLOBAIS.ATUALIZAR'),
      cpfCnpj: this.translate.instant('GLOBAIS.CPFCNPJ'),
      nomeRazaoSocial: this.translate.instant('GLOBAIS.NOMERAZAOSOCIAL'),
      email: this.translate.instant('GLOBAIS.EMAIL'),
      celular: this.translate.instant('GLOBAIS.CELULAR'),
      cadastrar: this.translate.instant('GLOBAIS.CADASTRAR'),
      dataInicial: this.translate.instant('GLOBAIS.DATAINICIAL'),
      dataFinal: this.translate.instant('GLOBAIS.DATAFINAL'),
      papel: this.translate.instant('GLOBAIS.PAPEL'),
      selecione: this.translate.instant('GLOBAIS.SELECIONE'),
      abrangencias: this.translate.instant('GLOBAIS.ABRANGENCIAS'),
      abrangenciasText: this.translate.instant('PROCURACAO.ABRANGENCIASTEXT'),
      proximo: this.translate.instant('GLOBAIS.PROXIMO'),
      desistir: this.translate.instant('GLOBAIS.DESISTIR'),
      cadastrarProcurador: this.translate.instant('PROCURACAO.CADASTRARPROCURADOR'),
      buscarCertificados: this.translate.instant('PROCURACAO.BUSCANDOCERTIFICADOS'),
      certificadosEncontrados: this.translate.instant('PROCURACAO.CERTIFICADOSENCONTRADOS'),
      selecioneCertificado: this.translate.instant('PROCURACAO.SELECIONARCERTIFICADO'),
      validoDe: this.translate.instant('PROCURACAO.VALIDODE'),
      validoAte: this.translate.instant('PROCURACAO.VALIDOATE'),
      vencido: this.translate.instant('PROCURACAO.VENCIDO')
    };
  }
}
