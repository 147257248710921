import { NgModule } from "@angular/core";

import { RodapeComponent } from "./rodape/rodape.component";
import { HeaderComponent } from "./header/header.component";
import { SharedModule } from "src/app/shared.module";
import { AcessibilidadeComponent } from "./acessibilidade/acessibilidade.component";
import { CommonRoutingModule } from "./common.routing.module";
import { SidebarMenuComponent } from "./sidebar/components/sidebar-menu.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { UserSidebarComponent } from "./user-sidebar/user-sidebar.component";
import { AlertasComponent } from "./alertas/alertas.component";
import { ChatCervelloComponent } from "./chat-cervello/chat-cervello.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";

@NgModule({
    imports: [
        SharedModule,
        CommonRoutingModule
    ],
    exports: [
        RodapeComponent,
        HeaderComponent,
        AcessibilidadeComponent,
        SidebarComponent,
        SidebarMenuComponent,
        ChatCervelloComponent,
        BreadcrumbComponent
    ],
    declarations: [
        RodapeComponent,
        HeaderComponent,
        AcessibilidadeComponent,
        SidebarComponent,
        SidebarMenuComponent,
        UserSidebarComponent,
        SidebarMenuComponent,
        AlertasComponent,
        ChatCervelloComponent,
        BreadcrumbComponent
    ],
    providers: [],
})
export class CommonAppModule {

}