import {AbstractControl} from '@angular/forms';

export class PasswordValidation {

    static MatchPassword(AC: AbstractControl) {
       let password = AC.get('password')!.value; // to get value in input tag
       let confirmPassword = AC.get('passwordConfirm')!.value; // to get value in input tag
        if(password != confirmPassword) {
            return AC.get('passwordConfirm')!.setErrors( {MatchPassword: true} )
        } else {
            return null
        }
    }

    static ValidatorPassWord(AC: AbstractControl){
        let password = '';
        if(AC.get('password')!.value){
            password = AC.get('password')!.value
        }
        let errors: any = {};
        let caracteres = [
            '@',
            '#',
            '$',
            '%',
            '&',
            '+',
            '='
        ]
        let semCaracter: number = 0;

        if(password.length <= 7) { 
            errors['minLenght'] = true;
        }
        
        let number = /[0-9]/;
        let numberValidator = number.test(password);
        if(numberValidator == false){
            
            errors['number'] = true;
        }

        if(password.toLowerCase() == password){
            errors['maiusculo'] = true;
        }

        if(password.toUpperCase() == password){
            errors['minusculo'] = true;
        }

        caracteres.forEach(caracter=> {
            if(password.indexOf(caracter) == -1){
                semCaracter++
            }

            if(semCaracter == 7){
                errors['caracter'] = true;
            }
            
        })

        if(Object.keys(errors).length !== 0){
           AC.get('password')!.setErrors( {errors} )
        }
    }
}