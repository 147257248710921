import { NgModule } from '@angular/core';
import { LoginSenhaComponent } from './login-senha/login-senha.component';
import { LoginRoutingModule } from './login.routing.module';
import { SharedModule } from 'src/app/shared.module';

import { PerfilComponent } from './perfil/perfil.component';
import { CertificadoComponent } from './certificado/certificado.component';
import { IconeValidacaoComponent } from './resetar-senha/componentes/icones-validacao/icones-validacao.component';
import { ResetarSenhaComponent } from './resetar-senha/resetar-senha.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { DataTable, DataTableModule } from 'ng2-data-table';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProcuracaoComponent } from './procuracao/procuracao.component';
import { ECnpjComponent } from './e-cnpj/e-cnpj.component';
import { filiaisComponent } from './filiais/filiais.component';



@NgModule({  
  imports: [
    LoginRoutingModule,
    SharedModule,
    NgxDatatableModule
  ],
  declarations: [
    LoginSenhaComponent,
    PerfilComponent,
    CertificadoComponent,
    IconeValidacaoComponent,
    ResetarSenhaComponent,
    RecuperarSenhaComponent,
    ProcuracaoComponent,
    ECnpjComponent,
    filiaisComponent,
  ],

})
export class LoginModule { }
