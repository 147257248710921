import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { NotificationsService } from "angular2-notifications";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class MessagingService {
    URL: string = environment.notificationApi;

    currentMessage = new BehaviorSubject(null);
    registration!: ServiceWorkerRegistration;

    numDispositivo: number = 2;
    cpfCnpj!: number;
    objetoSessao: any;
    notificationToken!: any;
    obj!: Object;

    constructor(
        private http: HttpClient,
        private angularFireMessaging: AngularFireMessaging,
        private notifications: NotificationsService
        ) {
        this.angularFireMessaging.messages.subscribe((_messaging: any) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
          });
    }

    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token: any) => {
                localStorage.removeItem('notificationToken');
                localStorage.setItem('notificationToken', token);
                const tokenNotificacao = localStorage.getItem('notificationToken');
                this.notificationToken = tokenNotificacao;
                const dadosSessao = JSON.parse(localStorage.getItem('session')!);
                this.cpfCnpj = dadosSessao['usuario']['cpfCnpj'];
                const obj = {
                    num_disposivivo: this.numDispositivo,
                    dsc_cpfcnpj: this.cpfCnpj,
                    json_pushSubscription: this.notificationToken
                }
                this.apiPushNotification(obj).subscribe(
                res => {}
    )
            },
            (err: any) => {
                console.error('Unable to get permission to notify.', err);
            }
        )
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                console.log("new message received. ", payload);
                this.currentMessage.next(payload);
                const title = payload['notification']['title'];
                const content = payload['notification']['body'];
                const temp = {
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true,
                    animate: 'fromRight'
                }
                this.notifications.info(title, content, temp)
            }
        )    
    }

    apiPushNotification(obj: any) {
        return this.http.post(this.URL, obj);
        
    }
}