import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { BehaviorSubject } from '../../../../node_modules/rxjs';

@Injectable()
export class AlertasService {
  url: string = environment.BASE_URL;
  private alerts = new Subject<object>();

  private protocolo = new BehaviorSubject<any>('');
  protocolo$ = this.protocolo.asObservable();

  constructor(private http: HttpClient) {}

  marcarComoLido(codigo: any, token: string | string[]) {
    const header = new HttpHeaders();
    header.append('Authorization', token);
    this.http
      .get(`${this.url}/alertas/${codigo}/marcarComoLido`, { headers: header })
      .subscribe();
  }

  updateAlerts(value: any) {
    this.alerts.next(value);
  }

  getUpdates() {
    return this.alerts.asObservable();
  }

  marcarTodosComoLido(token: string | string[]) {
    const header = new HttpHeaders();
    header.append('Authorization', token);
    this.http
      .get(`${this.url}/alertas/marcarTodosComoLido`, { headers: header })
      .subscribe();
  }
  
  getNaoLidos(token: string) {
    const header = new HttpHeaders();
    header.append('Authorization', token);
    return this.http
      .get(`${this.url}/alertas/naoLidos`, { headers: header })
      
  }

  setProtocolo(protocolo: string) {
    this.protocolo.next(parseInt(protocolo, 10));
  }
}
