import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../service/user-service/user.service';
import { Router } from '@angular/router';
import { SessionService } from '../../../service/session/session.service';
import { LoginService } from '../../../service/login/login.service';

import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { getSwal } from '../../../utils/swalObject';

@Component({
  selector: 'app-login-filiais',
  templateUrl: './filiais.component.html',
  styleUrls: ['./filiais.component.scss']
})
export class filiaisComponent implements OnInit {
  public filiais: Array<Object> = [];
  private filialChoosed!: any;
  allow: boolean = false;
  public loading: boolean = false;
  //
  constructor(
    private userService: UserService,
    private sessionService: SessionService,
    private router: Router,
    private loginService: LoginService
  ) {}
  ngOnInit() {
    if (this.userService.getCompanysAvailable().length == 0) {
      //this.filiais = this.userService.getCompanysAvailable();
      this.router.navigate(['/home']);
    } else {
      this.filiais = this.userService.getCompanysAvailable();
      this.filiais.forEach((element: any) => {
        element['selected'] = false;
        console.log(this.filiais);
      });
    }
  }

  desistir() {
    this.userService.sendInfo({ loggedIn: false });
    this.sessionService.clearSession();
    this.router.navigate(['/index']);
  }

  select(filial: any) {
    filial.selected = true;
    this.filiais.map((elem: any) => {
      if (elem !== filial) elem['selected'] = false;
    });
    this.filialChoosed = filial;
    this.allow = true;
  }

  proximo() {
    let dataToLog: any = this.userService.getLogOnData();
    this.loading = true;
    this.loginService
      .loginCnpj({
        codigoEmpresa: this.filialChoosed['codigo'],
        cpfCnpj: dataToLog['cnpj'],
        senha: dataToLog['word']
      })
      .subscribe(
        //  res => console.warn(res),
        (res: any) => {
          this.userService.sendInfo({ loggedIn: false, ...res.resultado });
          this.loginConfigs(res.resultado);
          dataToLog = '';
          console.warn(res);
        },
        (error: any) => this.handleError(error)
      );
  }

  loginConfigs(obj: any) {
    this.loading = false;
    localStorage.setItem('firstVisit', 'true');
    this.loginService.clientes().subscribe(
      res => {
        console.log('loginconfigs >> ', res.resultado);
        let response = res.resultado;
        let clients: any = [];

        // this.router.navigate(['perfis']);
        // return;
        response.forEach((element: any) => {
          if (clients.indexOf(element.cliente.codigo) === -1) {
            clients.push(element.cliente.codigo);
          }
        });
        console.warn(res.resultado[0]);

        if (clients.length <= 1) {
          response = res.resultado[0];
          this.loginService
            .obterSessao(response.codigo)
            .then((data: any) => {
              if (data['resultado'].perfilAcesso.codigo !== 3) {
                this.userService.sendInfo({
                  loggedIn: true,
                  firstVisit: true,
                  ...data['resultado']
                });
                this.userService.setLogOnData('', '');
                this.router.navigate(['/home']);
              } else {
                this.userService.sendInfo({
                  loggedIn: false,
                  ...data['resultado']
                });
                this.router.navigate(['/filiais']);
              }
            })
            .catch(error => {
              this.handleError(error);
            });
        } else {
          this.router.navigate(['perfis']);
        }
      },
      error => this.handleError(error)
    );
  }

  handleError(err: any) {
    const error = err['error'];
    this.loading = false;
    swal.fire(
      getSwal(
        error.excecao.codigo === 'E000' ? 'warning' : 'error',
        ``,
        error.excecao.recomendacao,
        'ok',
        null
      )
    ).then(res => {
      this.userService.sendInfo({
        loggedIn: false
      });
      this.sessionService.clearSession();
      this.router.navigate(['index']);
    });
  }
}
