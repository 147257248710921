import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { SharedModule } from '../../shared.module';
import { NgxMaskModule } from 'ngx-mask';

import { CaixaPostalEletronicaRoutingModule } from './caixa-postal-eletronica.routing.module';

import { CaixaPostalComponent } from './caixa-postal/caixa-postal.component';
import { MessagesComponent } from './caixa-postal/pages/messages-list/messages-list.component';
import { FilterComponent } from './caixa-postal/components/filter/filter.component';
import { FiltroArquivadosComponent } from './caixa-postal/components/filter/arquivados/filtro-arquivados.component';
import { FiltroContribuinteComponent } from './caixa-postal/components/filter/contribuinte/filtro-contribuinte.component';
import { FiltroPeriodoComponent } from './caixa-postal/components/filter/periodo/filtro-periodo.component';
import { FiltroProtocoloComponent } from './caixa-postal/components/filter/protocolo/filtro-protocolo.component';
import { FiltroValidadeComponent } from './caixa-postal/components/filter/validade/filtro-validade.component';
import { TipoComunicadoComponent } from './caixa-postal/components/filter/tipo-comunicado/tipo-comunicado.component';
import { MessageDetailsComponent } from './caixa-postal/pages/message/message.component';
import { CertificadosComponent } from './caixa-postal/pages/certificados/certificados.component';
import { RespostasComponent } from './caixa-postal/pages/message/components/respostas/respostas.component';
import { TextBoxComponent } from './caixa-postal/pages/message/components/textbox/textbox.component';
import { MessagesService } from '../../service/messages/messages.service';
import { NomearAdvogadoComponent } from './caixa-postal/pages/nomear-advogado/nomear-advogado.component';
import { PopUpContribuinteComponent } from './caixa-postal/components/pop-up/pop-up.component';
import { EnviarMensagemComponent } from './caixa-postal/components/enviar-mensagem/enviar-mensagem.component';
import { SelecaoTiposComunicadoComponent } from './caixa-postal/components/tipos-comunicado/tipos-comunicado.component';
import { PesquisarDestinatariosComponent } from './caixa-postal/components/pesquisar-destinatario/pesquisar-destinatario.component';
import { CpfCnpjPipe } from '../../pipes/cpf-cnpj.pipe';
import { GraficosMensagensComponent } from './caixa-postal/components/graficos/graficos.component';
import { DetalhesLeituraComponent } from './caixa-postal/components/detalhes-leitura/detalhes-leitura.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import 'summernote/dist/summernote';
import 'summernote/dist/summernote-bs4';

@NgModule({
  imports: [
    DpDatePickerModule,
    CaixaPostalEletronicaRoutingModule,
    SharedModule,
    NgxSummernoteModule
  ],
  declarations: [
    CaixaPostalComponent,
    MessagesComponent,
    MessageDetailsComponent,
    RespostasComponent,
    TextBoxComponent,
    CertificadosComponent,
    FilterComponent,
    FiltroArquivadosComponent,
    FiltroContribuinteComponent,
    FiltroPeriodoComponent,
    FiltroProtocoloComponent,
    FiltroValidadeComponent,
    TipoComunicadoComponent,
    NomearAdvogadoComponent,
    PopUpContribuinteComponent,
    EnviarMensagemComponent,
    SelecaoTiposComunicadoComponent,
    PesquisarDestinatariosComponent,
    GraficosMensagensComponent,
    DetalhesLeituraComponent
  ],
  providers: [MessagesService, CpfCnpjPipe]
})
export class CaixaPostalEletronicaModule {}
